import isEmpty from 'lodash/isEmpty';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerInfoI,
  OrderSliceI,
  ProductDataToDeleteI,
} from '../../lib/types';
import { getProductList } from '../services/getProductList';
import { updateStockItemProduct } from '../services/updateStockItemProduct';
import { addProductToOrder } from '../services/addProductToOrder';
import { getOrderData } from '../services/getOrderData';
import { updateOrderStatus } from '../services/updateOrderStatus';
import { getCustomerSuggestions } from '../services/getCustomerSuggestions';

const initialState: OrderSliceI = {
  isLoading: false,
  productList: null,
  search: null,
  orderId: null,
  status: '',
  customerInfo: null,
  newCustomerInfo: null,
  orderProducts: null,
  createdAt: new Date(),
  isAddProductModalVisible: false,
  isDeleteProductModalVisible: false,
  productDataToDelete: {
    productId: '',
    orderId: '',
  },
  clientSuggestionList: null,
};

const OrderSlice = createSlice({
  name: 'OrderSlice',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setOrderDatetime: (state, action: PayloadAction<Date>) => {
      state.createdAt = action.payload;
    },

    setOrderStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },

    setIsAddProductModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isAddProductModalVisible = action.payload;
    },

    setProductDataToDelete: (
      state,
      action: PayloadAction<ProductDataToDeleteI>
    ) => {
      state.productDataToDelete = action.payload;
    },

    setIsDeleteProductModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isDeleteProductModalVisible = action.payload;
    },

    setNewClientData: (state, action: PayloadAction<CustomerInfoI>) => {
      if (isEmpty(state.newCustomerInfo)) {        
        state.newCustomerInfo = { ...state.customerInfo, ...action.payload };
      } else {
        state.newCustomerInfo = {
          ...state.newCustomerInfo,
          ...action.payload,
        };
      }
    },

    setCustomerSuggestionList: (state, action: PayloadAction<any[] | null>) => {
      state.clientSuggestionList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Обновление статуса
      .addCase(updateOrderStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateOrderStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;

          state.status = action.payload;
        }
      )
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.isLoading = false;
      })
      // Получения продуктов
      .addCase(getProductList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getProductList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;

          state.productList = action.payload.rows;
        }
      )
      .addCase(getProductList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateStockItemProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateStockItemProduct.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;

          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      )
      .addCase(updateStockItemProduct.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addProductToOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addProductToOrder.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
        }
      )
      .addCase(addProductToOrder.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getOrderData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderData.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;

        state.orderProducts = action.payload.products;
        state.customerInfo = action.payload.customerOrderInfo;
        state.orderId = action.payload.orderId;
        state.createdAt = new Date(action.payload.createdAt);
        state.status = action.payload.status;
      })
      .addCase(getOrderData.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(
        getCustomerSuggestions.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.clientSuggestionList = isEmpty(action.payload) ? null : action.payload;
        }
      );
  },
});

export default OrderSlice;
export const OrderSliceActions = OrderSlice.actions;
