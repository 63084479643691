import { CartProductCardType } from 'types/products';
import { Stack, Box, Link } from '@mui/material';
import { useAppDispatch } from 'redux/hooks';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete-product-cart.svg';
import ProductOptionsString from 'components/Products/ProductOptions/ProductOptionsString';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CartActions } from 'entities/Cart';
import { ProductCounterDeprecated } from 'shared/ui/ProductCounter';

type CartProductListMobileProps = {
  products: CartProductCardType[];
};

const CartProductListMobile = ({ products }: CartProductListMobileProps) => {
  const dispatch = useAppDispatch();
  
  const handleDeleteProductFromCart = (productId: string) => {
    dispatch(CartActions.deleteProductFromCart(productId));
  };

  return (
    <Stack className='cart-main_mobile-list'>
      {products.map((product) => (
        <Stack
          direction='row'
          key={product.cartProductId}
          className='cart-main_mobile-card'
        >
          <Box className='cart-main__product-img-wrapper'>
            {product.s3ImgList && (
              <img src={product.s3ImgList[0].imgLink} alt={product.name} />
            )}
          </Box>

          <Stack className='cart-main__product-description'>
            <Box className='cart-main__product-name-mobile'>
              <Link
                href={product.productId}
                underline='none'
                sx={{ color: 'black' }}
                target='_blank'
              >
                <h3 className='cart-main__product-name-mobile-text'>
                  {product.name}
                </h3>
              </Link>
            </Box>
            <Box className='cart-main__product-options-mobile'>
              <ProductOptionsString optionList={product.options} />
            </Box>
            <Stack
              direction='row'
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              sx={{ alignItems: 'center', mt: 'auto' }}
            >
              <Box className='cart-main__mobile-product-price' mr='10px'>
                <p>{`${product.finalPrice} ${getSymbolFromCurrency(
                  product.currency ?? 'rub'
                )}`}</p>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: '16px',
                    color: '#223bdd',
                  }}
                >
                  {`${
                    product.finalPrice * product.count
                  } ${getSymbolFromCurrency(product.currency ?? 'rub')}`}
                </p>
              </Box>
              <ProductCounterDeprecated
                minValue={1}
                startCount={product.count}
                cartProductId={product.cartProductId}
                multiplicityCount={product.multiplicityCount}
              />
            </Stack>
          </Stack>
          <button
            className='cart-main__delete-icon'
            onClick={() => handleDeleteProductFromCart(product.cartProductId)}
          >
            <DeleteIcon />
          </button>
        </Stack>
      ))}
    </Stack>
  );
};

export default CartProductListMobile;
