import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CheckIcon from '@mui/icons-material/Check';

import CustomExpandIcon from '../OnboardingTaskList/CustomIcon';
import { ReactNode } from 'react';

import style from './AccordionItem.module.scss';

interface ReusableAccordionPropsI {
  title: string;
  defaultExpanded?: boolean;
  isCompleted?: boolean;
  children: ReactNode;
}

const ReusableAccordion = ({
  title,
  defaultExpanded = false,
  isCompleted = false,
  children,
}: ReusableAccordionPropsI) => (
  <Accordion defaultExpanded={defaultExpanded}>
    <AccordionSummary
      expandIcon={<CustomExpandIcon />}
      aria-controls='panel-content'
      id='panel-header'
    >
      <div className={style.wrapper}>
        {isCompleted && (
          <CheckIcon
            sx={{
              color: '#1EAE54',
              width: '20px',
              mr: '5px'
            }}
          />
        )}
        <p className={style.title}>{title}</p>
      </div>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export default ReusableAccordion;
