import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ReactComponent as EmptyCartIcon } from "../../../assets/images/empty_cart.svg";

const CartEmpty = () => {
  return (
    <Stack className="cart-main__products-list-empty">
      <EmptyCartIcon />
      <Typography variant="h5" component="p" sx={{ textAlign: "center" }}>
        Корзина пустая
      </Typography>
    </Stack>
  );
};

export default CartEmpty;
