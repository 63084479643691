import { StockOrderEdit } from 'features/Orders';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const updateOrderDate = createAsyncThunk<
  string, // Тип возвращаемого значения при успешном выполнении
  { newDate: Date; orderId: string }, // Аргументы для функции
  ThunkConfig<string> // Тип для Thunk API
>(
  'order/updateOrderDate',
  async ({ newDate, orderId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.patch(ApiRoutes.StockOrderDateEdit, {
        newDate,
        orderId,
      });

      if (!data) {
        throw new Error('No data returned');
      }

      showSuccessNotification(['Время и дата заказа обновлены']); // предположим, что у нас есть такое действие
      return data; // или return orderStatus, если API возвращает обновлённый статус
    } catch (error) {
      const { message } = apiErrorHandler(error);
      showErrorNotification([message || 'Не удалось дату и время заказа']);
      // Бросаем ошибку для обработки в extraReducers в slice
      return rejectWithValue(message); // используем rejectWithValue для передачи значения в rejected case
    }
  }
);
