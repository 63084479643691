import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OnboardingCompleteStatusI, OnboardingSchema } from '../../lib/types';
import { getUserOnboardingStatus } from '../services/getUserOnboardingStatus';

const initialState: OnboardingSchema = {
  onboardingCompleteStatus: null,
  isLoading: false,
};

const OnboardingSlice = createSlice({
  name: 'OnboardingSlice',
  initialState,
  reducers: {
  },
    extraReducers: (builder) => {
      builder
        .addCase(getUserOnboardingStatus.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getUserOnboardingStatus.fulfilled, (state, action: PayloadAction<OnboardingCompleteStatusI>) => {          
          state.isLoading = false;
          state.onboardingCompleteStatus = action.payload;
        })
        .addCase(getUserOnboardingStatus.rejected, (state, action) => {
          state.isLoading = false;
        });
    },
});

export { OnboardingSlice }

export const OnboardingReducer = OnboardingSlice.reducer;
export const OnboardingActions = OnboardingSlice.actions;
