import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const updateOrderStatus = createAsyncThunk<
  string, // Тип возвращаемого значения при успешном выполнении
  { orderStatus: string; orderId: string }, // Аргументы для функции
  ThunkConfig<string> // Тип для Thunk API
>(
  'order/updateOrderStatus',
  async ({ orderStatus, orderId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.patch(ApiRoutes.Orders, {
        orderStatus,
        orderId,
      });

      if (!data) {
        throw new Error('No data returned');
      }

      showSuccessNotification(['Статус заказа обновлён']); // предположим, что у нас есть такое действие
      return data; // или return orderStatus, если API возвращает обновлённый статус
    } catch (error) {
      const { message } = apiErrorHandler(error);
      showErrorNotification([message || 'Не удалось обновить статус заказа']);
      // Бросаем ошибку для обработки в extraReducers в slice
      return rejectWithValue(message); // используем rejectWithValue для передачи значения в rejected case
    }
  }
);
