import { createSlice } from '@reduxjs/toolkit';
import {
  CustomLinkSliceType,
  PriceFilterEnum,
  ProductViewTypeEnum,
} from '../../lib/types';

const initialState: CustomLinkSliceType = {
  content: null,
  name: '',
  categories: [],
  productCount: 0,
  sellerId: '',
  products: null,
  isLoading: false,
  access: true,

  isWhiteLableAvailable: false,
  isPremiumModulesAvailable: false,
  isUserVerified: false,
  isActive: true,
  isPaidTariff: false,

  // Фильтры

  productViewType: ProductViewTypeEnum.GRID,
};

const CustomLinkSlice = createSlice({
  name: 'CustomLinkSlice',
  initialState,
  reducers: {
    setCustomLinkParamsData: (state, action) => {
      state.content = action.payload.content;
      state.name = action.payload.name;
      state.sellerId = action.payload.sellerId;
      state.categories = action.payload.categories;
      state.isWhiteLableAvailable = action.payload.isWhiteLableAvailable;
      state.isPremiumModulesAvailable =
        action.payload.isPremiumModulesAvailable;
      state.isUserVerified = action.payload.isUserVerified;
      state.isPaidTariff = action.payload.isPaidTariff;
      state.isActive = action.payload.isActive;
    },

    setCustomLinkProducts: (state, action) => {
      state.products = action.payload.products;
      state.productCount = action.payload.productCount;
    },

    addCustomLinkProducts: (state, action) => {
      if (!state.products?.length) {
        return;
      }

      state.products = [...state.products, ...action.payload.products];
      state.productCount = action.payload.productCount;
    },

    setCustomLinkLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },

    setPrivateChannelLink: (state, action) => {
      state.privateChannelLink = action.payload;
    },

    setAccess: (state, action) => {
      state.access = action.payload;
    },

    setLinkData: (state, action) => {
      state.content = action.payload.content;
      state.products = action.payload.products;
      state.name = action.payload.name;
      state.sellerId = action.payload.sellerId;
      state.categories = action.payload.categories;
    },

    setProductViewType: (state, action) => {
      state.productViewType = action.payload;
    },
  },
});

export default CustomLinkSlice;

export const CustomLinkActions = CustomLinkSlice.actions;

export const {
  setCustomLinkParamsData,
  setCustomLinkProducts,
  addCustomLinkProducts,
  setCustomLinkLoadingStatus,
  setAccess,
  setLinkData,
  setPrivateChannelLink,
} = CustomLinkSlice.actions;
