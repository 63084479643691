import _ from 'lodash';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { TokenDataType } from '../types/user';
import { UserRole } from 'consts';

const accessTokenController = {
  save: (token: string) => {
    Cookies.set('accessToken', token);
  },

  logout: () => {
    Cookies.remove('accessToken');
  },

  isCurrentUserAdmin() {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    const { role }: TokenDataType = jwtDecode(token);
    if (role === UserRole.Admin) {
      return true;
    }

    return false;
  },

  getToken: () => {
    const accessToken = Cookies.get('accessToken');
    return accessToken || '';
  },

  decodeToken(token: string): TokenDataType | null {
    if (!token) {
      return null;
    }

    const result = jwtDecode(token);
    return result as TokenDataType;
  },

  getUserData: (): TokenDataType | null => {
    const token = accessTokenController.getToken();
    return accessTokenController.decodeToken(token);
  },
};

export default accessTokenController;
