import { ReduxStoreWithManager, type StateSchemaKey } from 'app/providers/StoreProvider/config/StateSchema';
import { type Reducer } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useAppDispatch } from 'redux/hooks';

export type ReducersList = {
  [key in StateSchemaKey]?: Reducer;
};

interface useAsyncReducerParams {
  reducers: ReducersList;
  removeAfterUnmount?: boolean;
}

type ReducersListEntry = [string, Reducer];

const useAsyncReducer = ({
    reducers,
    removeAfterUnmount = true,
}: useAsyncReducerParams): { status: undefined | string } => {
    const store = useStore() as ReduxStoreWithManager;
    const [status, setStatus] = useState<undefined | string>(undefined);
    const dispatch = useAppDispatch();

    // При монтировании компонента мы добавляем редюсер, а при демонтировании удаляем
    useEffect(() => {
        const mountedReducers = store.reducerManager.getReducerMap();
        Object.entries(reducers).forEach(([key, reducer]: ReducersListEntry) => {
            if(!mountedReducers[key as StateSchemaKey]) {
                store.reducerManager.add(key as StateSchemaKey, reducer);
                dispatch({ type: `@INIT ${key} reducer` });
                setStatus('add');
            }
        });

        return () => {
            if (removeAfterUnmount) {
                Object.entries(reducers).forEach(
                    ([key, reducer]: ReducersListEntry) => {
                        store.reducerManager.remove(key as StateSchemaKey);
                        dispatch({ type: `@DESTROY ${key} reducer` });
                        setStatus('remove');
                    },
                );
            }
        };
    }, [dispatch, reducers, removeAfterUnmount, store.reducerManager]);

    return { status };
};

export default useAsyncReducer;
