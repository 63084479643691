import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getIsOrdersDashboardLoading, getOrderDashboardDates } from '../model/selectors/orderDashboardSelectors';
import { getOrdersStats } from '../model/services/getOrdersStats';
import MainChart from './MainChart/MainChart';
import RevenueWidget from './RevenueWidget/RevenueWidget';
import OrderProductsWidget from './OrderProductsWidget/OrderProductsWidget';
import { format } from 'date-fns';

import style from './OrdersDashboard.module.scss'

const OrdersDashboard = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsOrdersDashboardLoading);
  const { dateFrom, dateTo } = useAppSelector(getOrderDashboardDates);

  useEffect(() => {
    dispatch(getOrdersStats());
  }, []);

  return (
    <div>
      <p className={style.header}>Дашборд продаж за период с {format(dateFrom, 'dd.MM.yyyy')} по {format(dateTo, 'dd.MM.yyyy')}</p>
      <div>
        <MainChart />
        <div>
          <RevenueWidget />
          <OrderProductsWidget />
        </div>
      </div>
    </div>
  );
};

export default OrdersDashboard;
