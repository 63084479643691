import { configureStore, ReducersMapObject } from "@reduxjs/toolkit";
import { CombinedState, Reducer } from "redux";
import { StateSchema, ThunkExtraArg } from "./StateSchema";
import { createReducerManager } from "./reducerManager";
import { apiInstance } from "services/apiInstance";
import ProjectSlice from "redux/ProjectSlice/ProjectSlice";
import NewLinkSlice from "redux/NewLinkSlice/NewLinkSlice";
import { promocodeSlice } from "widgets/PromocodeForm";
import paymentSlice from "redux/PaymentSlice/PaymentSlice";
import DetailProductPageSlice from "redux/DetailProductPage/DetailProductPageSlice";
import CustomerSlice from "redux/CustomerSlice/CustomerSlice";
import AnalyticsSlice from "redux/AnalyticsSlice/AnalyticsSlice";
import usersPageSlice from "redux/UsersPageSlice/UsersPageSlice";
import ordersPageSlice from "redux/OrdersSlice/OrdersPageSlice";
import LinkSlice from "redux/LinkSlice/LinkSlice";
import productsPageSlice from "redux/ProductsSlice/ProductsPageSlice";
import userSlice from "redux/UserSlice/UserSlice";

import { CustomLinkSlice } from "entities/CustomLink";
import { ReviewSlice } from "entities/Reviews";
import { OnboardingSlice } from "entities/Onboarding";
import StockSlice from "entities/Stock/model/slice/stockSlice";
import { OrderDashboardSlice } from "features/OrdersDashboard";

export function createReduxStore(
  initialState?: StateSchema,
  asyncReducers?: ReducersMapObject<StateSchema>
) {
  const rootReducers: ReducersMapObject<StateSchema> = {
    ProjectSlice: ProjectSlice.reducer,
    user: userSlice.reducer,
    productsPage: productsPageSlice.reducer,
    LinkSlice: LinkSlice.reducer,
    ordersPage: ordersPageSlice.reducer,
    CustomLinkSlice: CustomLinkSlice.reducer,
    ReviewSlice: ReviewSlice.reducer,
    usersPage: usersPageSlice.reducer,
    AnalyticsSlice: AnalyticsSlice.reducer,
    CustomerSlice: CustomerSlice.reducer,
    DetailProductPageSlice: DetailProductPageSlice.reducer,
    Payments: paymentSlice.reducer,
    Promocode: promocodeSlice.reducer,
    NewLink: NewLinkSlice.reducer,
    Onboarding: OnboardingSlice.reducer,
    Stock: StockSlice.reducer,
    ...asyncReducers,
  };

  const reducerManager = createReducerManager(rootReducers);

  const extraArg: ThunkExtraArg = {
    api: apiInstance,
  };

  const store = configureStore({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: extraArg,
        },
        serializableCheck: false
      }),
  });

  // @ts-ignore
  store.reducerManager = reducerManager;

  return store;
}

export type AppDispatch = ReturnType<typeof createReduxStore>["dispatch"];
