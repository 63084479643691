import cls from './NewOrderForm.module.scss';
import { Location } from '../Location/Location';
import { useState, memo, useEffect } from 'react';
import { OrderPayment } from '../OrderPayment/OrderPayment';
import Packages from '../Packages/Packages';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tariffs } from '../Tariffs/Tariffs';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getPackages,
  getLocationFrom,
  getLocationTo,
  getPoint,
} from '../../model/selectors/newOrderSdekSelectors';
import { Recipient } from '../Recipient/Recipient';
import { Button, Container, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createOrderSdek } from '../../model/services/createOrderSdek';
import useAsyncReducer, {
  ReducersList,
} from 'shared/lib/hooks/useAsyncReducer';
import { CreateOrderSdekReducer } from '../../model/slice/newSdekOrderSlice';
import { initRouteOrderState } from '../../model/services/initRouteOrderState';

// TODO: убрать в виджеты, тк тут есть архитектурное нарушени (импорт фичи в фичу)

const reducers: ReducersList = {
  sdekOrder: CreateOrderSdekReducer,
};

export const NewOrderForm = memo(() => {
  useAsyncReducer({ reducers });
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const packages = useAppSelector(getPackages);
  const locationFrom = useAppSelector(getLocationFrom);
  const locationTo = useAppSelector(getLocationTo);
  const navigate = useNavigate();
  const point = useAppSelector(getPoint);
  const location = useLocation();

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    setIsLoading(true);
    dispatch(createOrderSdek()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate('/dashboard/logistics/orders');
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (Boolean(location.state)) {
      dispatch(initRouteOrderState({ ...location.state }));
    }
  }, []);

  return (
    <Container maxWidth='xl'>
      <Button component={Link} to='/dashboard/logistics/orders'>
        Назад
      </Button>
      <Typography variant='h3' component='h1' sx={{ mb: '2rem' }}>
        Создание заказа в СДЕК
      </Typography>
      <form onSubmit={onSubmit} className={cls.NewOrderForm}>
        <Location />
        <Recipient />
        <Packages />
        <OrderPayment />

        <Tariffs
          orderTariffData={{
            fromLocationFiasCode: locationFrom?.fiasCode ?? '',
            toLocationFiasCode: locationTo?.fiasCode ?? '',
            packages: Object.values(packages) || [],
            pickupType: point?.pickupType,
          }}
        />

        <LoadingButton
          loading={isLoading}
          variant='contained'
          type='submit'
          sx={{ my: '1rem' }}
        >
          Создать
        </LoadingButton>
      </form>
    </Container>
  );
});
