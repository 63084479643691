import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { showErrorNotification } from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getStockPagination } from '../selectors/stockSelectors';

// Предположим, что тип данных запасов — StockData
// Нужно определить этот тип в соответствии с тем, что возвращает твой API
type StockData = any; // Замените any на реальный тип данных

export const getStockData = createAsyncThunk<
  StockData | undefined,
  void,
  ThunkConfig<string>
>('stock/getStockData', async (_, thunkApi) => {
  const {
    extra: { api },
    getState,
  } = thunkApi;
  try {
    const { page, pageSize } = getStockPagination(getState());
    const { data } = await api.get(ApiRoutes.Stock, {
      params: { page, pageSize },
    });    

    return data;
  } catch (error) {
    const { message } = apiErrorHandler(error);
    showErrorNotification([message]);
    // Важно возвращать явно undefined, если функция должна завершиться после ошибки
    return undefined;
  }
});
