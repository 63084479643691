import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDimensionsPackages,
  getLocationFrom,
  getLocationTo,
  getOrderSdekTariff,
  getPackagesList,
  getPoint,
  getPriceCOD,
  getSdekRecipientName,
  getSdekRecipientPhone,
} from '../selectors/newOrderSdekSelectors';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';
import { validationOrderCreate } from '../../lib/validation/validationOrderCreate';
import { ApiRoutes } from 'consts';
import { CreateOrderSdekActions } from '../slice/newSdekOrderSlice';
import { AxiosError } from 'axios';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const createOrderSdek = createAsyncThunk<
  boolean,
  undefined,
  ThunkConfig<string>
>('sdek/createOrderSdek', async (_args, thunkApi) => {
  const {
    extra: { api },
    getState,
    dispatch,
    rejectWithValue,
  } = thunkApi;

  const state = getState();

  // если выглядит страшно можно прокинуть параметрами
  const tariff = getOrderSdekTariff(state);
  const dimensions = getDimensionsPackages(state);
  const locationTo = getLocationTo(state);
  const point = getPoint(state);
  const toLocationFiasCode = locationTo?.fiasCode;
  const fromLocationFiasCode = getLocationFrom(state)?.fiasCode;
  const packages = getPackagesList(state);
  const pickupType = point?.pickupType ?? '';

  const priceCOD = getPriceCOD(state);
  const recipientName = getSdekRecipientName(state);
  const recipientPhone = getSdekRecipientPhone(state);

  const {
    isValid,
    packagesErrors,
    locationErrors,
    tariffErrors,
    recipientErrors,
  } = await validationOrderCreate({
    tariff,
    dimensions,
    fromLocationFiasCode,
    toLocationFiasCode,
    packages,
    pickupType,
    recipientName,
    recipientPhone,
    priceCOD,
  });
  if (!isValid) {
    dispatch(
      CreateOrderSdekActions.setErrors({
        packagesErrors,
        locationErrors,
        tariffErrors,
        recipientErrors,
      })
    );
    throw new AxiosError('Некорретно заполнена форма');
  }
  tariff?.tariff_code;
  try {
    const { data } = await api.post(ApiRoutes.SdekOrder, {
      fromLocationFiasCode,
      pvzCode: point?.deliveryPointCode,
      tariffCode: tariff?.tariff_code,
      packages,
      priceCOD,
      recipientName,
      recipientPhone: recipientPhone.replaceAll(' ', ''), // убираем пробелы, тк валидация бека не пропустит
      maxPrice: tariff?.delivery_sum,
    });

    showSuccessNotification([data.message]);

    return true;
  } catch (error) {
    const { message } = apiErrorHandler(error);
    showErrorNotification([message]);
    return rejectWithValue('error');
  }
});
