import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useAppDispatch } from 'redux/hooks';
import { setUserAuthStatus } from 'redux/UserSlice/UserSlice';
import { UserAuthStatus } from 'consts';

// ----------------------------------------------------------------------

type LogoProps = {
  className?: string;
  disabledLink?: boolean;
};

const Logo = (props: LogoProps) => {
  const { disabledLink = false, className } = props;
  const logo = <img src='/assets/images/logo-icon.svg' alt='логотип LinkWi' />;
  const dispatch = useAppDispatch();

  if (disabledLink) {
    return <>{logo}</>;
  }

  const handleClickOnLogo = () => {
    dispatch(dispatch(setUserAuthStatus(UserAuthStatus.Unknowed)));
  };

  return (
    <Link
      to='/'
      component={RouterLink}
      sx={{ display: 'contents', color: 'text.primary' }}
      onClick={handleClickOnLogo}
    >
      {logo}
    </Link>
  );
};

export default Logo;
