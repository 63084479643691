import { Popover } from 'shared/ui/Popover/Popover';
import { VerifiedPopoverTitle } from './VerifiedPopoverTitle/VerifiedPopoverTitle';
import { VerifiedPopoverContent } from './VerifiedPopoverContent/VerifiedPopoverContent';
import { useAppSelector } from 'redux/hooks';
import { getCustomLinkContent } from 'entities/CustomLink';

const anchorOrigin = {
  vertical: 'center' as const,
  horizontal: 'center' as const,
};

export const VerifiedPopover = () => {
  const linkContent = useAppSelector(getCustomLinkContent);

  return (
    <Popover
      title={<VerifiedPopoverTitle />}
      anchorOrigin={anchorOrigin}
      sx={{ background: linkContent?.generalBlock?.content?.bgColor }}
    >
      <VerifiedPopoverContent />
    </Popover>
  );
};
