import { SearchField } from 'shared/ui/SearchField';
import { Filters } from '../Filters';

import style from './ToolBar.module.scss';
import useSyncSearchWithUrl from '../../hooks/useSyncSearchWithURL';
import { useMediaQuery } from '@mui/material';
import MobileSearchFieldWithTools from './MobileSearchFieldWithTools';

const ToolBar = () => {
  const { searchValue, updateSearch } = useSyncSearchWithUrl();

  const handleSearchChange = (newValue: string) => {
    updateSearch(newValue);
    
  };

  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <div className={style.wrapper}>
      {isMobile ? (
        <MobileSearchFieldWithTools
          searchValue={searchValue || ''}
          handleSearchChange={handleSearchChange}
        />
      ) : (
        <>
          <SearchField
            value={searchValue || ''}
            handleSearchChange={handleSearchChange}
          />
          <Filters />
        </>
      )}
    </div>
  );
};

export { ToolBar };
