import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { showErrorNotification } from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { ApiRoutes } from 'consts';
import { OnboardingCompleteStatusI } from '../../lib/types';

export const getUserOnboardingStatus = createAsyncThunk<
OnboardingCompleteStatusI,
  undefined,
  ThunkConfig<string>
>('onboarding/getUserOnboardingStatus', async (_, thunkApi) => {
  const { extra, rejectWithValue, fulfillWithValue } = thunkApi;

  try {
    const response = await extra.api.get<OnboardingCompleteStatusI>(ApiRoutes.Onboarding);

    fulfillWithValue(response.data);

    return response.data;
  } catch (error) {
    const { message } = apiErrorHandler(error);
    showErrorNotification([message]);
    return rejectWithValue(message);
  }
});
