import DashboardLayout from "app/layouts/dashboard/DashboardLayout";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
const UsersPage = lazy(() => import("pages/UsersPage/UsersPage"));
const CustomLinksPage = lazy(() => import("pages/CustomLinksPage/CustomLinksPage"));

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route path="/users" element={<UsersPage />} />
        <Route path="/links" element={<CustomLinksPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
