import { createSlice } from "@reduxjs/toolkit";
import { AnalyticsType } from "types/analytics";

export type AnalyticsSliceType = {
  analytics: { [linkId: string]: AnalyticsType };
  isLoading: boolean;
  error: string;
  ordersRevenue: number;
};

const initialState: AnalyticsSliceType = {
  analytics: {},
  isLoading: false,
  error: "",
  ordersRevenue: 0,
};

const AnalyticsSlice = createSlice({
  name: "AnalyticsSlice",
  initialState,
  reducers: {
    setAnalytics: (state, action) => {
      state.analytics = action.payload;
    },
    setAnalyticsLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    setAnalyticsErrorMessage: (state, action) => {
      state.error = action.payload;
    },
    resetAnalyticsErrorMessage: (state) => {
      state.error = "";
    },
    setOrdersAnalytics: (state, action) => {
      state.ordersRevenue = action.payload;
    },
  },
});

export default AnalyticsSlice;

export const {
  setAnalytics,
  setAnalyticsLoadingStatus,
  setAnalyticsErrorMessage,
  resetAnalyticsErrorMessage,
  setOrdersAnalytics,
} = AnalyticsSlice.actions;
