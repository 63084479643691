import { format } from 'date-fns';
import { TariffTheme, TranslatedTariffName } from 'types/tariffs';


export const getMarginPriceFromString = (price: string): number => {
  return Number(price);
};

export const getTariffTheme = (title: string) => {
  switch (title) {
    case TranslatedTariffName.WHOLESALE:
      return TariffTheme.Orange;
    case TranslatedTariffName.BUSINESS:
      return TariffTheme.Yellow;
    case TranslatedTariffName.STANDART:
      return TariffTheme.Blue;

    default:
      return TariffTheme.Grey;
  }
};

export const getFormatedDate = (endDate?: Date) => format(new Date(endDate || new Date()), 'dd.MM.yyyy');
