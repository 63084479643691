import { Button } from '@mui/material';
import { ReviewType, setCurrentReview } from 'entities/Reviews';
import { Rating } from 'react-simple-star-rating';
import { useAppDispatch } from 'redux/hooks';

export const ShowReview = ({ review }: { review: ReviewType }) => {
  const dispatch = useAppDispatch();

  const handleShowReview = () => {
    dispatch(setCurrentReview(review));
  };

  return (
    <div>
      <Rating
        initialValue={review.rating}
        size={22}
        readonly
        tooltipStyle={{
          backgroundColor: 'white',
          color: 'inherit',
          fontSize: '14px',
          marginLeft: 0,
          paddingTop: 0,
        }}
      />
      <Button sx={{ fontSize: '12px' }} onClick={handleShowReview}>
        посмотреть отзыв
      </Button>
    </div>
  );
};
