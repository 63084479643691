import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getPackages = (state: StateSchema) => state?.sdekOrder?.packages ?? {};

export const getPackagesList = createSelector(getPackages, (packages) =>
  Object.values(packages)
);

export const getPriceCOD = (state: StateSchema) => state?.sdekOrder?.priceCOD;

export const getOfficeListLocationTo = (state: StateSchema) =>
  state?.sdekOrder?.officeListLocationTo;

export const getOfficeListLocationFrom = (state: StateSchema) =>
  state?.sdekOrder?.officeListLocationFrom;

export const getLocationTo = (state: StateSchema) =>
  state?.sdekOrder?.locationTo;

export const getLocationFrom = (state: StateSchema) =>
  state?.sdekOrder?.locationFrom;

export const getSdekRecipientPhone = (state: StateSchema) =>
  state?.sdekOrder?.phone ?? '';

export const getSdekRecipientName = (state: StateSchema) =>
  state?.sdekOrder?.name ?? '';

export const getDimensionsPackages = (state: StateSchema) =>
  state?.sdekOrder?.dimensions;

export const getOrderSdekTariff = (state: StateSchema) =>
  state?.sdekOrder?.tariff ?? null;

export const getTariffError = (state: StateSchema) =>
  state?.sdekOrder?.errors?.tariffErrors ?? {};

export const getPackageError = (state: StateSchema) =>
  state?.sdekOrder?.errors?.packagesErrors ?? {};

export const getRecipientError = (state: StateSchema) =>
  state?.sdekOrder?.errors?.recipientErrors ?? {};

export const getLocationsError = (state: StateSchema) =>
  state?.sdekOrder?.errors?.locationErrors ?? {};

export const getPoint = (state: StateSchema) => state?.sdekOrder?.point ?? null;
