import cls from './Location.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CreateOrderSdekActions } from '../../model/slice/newSdekOrderSlice';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import BasicModal from 'components/common/BasicModal/BasicModal';
import { useState, memo } from 'react';
import { IAutocomplitingAddress } from '../../model/types/location';
import { FormControl, FormLabel } from '@mui/material';
import { AddressInput } from 'features/FeatchAddressInput';
import { FindDeliveryPoint } from '../FindDeliveryPoint/FindDeliveryPoint';
import {
  getLocationFrom,
  getLocationTo,
  getLocationsError,
  getPoint,
} from '../../model/selectors/newOrderSdekSelectors';
import { getOrderDimensions } from '../../lib/helpers/package';

export const Location = memo(() => {
  const [sdekPointModal, openSdekPointModal] = useState(false);

  const locationTo = useAppSelector(getLocationTo);
  const locationFrom = useAppSelector(getLocationFrom);
  const errors = useAppSelector(getLocationsError);
  const point = useAppSelector(getPoint);
  const dispatch = useAppDispatch();

  const clearPointFields = () => {
    dispatch(
      CreateOrderSdekActions.setPoint({
        deliveryPointCode: '',
        deliveryPointName: '',
        deliveryPointAddress: '',
        deliveryPointOpenHours: '',
      })
    );
  };

  const setCityFrom = (location: IAutocomplitingAddress) => {
    dispatch(CreateOrderSdekActions.setLocationFrom(location));
  };
  const setCityTo = (location: IAutocomplitingAddress) => {
    clearPointFields();
    dispatch(CreateOrderSdekActions.setLocationTo(location));
  };

  const setPoint = (point: any) => {
    dispatch(
      CreateOrderSdekActions.setPoint({
        lat: point.location.latitude,
        lon: point.location.longitude,
        deliveryPointCode: point.code,
        deliveryPointName: point.name,
        deliveryPointAddress: point.location.address,
        deliveryPointOpenHours: point.work_time,
        pickupType: point.type,
      })
    );

    const orderDimensions = getOrderDimensions(point);
    dispatch(CreateOrderSdekActions.setDimensionsPackages(orderDimensions));
  };

  return (
    <>
      <FormControl component='fieldset' variant='standard'>
        <FormLabel id='recipient-group'>Детали заказа</FormLabel>
        <div className={cls.Location}>
          <AddressInput
            id='locationFrom'
            label='Город отправителя'
            value={locationFrom?.value ?? ''}
            setLocation={setCityFrom}
            errorMessage={errors?.fromLocationFiasCode ?? ''}
            onlyCities
          />
          <AddressInput
            id='locationTo'
            label='Город получателя'
            value={locationTo?.value ?? ''}
            setLocation={setCityTo}
            errorMessage={errors?.toLocationFiasCode}
            onlyCities
          />
        </div>

        {locationTo?.value && (
          <BaseInput
            fullWidth
            id='sdek-point'
            name='sdek-point'
            label='Выбранный клиентом пвз'
            InputLabelProps={{ shrink: true }}
            value={point?.deliveryPointAddress}
            onClick={() => openSdekPointModal(true)}
            sx={{ mt: '1rem' }}
            required
            error={Boolean(errors?.pickupType)}
            helperText={errors?.pickupType}
          />
        )}
      </FormControl>

      {locationTo && (
        <BasicModal
          open={sdekPointModal}
          onClose={() => openSdekPointModal(false)}
        >
          <FindDeliveryPoint
            onCloseModal={() => openSdekPointModal(false)}
            setPoint={setPoint}
            deliveryPointCode={point?.deliveryPointCode}
            latitude={Number(locationTo?.lat)}
            longitude={Number(locationTo?.lon)}
            fiasCode={locationTo?.fiasCode}
            deliveryPointAddress={point?.deliveryPointAddress}
          />
        </BasicModal>
      )}
    </>
  );
});
