import cls from './Recipient.module.scss';
import { FormControl, FormLabel } from '@mui/material';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import BasePhoneInput from 'components/ui/BasePhoneInput/BasePhoneInput';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getRecipientError,
  getSdekRecipientName,
  getSdekRecipientPhone,
} from '../../model/selectors/newOrderSdekSelectors';
import { CreateOrderSdekActions } from '../../model/slice/newSdekOrderSlice';
import { memo } from 'react';

export const Recipient = memo(() => {
  const dispatch = useAppDispatch();
  const phone = useAppSelector(getSdekRecipientPhone);
  const name = useAppSelector(getSdekRecipientName);
  const errors = useAppSelector(getRecipientError);

  return (
    <FormControl component='fieldset' variant='standard' sx={{ mt: '2rem' }}>
      <FormLabel id='recipient-group'>Получатель</FormLabel>
      <div className={cls.Recipient}>
        <BaseInput
          fullWidth
          id='person'
          name='nameRecipient'
          label='ФИО'
          InputLabelProps={{ shrink: true }}
          onChange={(evt) =>
            dispatch(CreateOrderSdekActions.setName(evt.target.value))
          }
          sx={{ mt: '1rem' }}
          value={name}
          error={Boolean(errors?.recipientName)}
          helperText={errors?.recipientName}
        />
        <BasePhoneInput
          id='phone'
          hiddenLabel
          variant='filled'
          onlyCountries={['RU']}
          langOfCountryName='ru'
          label='Номер телефона'
          defaultCountry='RU'
          onChange={(phone) => dispatch(CreateOrderSdekActions.setPhone(phone))}
          value={phone}

          inputProps={{
            maxLength: 16,
            autoComplete: 'off',
          }}
          error={Boolean(errors?.recipientPhone)}
          helperText={errors?.recipientName}
        />
      </div>
    </FormControl>
  );
});
