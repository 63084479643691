import { memo } from 'react';

const SearchIcon = ({ fill = '#FFFFFF' }: { fill: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
    >
      <g clipPath='url(#clip0_278_7340)'>
        <path
          d='M10.7798 18C15.0323 18 18.4796 14.6421 18.4796 10.5C18.4796 6.35786 15.0323 3 10.7798 3C6.52737 3 3.08008 6.35786 3.08008 10.5C3.08008 14.6421 6.52737 18 10.7798 18Z'
          stroke={fill}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.2241 15.8035L21.5591 21'
          stroke={fill}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_278_7340'>
          <rect width='24.6392' height='24' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(SearchIcon);
