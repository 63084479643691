import { isEmpty } from 'lodash';
import { IAutocomplitingAddress, PointType } from 'features/sdek/NewSdekOrder';
import { matchIsValidTel } from 'mui-tel-input';
import { showErrorNotification } from 'utils/notification';
import { getValidationError } from 'utils/validation';
import { boolean, object, string } from 'yup';

const checkTgNick = (tgNick: string | undefined) => {
  if (!tgNick) {
    return true;
  }
  if (tgNick[0] === '@') {
    return true;
  }
  return false;
};

const CartDeliveryValidationSchema = object({
  name: string().required('Имя обязательное поле'),
  surName: string().required('Фамилия обязательное поле'),
  address: object().nullable().required('Адрес оязательное поле'),
  phone: string().test('phone', 'Некорректный телефон', (phone) => {
    if (!phone) {
      return false;
    }

    return matchIsValidTel(phone);
  }),
  tgNick: string().test((value) => {
    return checkTgNick(value);
  }),
  point: object().nullable().test('point-is-need', 'Выберите точку доставки', (value, content) => {
    if(!Boolean(content.parent.isUseSdek)){
      return true;
    }

    if(value && !isEmpty(value)){
      return true;
    }

    return false;
  }),
});

interface clientDataValidationI {
  name: string;
  surName: string;
  fatherName: string;
  phone: string;
  address: IAutocomplitingAddress | null;
  comment: string;
  tgNick: string;
  point: PointType | null;
  isUseSdek: boolean;
}

export const CartDeliveryValidation = async (
  clientData: clientDataValidationI
): Promise<{ isValid: boolean; errors: null | string[] }> => {
  const loginValidateResult = await getValidationError<clientDataValidationI>(
    CartDeliveryValidationSchema,
    clientData
  );

  const errors = Object.values(loginValidateResult);

  if (errors.length > 0) {
    showErrorNotification(errors);
    return { isValid: true, errors: null };
  }

  return { isValid: false, errors };
};
