import { createSlice } from "@reduxjs/toolkit";
import { UserAuthStatus } from "consts";
import { TelergamDataType } from "types/user";

export type CustomerSliceType = {
  customerId: string;
  isLoading: boolean;
  error: string;
  customerAuthStatus: UserAuthStatus;
  role: string;
  phone: string;
  requestStatus: number;
  telegramData: TelergamDataType;
};

const initialState: CustomerSliceType = {
  customerId: "",
  isLoading: false,
  error: "",
  customerAuthStatus: UserAuthStatus.Unknowed,
  role: "",
  phone: "",
  requestStatus: 0,
  telegramData: { telegramVerificationCode: "", telegramId: "" },
};

const CustomerSlice = createSlice({
  name: "CustomerSlice",
  initialState,
  reducers: {
    setCustomerLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    setCustomerAuthStatus: (state, action) => {
      state.customerAuthStatus = action.payload;
    },
    setCustomerErrorMessage: (state, action) => {
      state.error = action.payload;
    },
    setCustomerPhone: (state, action) => {
      state.phone = action.payload;
    },
    resetCustomerErrorMessage: (state) => {
      state.error = "";
    },
    setCustomerRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setCustomerRole: (state, action) => {
      state.role = action.payload;
    },
    setCustomerTelegramData: (state, action) => {
      state.telegramData = action.payload;
    },
  },
});

export default CustomerSlice;

export const {
  setCustomerLoadingStatus,
  setCustomerAuthStatus,
  setCustomerErrorMessage,
  resetCustomerErrorMessage,
  setCustomerPhone,
  setCustomerRequestStatus,
  setCustomerId,
  setCustomerRole,
  setCustomerTelegramData,
} = CustomerSlice.actions;
