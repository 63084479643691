import { memo } from 'react';

interface ShowErrorOnPageProps {
  errorMessage?: string;
}

export const ShowErrorOnPage = memo(
  ({ errorMessage }: ShowErrorOnPageProps) => {
    if (!errorMessage) {
      return null;
    }

    return (
      <span style={{ marginTop: '20px', color: 'red' }}>{errorMessage}</span>
    );
  }
);
