import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getCurrentProductPrice = (state: StateSchema) =>
  state?.CartSlice?.cartProducts;

export const getCartProductsObj = (state: StateSchema) =>
  state.CartSlice?.cartProducts ?? {};

export const getCartProducts = createSelector(
  [getCartProductsObj],
  (getCartProductsObj) => {
    return Object.values(getCartProductsObj ?? {});
  }
);

export const getSummaryPrice = createSelector(
  [getCartProductsObj],
  (getCartProductsObj) => {
    return Object.values(getCartProductsObj ?? {}).reduce(
      (acc: number, item) => acc + item.finalPrice * item.count,
      0
    );
  }
);

export const getCartClientData = (state: StateSchema) =>
  state.CartSlice?.clientData;

export const getCartSellerId = (state: StateSchema) =>
  state.CartSlice?.sellerId ?? '';

export const getCartPoint = (state: StateSchema) =>
  state.CartSlice?.point ?? null;

export const getCartAddress = (state: StateSchema) =>
  state.CartSlice?.address ?? null;
