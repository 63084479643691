import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "app/providers/StoreProvider";
import { ApiRoutes } from "consts";
import { ReviewType } from "entities/Reviews";
import { resetReviewError, setReviewError } from "entities/Reviews";
import { setCurrentOrder } from "redux/OrdersSlice/OrdersPageSlice";
import { apiErrorHandler } from "services/apiErrorHandler";
import { showErrorNotification, showSuccessNotification } from "utils/notification";

export const postReview = createAsyncThunk<
  void,
  ReviewType,
  ThunkConfig<string>
>("review/postReview", async (review, { dispatch, extra: { api } }) => {
  try {
    const formData = new FormData();
    formData.append('reviewText', review.reviewText);
    formData.append('rating', review.rating.toString());
    formData.append('productId', review.productId);
    formData.append('orderId', review.orderId);

    // Добавляем изображения в formData
    if (review.images) {
      review.images.forEach((image, index) => 
        formData.append(`images`, image)
      );
    }

    const { data } = await api.post<ReviewType>(ApiRoutes.Reviews, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (data) {
      dispatch(setCurrentOrder(null));
      showSuccessNotification(['Отзыв успешно отправлен']);
    }
  } catch (error) {
    const { message } = apiErrorHandler(error);
    dispatch(setReviewError(message));
    showErrorNotification([message]);

    setTimeout(() => {
      dispatch(resetReviewError());
    }, 3000);
  }
});
