import request from "axios";
import propertyOf from 'lodash/propertyOf';

import accessTokenController from "./accessTokenController";

export const apiErrorHandler = (error: unknown) => {
  if (!request.isAxiosError(error)) {
    throw error;
  }

  const { response } = error;

  if (!response) {
    return handleError("Отсутствует подключение к интернету или сервер не отвечает");
  }

  if (response?.status === 413) {
    return handleError("Объект запроса больше, чем ограничения, определённые сервером");
  }  

  if (response?.data.message) {
    return handleError(response?.data.message, response.status);
  }

  return { status: "", message: "Что-то пошло не так" };
};

const handleError = (message: string, status: number | string = '') => ({
  status,
  message,
});
