import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  resetReviewError,
  setReviewError,
  setReviewLoadingStatus,
  setReviewProductId,
} from "./ReviewSlice";
import { apiErrorHandler } from "services/apiErrorHandler";
import { setReviewsList } from "./ReviewSlice";
import { ThunkConfig } from "app/providers/StoreProvider";
import { ReviewType } from "../types/review";
import { ApiRoutes } from "consts";
import { setCurrentOrder } from "redux/OrdersSlice/OrdersPageSlice";
import { showSuccessNotification } from "utils/notification";

export const fetchReviewsAction = createAsyncThunk<
  void,
  { productId: string },
  ThunkConfig<string>
>(
  "review/fetchReviews",
  async ({ productId: productId }, { dispatch,extra: { api } }) => {
    dispatch(setReviewLoadingStatus(true));
    dispatch(resetReviewError());
    try {
      const { data } = await api.get(`${ApiRoutes.Reviews}/${productId}`, {
        params: { productId },
      });

      if (data) {
        dispatch(setReviewsList(data));
        dispatch(setReviewLoadingStatus(false));
      }
    } catch (error) {
      dispatch(setReviewLoadingStatus(false));
      const { message, status } = apiErrorHandler(error);
      dispatch(setReviewError(`${status} ${message}`));
      setTimeout(() => {
        dispatch(resetReviewError());
      }, 3000);
    }
  }
);
