import { CircularProgress, Stack } from '@mui/material';
import ProductCard from 'components/Products/ProductCard/ProductCard';
import CustomLinkAuthBtn from 'components/CustomLinkPageContent/CustomLinkAuthBtn/CustomLinkAuthBtn';
import { ProductCardType } from 'types/products';
import { ToolBar } from 'entities/CustomLink';
import { useCustomLinkData } from 'entities/CustomLink/hooks/useCustomLinkData';
import { ProductViewTypeEnum } from 'entities/CustomLink/lib/types';
import { useAppSelector } from 'redux/hooks';
import {
  getCustomLinkLoadingStatus,
  getCustomLinkProductsData,
} from 'entities/CustomLink/model/selectors';

import style from './CustomLinkProductList.module.scss';
import CustomLinkCartPreview from 'components/CustomLinkCartPreview/CustomLinkCartPreview';
import PaginationRanges from 'entities/CustomLink/ui/PaginationRanges/PaginationRanges';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

const CustomLinkProductList = () => {
  const { productViewType } = useCustomLinkData();
  const isLoading = useAppSelector(getCustomLinkLoadingStatus);
  const productList = useAppSelector(getCustomLinkProductsData);

  const isWhite = useIsWhiteMoreContrast();

  const renderProducts = (product: ProductCardType) => {
    if (isLoading) {
      return (
        <CircularProgress
          sx={{
            color: isWhite ? '#000000' : '#FFFFFFF',
            margin: '100px auto'
          }}
        />
      );
    }

    return (
      <ProductCard
        key={product.productId}
        product={product}
        productViewType={productViewType}
      />
    );
  };

  return (
    <div className={style.wrapper}>
      <CustomLinkAuthBtn />
      <ToolBar />
      <Stack
        className={
          style[
            `products${
              productViewType === ProductViewTypeEnum.GRID ? 'Cards' : 'List'
            }`
          ]
        }
        alignItems='center'
      >
        {productList?.map(renderProducts)}
      </Stack>
      <PaginationRanges />
      <CustomLinkCartPreview className={style.cartPreview} />
    </div>
  );
};

export default CustomLinkProductList;
