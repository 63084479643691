import { LinearProgress } from '@mui/material';
import {
  DAY_BY_COMPLETED_ONBOARDING_TASK,
  DEFAULT_NEW_USER_FREE_DAYS,
  MAX_NEW_USER_FREE_DAYS,
} from 'entities/Onboarding/lib/const';

import style from './ProgressBar.module.scss';
import { useAppSelector } from 'redux/hooks';
import { getOnboardingData } from 'entities/Onboarding/model/selectors/onboardingSelectors';
import { useCallback } from 'react';

const normalise = (value: number) =>
  ((value - 0) * 100) /
  (MAX_NEW_USER_FREE_DAYS - 0);

const ProgressBar = () => {
  const onboardingStatusCompleted = useAppSelector(getOnboardingData);  

  const countFreeDay = useCallback(() => {
    if (!onboardingStatusCompleted) {
      return DEFAULT_NEW_USER_FREE_DAYS;
    }

    const values = Object.values(onboardingStatusCompleted);

    // Считаем количество true значений
    const trueCount = values.filter((value) => value === true).length;

    return DEFAULT_NEW_USER_FREE_DAYS + trueCount * DAY_BY_COMPLETED_ONBOARDING_TASK;
  }, [onboardingStatusCompleted])();
  return (
    <div className={style.wrapper}>
      <div className={style.labelWrapper}>
        <span className={style.title}>Бонусные дни</span>
        <span className={style.counter}>
          {countFreeDay} из {MAX_NEW_USER_FREE_DAYS}
        </span>
      </div>
      <LinearProgress variant='determinate' value={normalise(countFreeDay)} />
    </div>
  );
};

export default ProgressBar;
