import { DeliveryPointI } from 'features/sdek/NewSdekOrder/model/types/location';
import { CustomMarker } from './CustomMarker';
import { MarkerProps } from 'react-map-gl';

type CustomMarkerListProps = {
  officeListLocation: DeliveryPointI[];
  deliveryPointCode?: string;
  handleMarkerClick: (pointData: MarkerProps) => void;
};

export const CustomMarkerList = ({
  officeListLocation,
  deliveryPointCode,
  handleMarkerClick,
}: CustomMarkerListProps) => {
  if (!officeListLocation) {
    return null;
  }

  return (
    <>
      {officeListLocation.map((el) => (
        <CustomMarker
          key={el.code}
          pointData={el}
          deliveryPointCode={deliveryPointCode}
          handleMarkerClick={handleMarkerClick}
        />
      ))}
    </>
  );
};
