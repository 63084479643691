import { StateSchema } from "app/providers/StoreProvider";
import { CurrentOrderType, OrderType } from "types/order";

export const getOrdersList = (state: StateSchema): OrderType[] => {
  return Object.values(state.ordersPage.ordersList);
};

export const getCurrentOrder = (state: StateSchema): CurrentOrderType | null => {
  return state.ordersPage.currentOrder;
};

export const getOrderLoadingStatus = (state: StateSchema): boolean => {
  return state.ordersPage.isLoading;
};
