import {
  Box,
  Paper,
  useMediaQuery,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useAppSelector } from "redux/hooks";
import CartEmpty from "../CartEmpty/CartEmpty";
import CartProductList from "../CartProductList/CartProductList";
import CartProductListMobile from "../CartProductListMobile/CartProductListMobile";
import CartTableHead from "../CartTableHead/CartTableHead";
import { getCartProducts } from "entities/Cart";

const CartProductsBlock = () => {
  const products = useAppSelector(getCartProducts);
  const matches = useMediaQuery("(max-width:650px)");

  if (products.length <= 0) {
    return <CartEmpty />;
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "start", width: "100%" }}>
        <p className="cart-main__name">Товары</p>
        <div className="cart-main_count-products-wrapper">
          <p className="cart-main_count-products">{products.length}</p>
        </div>
      </Box>

      {matches ? (
        <CartProductListMobile products={products} />
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: "0" }}>
          <Table sx={{ minWidth: "650px" }} aria-label="simple table">
            <CartTableHead />
            <TableBody>
              <CartProductList products={products} />
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CartProductsBlock;
