import { ApiRoutes } from 'consts';
import { IAutocomplitingAddress } from 'features/sdek/NewSdekOrder/model/types/location';
import { propertyOf } from 'lodash';
import { apiInstance } from 'services/apiInstance';

export const featchAddress = async (query: string, onlyCities: boolean = false) => {
  const { data } = await apiInstance.get(ApiRoutes.Addresses, {
    params: { query, onlyCities },
  });

  return data.address.map((address: IAutocomplitingAddress) => ({
    value: address.value,
    fiasCode: propertyOf(address)(['data', 'fias_id']),
    lat: propertyOf(address)(['data', 'geo_lat']),
    lon: propertyOf(address)(['data', 'geo_lon']),
    postalCode: propertyOf(address)(['data', 'postal_code']),
  }));
};
