import cls from './TariffTypeBlock.module.scss';

export const TariffTypeBlock = () => {
  return (
    <div className={cls.TariffTypeBlock}>
      <p className={cls.info}>
        Вы используете бесплатный тариф. Возможности сервиса ограничены
      </p>
      <a className={cls.link} href='https://wiki.linkwi.ru/ru/tariff-restriction'>
        Подробнее
      </a>
    </div>
  );
};
