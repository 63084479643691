import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { memo } from 'react';

export const SnackbarCloseButton = memo(({ snackbarKey }: any) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon sx={{ color: '#fff' }} />
    </IconButton>
  );
});
