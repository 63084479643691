import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useAppSelector } from 'redux/hooks';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getCurrentOrderData } from 'features/Orders/model/selectors/stockOrderEditSelectors';
import { format } from 'date-fns';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function ExportOrderData() {
  const { orderProducts, orderId, customerInfo, createdAt } = useAppSelector(getCurrentOrderData);

  if (!orderProducts) {
    return null;
  }

  const handleExportData = () => {
    const data = orderProducts.map((product, index) => {
      const options =
        product.orderProduct.options.length > 0
          ? product.orderProduct.options.map((option: any) => option.value).join(',')
          : 'Опции не заданы';
      return [
        index + 1,
        product.name ? product.name :'Товар удален',
        options,
        product.orderProduct.finalPrice ?? product.price.currentPrice,
        product.orderProduct.count,
      ];
    });

    if (!customerInfo) {
      return null;
    }

    const { surName, name, fatherName, address, phone, tgNick } = customerInfo;

    var dd = {
      content: [
        {
          text: `Заказ (${orderId}):\n\n`,
          style: 'header',
          bold: true,
          fontSize: 16,
        },

        {
          text: `Заказчик: ${surName} ${name} ${
            fatherName ?? ''
          }`,
          style: 'subheader',
        },
        {
          text: `Адрес: ${address}`,
          style: 'subheader',
        },
        {
          text: `Телефон: ${phone}`,
          style: 'subheader',
        },
        tgNick && {
          text: `Телеграмм: ${tgNick}`,
          style: 'subheader',
        },
        {
          text: `Дата заказа: ${format(createdAt, 'dd.MM.yyyy hh:mm:ss')}`,
          style: 'subheader',
        },
        {
          text: `\n\n`,
        },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['auto', '*', 'auto', 'auto', 'auto'],

            body: [
              [
                { text: 'п/п', bold: true },
                { text: 'Наименование', bold: true },
                { text: 'Опции', bold: true },
                { text: 'Цена за ед.', bold: true },
                { text: 'Кол-во', bold: true },
              ],
              ...data,
              [
                '',
                '',
                { text: 'Сумма', bold: true },
                `${orderProducts.reduce(
                  (accum, product) =>
                    accum +
                    product.orderProduct.count *
                      (product.orderProduct.finalPrice ??
                        product.price.currentPrice),
                  0
                )}`,
                '',
              ],
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(dd).open();
  };

  return (
    <Button
      variant='text'
      endIcon={<GetAppIcon />}
      onClick={handleExportData}
    >
      Экспорт
    </Button>
  );
}
