import { isEmpty } from 'lodash';
import { TariffType } from '../../model/types/tariff';

export const validationTariff = async (tariff: TariffType | null) => {
  let errors: Record<string, string> = {};

  if (!tariff) {
    errors['tariff'] = 'Необходимо выбрать тариф';
  }

  if (!isEmpty(errors)) {
    return { errors: errors, isValid: false };
  }

  return { errors: undefined, isValid: true };
};
