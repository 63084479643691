import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetOrderDashboardRequestDataI,
  OrderDashboardSliceI,
} from '../../lib/types';
import { getOrdersStats } from '../services/getOrdersStats';
import { DEFAULT_SUMMARY_DATA } from 'features/OrdersDashboard/lib/const';

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

const initialState: OrderDashboardSliceI = {
  isLoading: false,
  summaryData: DEFAULT_SUMMARY_DATA,
  statsByDay: null,
  dateFrom: thirtyDaysAgo,
  dateTo: today,
};

const OrderDashboardSlice = createSlice({
  name: 'OrderDashboardSlice',
  initialState,
  reducers: {
    setDateFrom: (state, action: PayloadAction<Date>) => {
      state.dateFrom = action.payload;
    },
    setDateTo: (state, action: PayloadAction<Date>) => {
      state.dateTo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getOrdersStats.fulfilled,
        (state, action: PayloadAction<GetOrderDashboardRequestDataI>) => {
          state.isLoading = false;

          state.summaryData = action.payload.summaryData;
          
          state.statsByDay = action.payload.statsByDay;
        }
      )
      .addCase(getOrdersStats.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default OrderDashboardSlice;
export const OrderDashboardSliceActions = OrderDashboardSlice.actions;
