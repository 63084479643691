import { createSlice } from "@reduxjs/toolkit";
import { CurrentOrderType, OrderObjListType } from "./../../types/order";

export type OrderState = {
  ordersList: OrderObjListType;
  currentOrder: CurrentOrderType | null;
  isLoading: boolean;
  errorMessage: string;
};

const initialState: OrderState = {
  ordersList: {},
  currentOrder: null,
  isLoading: false,
  errorMessage: "",
};

const ordersPageSlice = createSlice({
  name: "ordersList",
  initialState,
  reducers: {
    setOrdersList(state, action) {
      state.ordersList = action.payload;
    },
    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },
    updateOrderStatusState: (state, action) => {
      if (state.currentOrder) {
        state.currentOrder.status = action.payload.orderStatus;
        state.ordersList[action.payload.orderId].status =
          action.payload.orderStatus;
      }
    },
    setIsLoadingOrder: (state, action) => {
      state.isLoading = action.payload;
    },
    setOrderError: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetOrderError: (state) => {
      state.errorMessage = "";
    },
    deleteProject: (state, action) => {
      delete state.ordersList[action.payload];
      state.currentOrder = null;
    },
  },
});

export const {
  setOrdersList,
  setCurrentOrder,
  updateOrderStatusState,
  setIsLoadingOrder,
  setOrderError,
  resetOrderError,
  deleteProject
} = ordersPageSlice.actions;

export default ordersPageSlice;
