import { CartActions } from 'entities/Cart';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { RequestStatusCode } from 'consts';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { CustomLinkActions } from '../slice/CustomLinkSlice';

export const getCustomLinkData = createAsyncThunk<
  void,
  { linkId: string; page: number; rowsPerPage: number; search?: string },
  ThunkConfig<string>
>(
  'customLink/fetchCustomLinkParams',
  async ({ linkId, page, rowsPerPage, search }, { dispatch }) => {
    dispatch(CustomLinkActions.setCustomLinkLoadingStatus(true));
    dispatch(CustomLinkActions.setAccess(true));
    api
      .getLink(linkId, page, rowsPerPage, search)
      .then((data) => {
        if (data.privateUrl) {
          dispatch(CustomLinkActions.setAccess(false));
        }

        if (data.privateChannelLink) {
          dispatch(
            CustomLinkActions.setPrivateChannelLink(data.privateChannelLink)
          );
        } else if (data.content) {
          dispatch(
            CustomLinkActions.setCustomLinkParamsData({
              content: data.content,
              name: data.name,
              sellerId: data.userId,
              categories: data.categories,
              isPremiumModulesAvailable: data?.isPremiumModulesAvailable,
              isWhiteLableAvailable: data?.isWhiteLableAvailable,
              isUserVerified: data?.isUserVerified,
              isActive: data?.isActive,
              isPaidTariff: data.isPaidTariff,
            })
          );

          dispatch(CartActions.setSellerId(data.userId));
        }
      })
      .catch((error) => {
        if (error.response.status === RequestStatusCode.NotFound) {
          window.open('/404', '_self'); //TODO: Сделать нормальный редирект
        }
      });

    dispatch(CustomLinkActions.setCustomLinkLoadingStatus(false));
  }
);
