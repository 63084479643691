import { ApiRoutes } from 'consts';
import { AxiosInstance } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  resetAnalyticsErrorMessage,
  setAnalytics,
  setAnalyticsErrorMessage,
  setAnalyticsLoadingStatus,
  setOrdersAnalytics,
} from './AnalyticsSlice';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const fetchAllAnalytics = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>('analytics/fetchAnalytics', async (_args, { dispatch, extra: { api } }) => {
  dispatch(setAnalyticsLoadingStatus(true));
  dispatch(resetAnalyticsErrorMessage());

  try {
    const { data } = await api.get(ApiRoutes.Analytics);

    if (data) {
      dispatch(setAnalytics(data));
    }

    dispatch(setAnalyticsLoadingStatus(false));
  } catch (error) {
    const { message } = apiErrorHandler(error);
    dispatch(setAnalyticsErrorMessage(message));
    dispatch(setAnalyticsLoadingStatus(false));
  }
});

export const fetchOrdersAnalytics = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>('analytics/fetchOrderAnalytics', async (_args, { dispatch,extra: { api } }) => {
  dispatch(setAnalyticsLoadingStatus(true));
  dispatch(resetAnalyticsErrorMessage());

  try {
    const { data } = await api.get(ApiRoutes.AnalyticsOrders);

    if (data) {
      dispatch(setOrdersAnalytics(data));
    }

    dispatch(setAnalyticsLoadingStatus(false));
  } catch (error) {
    const { message } = apiErrorHandler(error);
    dispatch(setAnalyticsErrorMessage(message));
    dispatch(setAnalyticsLoadingStatus(false));
  }
});
