import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCustomerAuthStatus } from 'redux/CustomerSlice/selector';
import { checkAuthCustomerAction } from 'redux/CustomerSlice/asyncActions';
import { UserAuthStatus, UserType } from 'consts';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';

import style from './CustomLinkAuthBtn.module.scss';

const CustomLinkAuthBtn = () => {
  const navigate = useNavigate();
  const authStatus = useAppSelector(getCustomerAuthStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuthCustomerAction());
  }, []);

  const handleSignInBtnClick = () => {
    navigate(`/signin?userType=${UserType.Customer}`);
  };

  const handleProfileBtnClick = () => {
    navigate('/customer/orders');
  };

  if (authStatus === UserAuthStatus.Authorized) {
    return (
      <div className={style.authButton}>
        <Button
          variant='contained'
          startIcon={<PersonIcon />}
          color='info'
          sx={{
            padding: '0 24px',
            color: '#223BDD',
            background: '#fff',
            marginRight: 'auto',
            display: 'flex',
            '&:hover': {
              color: '#fff',
              background: '#223BDD',
            },
          }}
          onClick={handleProfileBtnClick}
        >
          <span className={style.authButtonText}>Профиль</span>
        </Button>
      </div>
    );
  }

  return (
    <div className={style.authButton}>
      <Button
        variant='contained'
        startIcon={<LoginIcon />}
        color='info'
        sx={{
          padding: '0 24px',
          color: '#223BDD',
          background: '#fff',
          marginRight: 'auto',
          display: 'flex',
          '&:hover': {
            color: '#fff',
            background: '#223BDD',
          },
        }}
        onClick={handleSignInBtnClick}
      >
        <span className={style.authButtonText}>Вход</span>
      </Button>
    </div>
  );
};

export default CustomLinkAuthBtn;
