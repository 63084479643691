import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { getCustomLinkSellerId } from 'entities/CustomLink';
import CartButtonIcon from 'components/Cart/CartButtonIcon/CartButtonIcon';
import cn from 'classnames';

const CustomLinkCartPreview = ({ className }: { className?: string }) => {
  const sellerId = useAppSelector(getCustomLinkSellerId) || localStorage.getItem('sellerId');
  const { linkId } = useParams();

  if (!sellerId) {
    console.log('Нет sellerId');
    
    return null;
  }

  return (
    <Box className={cn('custom-link__cart', className)}>
      <Box className='custom-link__cart-wrapper'>
        <CartButtonIcon sellerId={sellerId} linkId={linkId as string}/>
      </Box>
    </Box>
  );
};

export default CustomLinkCartPreview;
