import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getProductDataToDelete } from '../selectors/stockOrderEditSelectors';
import { OrderSliceActions } from '../slice/stockOrderEditSlice';

export const deleteOrderProduct = createAsyncThunk<
  boolean,
  void,
  ThunkConfig<string>
>(
  'stock/deleteOrderProduct',
  async (_, { extra: { api }, rejectWithValue, getState, dispatch }) => {
    try {
      const productToDeleteData = getProductDataToDelete(getState());
      const { data } = await api.delete(ApiRoutes.StockProduct, {
        data: productToDeleteData,
      });

      if (!data) {
        showErrorNotification(['Не удалось удалить продукт из заказу']);
        return rejectWithValue('Не удалось удалить продукт из заказу');
      }

      showSuccessNotification(['Товар удален из заказа']);

      dispatch(OrderSliceActions.setProductDataToDelete({
        productId: '',
        orderId: ''
      }));
      
      dispatch(OrderSliceActions.setIsDeleteProductModalVisible(false));

      window.location.reload();

      return data;
    } catch (error) {
      console.log(error, 'update error');

      const { message } = apiErrorHandler(error);
      showErrorNotification([message]);
      return rejectWithValue('Не удалось удалить продукт из заказу');
    }
  }
);
