import React from "react";
import { Typography, Stack, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import LogoIcon from "components/LogoIcon/LogoIcon";

const DefaultBrand = ({ colorText }: { colorText: string }) => {
  return (
    <Typography sx={{minWidth: "300px", textAlign: "center"}}>
      <Typography
        variant="caption"
        color={colorText}
        component={Link}
        to="https://linkwi.ru/"
      >
        Сделано на LinkWi
      </Typography>
      <Typography component="span" sx={{ ml: "0.5rem", verticalAlign: "text-top", "& svg": {width: "1.2rem", height: "1.2rem"} }}>
        <LogoIcon />
      </Typography>
    </Typography>
  );
};

export default DefaultBrand;
