import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CheckCartAction, getCartProducts, getSummaryPrice } from 'entities/Cart';

type CartButtonIconProps = {
  sellerId: string;
  linkId: string;
};

const CartButtonIcon = ({
  sellerId,
  linkId,
}: CartButtonIconProps) => {
  const dispatch = useAppDispatch();
  const cartProducts = useAppSelector(getCartProducts);
  const totalPrice = useAppSelector(getSummaryPrice);

  useEffect(() => {
    if (sellerId) {
      dispatch(CheckCartAction({ sellerId }));
    }
  }, [sellerId]);

  if (totalPrice === 0) {
    return null;
  }

  const cartButtonStyles = {
    fontSize: '1rem',
    marginBottom: '1rem',
    alignSelf: 'center',
    backgroundColor: '#fff',
    color: '#223bdd',
    borderRadius: '20px',
    height: '42px',
    padding: '8px 16px',
    paddingLeft: '8px',
    boxShadow:
      '0px 8px 6px -6px rgba(34, 59, 221, 0.25), 0px 11px 10px -8px rgba(34, 59, 221, 0.3)',

    '&:hover': {
      backgroundColor: '#f4f5fd',
      color: '#223bdd',
    },
  };

  const cartCountStyle = {
    width: '30px',
    height: '30px',
    flexShrink: '0',
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: '#223bdd',
    textAlign: 'center',
    padding: '4px 5px',
  };

  return (
    <IconButton sx={cartButtonStyles} component={Link} to={`/${linkId}/cart`}>
      <Typography sx={cartCountStyle}>{cartProducts.length}</Typography>
      <Typography variant='subtitle1' px={5}>
        {`${Math.round(Number(totalPrice))} ${getSymbolFromCurrency(cartProducts[0]?.currency ?? "rub")}`}
      </Typography>
      <ShoppingCartCheckoutIcon />
    </IconButton>
  );
};

export default CartButtonIcon;
