import {
  DataGrid,
  GridRowParams,
  GridSelectionModel,
  ruRU,
} from '@mui/x-data-grid';
import { memo, useState } from 'react';
import { TariffOrderType, TariffType } from '../../model/types/tariff';
import { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Box, FormLabel } from '@mui/material';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';
import { tariffColumns } from './tariffColumns';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchTariffSdek } from '../../model/services/fetchOrderTariffs';
import {
  getOrderSdekTariff,
  getTariffError,
} from '../../model/selectors/newOrderSdekSelectors';
import { CreateOrderSdekActions } from '../../model/slice/newSdekOrderSlice';
import { ShowErrorOnPage } from 'shared/ui/ShowErrorOnPage/ShowErrorOnPage';

type TariffsProps = {
  orderTariffData: TariffOrderType;
};

export const Tariffs = memo(({ orderTariffData }: TariffsProps) => {
  const dispatch = useAppDispatch();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [tariffs, setTariffs] = useState<TariffType[] | null>(null);
  const matches = useMediaQuery('(min-width:450px)');
  const [isLoading, setIsLoading] = useState(false);
  const tariff = useAppSelector(getOrderSdekTariff);
  const errors = useAppSelector(getTariffError);

  // TODO: делать запросы в сайд эффектах очень ненадежно, нужно придумать что-то другое
  // 1.Кнопка выбрать тариф + модалка
  // 2.Выбрать тариф и аккордеон
  // 3.???
  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchTariffSdek({ orderTariffData }))
      .then((res) => {
        // Это уже у нас тарифы и их не нужно засовывать в стор , либо завести асинхронные редьюсеры (доп. время)
        setTariffs(res.payload as TariffType[]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [orderTariffData]);

  useEffect(() => {
    if (tariff) {
      setSelectionModel([tariff.tariff_code]);
      return;
    }
    setSelectionModel([]);
  }, [tariff]);

  const handleProductListItemClick = (params: GridRowParams) => {
    if (params.row) {
      dispatch(CreateOrderSdekActions.setTariff(params.row));
      dispatch(CreateOrderSdekActions.setPriceCOD(Number(params.row?.delivery_sum)));
    }
  };

  if (isLoading) {
    return (
      <TailSpin
        color='#2274a5'
        wrapperStyle={{ margin: '300px', justifyContent: 'center' }}
      />
    );
  }

  if (!tariffs) {
    return null;
  }

  if (tariffs && tariffs.length === 0) {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity='error'>
          По данному направлению отправка невозможна
        </Alert>
      </Stack>
    );
  }

  return (
    <Box sx={{ minHeight: '40vh', marginBottom: '30px' }}>
      <FormLabel id='tariff' sx={{ marginBottom: '10px', display: 'block' }}>
        Список тарифов:
      </FormLabel>
      <DataGrid
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        rows={tariffs}
        getRowId={(row) => row.tariff_code}
        columns={tariffColumns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowHeight={matches ? () => undefined : () => 'auto'}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        onRowClick={handleProductListItemClick}
        sx={{
          height: 400,
          getRowHeight: `() => "auto"`,
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:click': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
        }}
      />
      {errors && <ShowErrorOnPage errorMessage={errors?.tariff} />}
    </Box>
  );
});
