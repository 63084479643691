import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { memo, useEffect, useState } from 'react';

import { Modal } from 'shared/ui/Modal/Modal';
import { AddProductModalI } from '../../lib/types';
import style from './AddProductModal.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getStockOrderProductList,
  getIsLoading,
} from '../../model/selectors/stockOrderEditSelectors';
import { getProductList } from '../../model/services/getProductList';
import { Autocomplete, Box, Button, CircularProgress } from '@mui/material';
import { ProductCardType } from 'types/products';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { OrderSliceActions } from '../../model/slice/stockOrderEditSlice';
import ProductsOptionButtons from 'components/Products/ProductsOptionButton/ProductsOptionButton';
import { OptionsOrderType } from 'types/order';
import { getCustomFieldsOrValuesSortedByIndex } from 'utils/CustomFieldsUtils';
import { useParams } from 'react-router-dom';
import { addProductToOrder } from 'features/Orders/model/services/addProductToOrder';
import { showErrorNotification } from 'utils/notification';

const productValidationSchema = yup.object({
  product: yup.object().required('Продукт не выбран'),
  options: yup.object().nullable(),
  finalPrice: yup.number().positive('Цена должна быть положительным числом').required('Цена обязательна'),
  count: yup.number().positive('Количество должно быть положительным числом').integer('Количество должно быть целым числом').required('Количество обязательно'),
});

const AddProductModal = ({ handleModalClose, isOpen }: AddProductModalI) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const { orderId = null } = useParams();
  const [product, setProduct] = useState<ProductCardType | null>(null);
  const [productOptions, setProductOptions] = useState<OptionsOrderType | null>(
    null
  );
  const isLoading = useAppSelector(getIsLoading);
  const productList = useAppSelector(getStockOrderProductList);

  const [price, setPrice] = useState<number | null>(null);
  const [quantity, setQuantity] = useState<number | null>(null);

  useEffect(() => {
    if (open) {
      dispatch(getProductList());
    }
  }, [dispatch, open]);

  const handleProductSearch = debounce((search: string) => {
    dispatch(OrderSliceActions.setSearch(search));
    dispatch(getProductList());
  }, 500);

  const handleProductOptionChange = (value: any) => {
    setProductOptions(value);
  };

  const handleSelectActiveProductChange = (
    event: any,
    newValue: ProductCardType | null
  ) => {
    if (!newValue) {
      setPrice(null);
      setQuantity(null);
      setProductOptions(null);
      return;
    }

    setProduct(newValue);

    setPrice(newValue.price.currentPrice);

    if (isEmpty(newValue.options)) {
      setProductOptions(null);

      return;
    }
    if (newValue && newValue.options) {
      const defaultProductOptions: OptionsOrderType = {};
      Object.values(
        getCustomFieldsOrValuesSortedByIndex(newValue.options)
      ).forEach((field: any) => {
        const valuesArray = getCustomFieldsOrValuesSortedByIndex(field.values);
        if (valuesArray.length > 0) {
          const firstValue = valuesArray[0];
          defaultProductOptions[field.id] = {
            name: field.name,
            value: firstValue.value,
          };
        }
      });

      setProductOptions(defaultProductOptions);
    }
  };

  const handleAddProductClick = async () => {
    try {
      // Пытаемся валидировать данные
      await productValidationSchema.validate({
        product,
        options: productOptions,
        finalPrice: price,
        count: quantity,
      }, { abortEarly: false });

      if (!product || !price || !quantity) {
        return;
      }
  
      // После успешной валидации отправляем запрос на добавление продукта
      dispatch(addProductToOrder({
        productId: product.productId,
        orderId,
        options: productOptions,
        finalPrice: price,
        count: quantity,
      }));
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        // Если ошибка является ошибкой валидации от yup, показываем уведомление с ошибками
        showErrorNotification(error.errors);
      } else {
        // Обработка других типов ошибок
        showErrorNotification(['Произошла ошибка при добавлении продукта']);
      }
    }
  };

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <div className={style.wrapper}>
        <p className={style.header}>Редактирование товара в заказе</p>
        <Autocomplete
          id='asynchronous-demo'
          sx={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option: any, value: any) =>
            option.name === value.name
          }
          onChange={handleSelectActiveProductChange}
          getOptionLabel={(option: ProductCardType) => option.name}
          options={productList || [{ name: 'Loading...', id: 0 }]}
          loading={isLoading}
          renderInput={(params: any) => (
            <BaseInput
              {...params}
              label='Товары'
              placeholder='Введите название товара'
              onChange={(evt) => handleProductSearch(evt.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option: ProductCardType) => (
            <Box
              component='li'
              {...props}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {option.s3ImgList && option.s3ImgList[0] && (
                <img
                  src={option.s3ImgList[0].imgLink}
                  alt=''
                  style={{ width: 50, height: 50, marginRight: 10 }}
                />
              )}
              {option.name}
            </Box>
          )}
        />
        {product && !isEmpty(product?.options) && (
          <Box>
            <ProductsOptionButtons
              options={product?.options}
              selectedProductOptions={productOptions}
              setOptionsProduct={handleProductOptionChange}
            />
          </Box>
        )}
        <BaseInput
          value={quantity}
          onChange={(evt) => setQuantity(Number(evt.target.value))}
          label='Кол-во'
        />
        <BaseInput
          value={price}
          onChange={(evt) => setPrice(Number(evt.target.value))}
          label='Цена продажи'
        />
        <Button
          sx={{
            mt: '10px',
          }}
          fullWidth
          variant='contained'
          onClick={handleAddProductClick}
        >
          Сохранить
        </Button>
      </div>
    </Modal>
  );
};

export default memo(AddProductModal);
