import { memo } from "react";

const GridIcon = ({ style }: { style: any }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      viewBox='0 0 26 26'
      style={style}
    >
      <rect width='11.3837' height='11.3837' rx='4.125' fill={style.fill} />
      <rect
        x='14.2295'
        width='11.3837'
        height='11.3837'
        rx='4.125'
        fill={style.fill}
      />
      <rect
        y='14.2296'
        width='11.3837'
        height='11.3837'
        rx='4.125'
        fill={style.fill}
      />
      <rect
        x='14.2295'
        y='14.2296'
        width='11.3837'
        height='11.3837'
        rx='4.125'
        fill={style.fill}
      />
    </svg>
  );
};

export default memo(GridIcon);
