import { SdekAvailableStatus } from 'consts';
import { Navigate, Outlet } from 'react-router-dom';
import { getUserIsSdekvailable } from 'redux/UserSlice/selector';
import { useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';
import { socketSdek } from 'shared/config/sockets/initial';

export const LogisticsLayout = () => {
  const isSdekAvailable = useAppSelector(getUserIsSdekvailable);

  useEffect(() => {
    socketSdek.connect();
  }, []);

  if (isSdekAvailable === SdekAvailableStatus.NotAvailable) {
    return <Navigate to='/dashboard/logistics/onboarding' />;
  }

  return <Outlet />;
};
