import { useState, useEffect, CSSProperties } from "react";
import { useAppDispatch } from "redux/hooks";
import Iconify from "components/common/Iconify/Iconify";
import { Button, Stack } from "@mui/material";
import { CartActions } from "entities/Cart";

export const CounterButtonStyle = {
  width: "42px",
  height: "42px",
  minWidth: "unset",
  p: "10px 13px",
  boxShadow: "none",
};

const countStyle: CSSProperties = {
  width: '100%',
  fontWeight: '500',
  border: '0',
  textAlign: 'center',
  backgroundColor: 'transparent',
  outline: 'none' // Применяется по умолчанию, но фокус нужно обработать отдельно
};

const countFocusStyle: CSSProperties = {
  color: '#223bdd',
};

type ProductCountControllerProps = {
  startCount: number;
  cartProductId: string;
  minValue: number;
  multiplicityCount?: number;
};
const ProductCounterDeprecated = ({
  startCount,
  cartProductId,
  minValue,
  multiplicityCount = 1
}: ProductCountControllerProps) => {
  const dispatch = useAppDispatch();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const [count, setCount] = useState<number | "">(startCount);

  useEffect(() => {
    setCount(startCount);
  }, [startCount]);

  const handleIncCountProduct = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    dispatch(CartActions.incProductInCart({cartProductId, multiplicityCount}));
    setCount((prev) => Number(prev) + multiplicityCount);
  };

  const handleDecCountProduct = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setCount((prev) => Number(prev) - multiplicityCount);
    if (count === "") {
      return
    }
    if (count > multiplicityCount) {
      dispatch(CartActions.decProductInCart({cartProductId, multiplicityCount}));
      return;
    }
    dispatch(CartActions.deleteProductFromCart(cartProductId));
  };

  const handleCountInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      dispatch(
        CartActions.setProductCountInCart({
          productId: cartProductId,
          count: minValue,
        })
      );
      setCount("");
      return;
    }
    const value = Math.abs(Math.round(Number(event.target.value)));
    setCount(value);
    dispatch(CartActions.setProductCountInCart({ productId: cartProductId, count: value }));
  };

  const onBlurEmpty = () => {
    setIsFocused(false);
    if (count === "") {
      setCount(minValue);
      if (minValue === 0) {
        dispatch(CartActions.deleteProductFromCart(cartProductId));
      }
      return;
    }

    const roundedCount = Number(count) % multiplicityCount !== 0 ? Math.floor(Number(count) / multiplicityCount) * multiplicityCount : Math.round(Number(count));
    dispatch(CartActions.setProductCountInCart({ productId: cartProductId, count: roundedCount }));
    setCount(roundedCount);
  };

  return (
    <Stack
      flexDirection="row"
      className="cart-main__change-count"
      sx={{
        backgroundColor: "#F4F5FD",
        width: "100%",
        borderRadius: "20px",
      }}
    >
      <Button
        variant="contained"
        onClick={handleDecCountProduct}
        disabled={count === minValue}
        sx={{ ...CounterButtonStyle }}
      >
        <Iconify icon="ant-design:minus-outlined" />
      </Button>
      <input
        className="cardContent__count"
        style={{ ...countStyle, ...(isFocused ? countFocusStyle : {}) }}
        id={"counter"}
        type={"number"}
        value={count}
        onChange={handleCountInput}
        onBlur={onBlurEmpty}
        onFocus={handleFocus}
        onClick={(event) => event.stopPropagation()}
      />
      <Button
        variant="contained"
        onClick={handleIncCountProduct}
        sx={{ ...CounterButtonStyle }}
      >
        <Iconify icon="ant-design:plus-outlined" />
      </Button>
    </Stack>
  );
};

export default ProductCounterDeprecated;
