import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

import DashboardLayout from 'app/layouts/dashboard/DashboardLayout';
import LogisticsRoutes from './LogisticsRoutes';
import { ClientsPage } from 'pages/ClientsPage';
import { OnboardingPage } from 'entities/Onboarding';
import { AccountingPage } from 'entities/Stock';
import { StockOrderPage } from 'features/Orders';


const CreateLinkPage = lazy(() => import('pages/CreateLinkPage'));
const CreateRedirectPage = lazy(() => import('pages/CreateRedirectPage'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const DetailProjectPage = lazy(
  () => import('pages/DetailProjectPage/DetailProjectPage')
);
const EditLinkPage = lazy(() => import('pages/EditLinkPage'));
const OrdersPage = lazy(() => import('pages/OrdersPage/OrdersPage'));
const ProjectsPage = lazy(() => import('pages/ProjectsPage/ProjectsPage'));
const SellerProductsPage = lazy(
  () => import('pages/SellerProductsPage/SellerProductsPage')
);
const SettingsPage = lazy(() => import('pages/SettingsPage/SettingsPage'));
const EducationPage = lazy(() => import('pages/EducationPage/EducationPage'));
const MarketingPage = lazy(() => import('pages/MarketingPage/MarketingPage'));
const TariffsPage = lazy(() => import('pages/TariffsPage/TariffsPage'));

const LogisticsPage = lazy(
  () => import('pages/Logistics/LogisticsPage/LogisticsPage')
);
const PaymentPage = lazy(() => import('pages/PaymentPage/PaymentPage'));
const ReviewsPage = lazy(() => import('pages/ReviewsPage/ReviewsPage'));

const SellerRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<DashboardLayout />}>
        <Route path='/:linkId/edit' element={<EditLinkPage />} />
        {/* <Route path="/dashboard/products/:sellerId" element={<ClientProductsPage />} /> */}
        <Route path='/products' element={<SellerProductsPage />} />
        <Route path='/orders' element={<AccountingPage />} />
        <Route path='/orders/order' element={<StockOrderPage />} />
        <Route path='/orders/order/:orderId' element={<StockOrderPage />} />
        
        <Route path='/links' element={<CreateLinkPage />} />
        <Route path='/main' element={<Dashboard />} />
        <Route path='/projects' element={<ProjectsPage />} />
        <Route path='/projects/:projectId' element={<DetailProjectPage />} />
        <Route path='/link' element={<CreateLinkPage />} />
        <Route path='/redirect' element={<CreateRedirectPage />} />
        <Route path='/products/:productId' element={<Dashboard />} />
        <Route path='/settings/' element={<SettingsPage />} />
        <Route path='/onboarding' element={<OnboardingPage />} />
        <Route path='/payments' element={<TariffsPage />} />
        <Route path='/marketing' element={<MarketingPage />} />
        <Route path='/payform' element={<PaymentPage />} />
        <Route path='/logistics' element={<LogisticsPage />} />
        <Route path='/reviews' element={<ReviewsPage />} />
        <Route path='/clients' element={<ClientsPage/>} />
        <Route path='/logistics/*' element={<LogisticsRoutes />} />
      </Route>
    </Routes>
  );
};

export default SellerRoutes;
