import style from './OnboardingTaskList.module.scss';
import ReusableAccordion from '../AccordionItem/AccordionItem';
import { useAppSelector } from 'redux/hooks';
import { getOnboardingData } from 'entities/Onboarding/model/selectors/onboardingSelectors';
import TaskItem from '../TaskItem/TaskItem';

const OnboardingTaskList = () => {
  const onboardingStatusList = useAppSelector(getOnboardingData);

  if (!onboardingStatusList) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <ReusableAccordion
        title='Как создать товар'
        defaultExpanded
        isCompleted={onboardingStatusList?.isFirstProductCreated}
      >
        <p className={style.taskText}>
          LinkWi - сервис, в котором вы можете создать каталог ваших товаров,
          удобно делиться им с клиентами и принимать заказы. У каждого товара
          есть свое название, галерея фото, описание, цена, возможность добавить
          кастомные поля и несколько цен в зависимости от кол-ва товара в
          корзине.
        </p>
        <div className={style.videoWrapper}>
          <iframe
            src='https://www.youtube.com/embed/AKUencAe8eY?si=pNIrVDZIHKsZGPAI'
            title='Создание товара в сервисе LinkWI'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
        <TaskItem
          title='Создай первый товар'
          text='Посмотрите нашу видеоинструкцию, создайте первый товар и мы начислим бонус к тестовому периоду + 2 дня на тарифе "Оптовый"'
          isTaskCompleted={onboardingStatusList?.isFirstProductCreated}
        />
      </ReusableAccordion>
      <ReusableAccordion
        title='Как создать страницу'
        defaultExpanded
        isCompleted={onboardingStatusList?.isFirstPageCreated}
      >
        <p className={style.taskText}>
          Страница - это ваш каталог товаров. Изначально товары не прикреплены
          ни к одной странице, чтобы можно было гибко настраивать ваши каталоги.
          Особо актуально это для тех, у кого широкий ассортимент. Страницу
          можно кастомизировать, установить баннер, лого, фирменный цвета вашего
          бренда, чтобы придать ей уникальность.
        </p>
        <div className={style.videoWrapper}>
          <iframe
            src='https://www.youtube.com/embed/MxHiIS1WMtw?si=JiJ9LE76BC4SPlHf'
            title='Создание страницы в сервисе LinkWI'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
        <TaskItem
          title='Создайте первую страницу'
          text='Посмотри нашу видеоинструкцию, создай первую страницу и добавь на нее первый товар. После чего мы начислим бонус к тестовому периоду + 2 дня на тарифе "Оптовый"'
          isTaskCompleted={onboardingStatusList?.isFirstPageCreated}
        />
      </ReusableAccordion>
      <ReusableAccordion
        title='Как подключить уведомления в телеграм'
        defaultExpanded
        isCompleted={onboardingStatusList?.isTelegramNotification}
      >
        <p className={style.taskText}>
          Чтобы быстро обрабатывать заказы и реагировать на события системы
          (новые отзывы, напоминания о конце платного периода) рекомендуем
          подключить уведомления в телеграм
        </p>
        <TaskItem
          title='Подключите уведомления в телеграм'
          text='Перейдите в раздел "Настроки", далее "Телеграм уведомления". Скопируйте код и пришлите его боту, ссылка на бота будет под полем с кодом.'
          isTaskCompleted={onboardingStatusList?.isTelegramNotification}
        />
      </ReusableAccordion>
      <ReusableAccordion
        title='Как настроить интеграцию с телеграм'
        defaultExpanded
        isCompleted={onboardingStatusList?.isTelegramBotConnected}
      >
        <p className={style.taskText}>
          Наша интеграция с телеграм позволяет собирать подписчиков в ваш
          закрытый канал. Когда ваши клиенты попадают в каталог, они видят
          ссылку на ваш телеграм, после подписки на него, бот высылает им новую
          ссылку (в которую подставле код-пароль) и ссылка открывается. Код
          запомнится у пользователя в браузере, клиент получит доступ к
          каталогу, а вы нового подписчика.
        </p>
        <div className={style.videoWrapper}>
          <iframe
            src='https://www.youtube.com/embed/BGIjTq3A_qU'
            title='Как настроить интеграцию с телеграм'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
        <TaskItem
          title='Подключите интеграцию с телеграм'
          text='Посмотри нашу видеоинструкцию, подключите интеграцию с телеграм (частично или полностью - не важно). После чего мы начислим бонус к тестовому периоду + 2 дня на тарифе "Оптовый"'
          isTaskCompleted={onboardingStatusList?.isTelegramBotConnected}
        />
      </ReusableAccordion>
    </div>
  );
};

export default OnboardingTaskList;
