import { enqueueSnackbar } from 'notistack';

export const showErrorNotification = (messages: string[]) => {
  messages.forEach((msg) => {
    enqueueSnackbar(msg, {
      variant: 'error',
      preventDuplicate: true
    });
  });
};

export const showSuccessNotification = (messages: string[]) => {
  messages.forEach((msg) => {
    enqueueSnackbar(msg, {
      variant: 'success',
      preventDuplicate: true
    });
  });
};
