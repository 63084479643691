import {ComponentType} from "react";
import { useAppSelector } from "redux/hooks";
import { getUserRole } from "redux/UserSlice/selector";
import { UserRole } from "consts";

export function WithAdminAccess<T>(
  Component: ComponentType<T>,
  isReturn: boolean
) {
  const RedirectComponent = (props: any) => {
    const role = useAppSelector(getUserRole)
    if (role !== UserRole.Admin) {
      return isReturn ? (
        <span className="admin-access-text">
          Доступ только для пользователей admin
        </span>
      ) : null;
    }
    return <Component {...props} />;
  };

//   return connect(mapStateToProps)(RedirectComponent);
  return RedirectComponent;
};
