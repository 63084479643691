import { Select, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getCurrentOrderData } from '../../model/selectors/stockOrderEditSelectors';
import { updateOrderStatus } from '../../model/services/updateOrderStatus';
import { OrderStatus } from 'entities/Orders/model/lib/consts';

import style from './OrderStatus.module.scss';

const StatusSelect = () => {
  const { status, orderId } = useAppSelector(getCurrentOrderData);
  const dispatch = useAppDispatch();

  const handleChange = (event: any) => {
    const newStatus = event.target.value;

    if (!orderId) {
      return;
    }

    dispatch(updateOrderStatus({
      orderStatus: newStatus,
      orderId,
    }));
  };

  // Добавление дефолтного значения для статуса, если он не установлен
  const displayStatus = status || '';

  return (
    <Select
      labelId={`status-select-label-${orderId}`}
      id={`status-select-${orderId}`}
      value={displayStatus}
      onChange={handleChange}
      displayEmpty
      className={style.wrapper}
      variant='standard'
      sx={{
        fontSize: '22px'
      }}
    >
      <MenuItem disabled value="">
        Выберите статус
      </MenuItem>
      {Object.entries(OrderStatus).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StatusSelect;
