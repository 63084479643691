import Typography from '@mui/material/Typography/Typography';
import React, { useEffect, useState, memo, useCallback } from 'react';
import { MapWidget } from 'shared/ui/MapWidget/index';
import { useAppDispatch } from 'redux/hooks';
import AutocompleteInput from 'components/ui/AutocompleteInput/AutocompleteInput';
import { useMap } from 'react-map-gl';
import { fetchOfficeListSdek } from '../../model/services/getOfficeListBySdekCityCode';
import { DeliveryPointI, PointType } from '../../model/types/location';
import { showErrorNotification } from 'utils/notification';
import { Spinner } from 'shared/ui/Spinner/Spinner';
import Button from '@mui/material/Button';
import cls from './FindDeliveryPoint.module.scss';
import { ShowDimensionsDeliveryPoint } from '../ShowDimensionsDeliveryPoint/ShowDimensionsDeliveryPoint';

interface FindDeliveryPointProps {
  onCloseModal?: () => void;
  setPoint: (point: DeliveryPointI) => void;
  deliveryPointCode?: string;
  latitude: number;
  longitude: number;
  fiasCode: string;
  deliveryPointAddress?: string;
}

//TODO: рефаткоринга + нормальная типизация
export const FindDeliveryPoint = memo(
  ({
    onCloseModal,
    setPoint,
    deliveryPointCode,
    latitude,
    longitude,
    fiasCode,
    deliveryPointAddress,
  }: FindDeliveryPointProps) => {
    const [officeList, setOfficeList] = useState<DeliveryPointI[] | null>(null);
    const dispatch = useAppDispatch();
    const { mapSdek } = useMap();
    const autocompleteOptions =
      officeList?.map((el) => el.location.address) || [];

    const handleChangeAutocomplete = useCallback(
      (_: React.SyntheticEvent, value: string) => {
        const point = officeList?.find((el) => el.location.address === value);

        if (!point) {
          showErrorNotification(['Не удалось найти точку доставки']);
          return;
        }

        const { latitude, longitude } = point.location;

        mapSdek?.flyTo({ center: [Number(longitude), Number(latitude)] });
        setPoint(point);
      },
      [officeList]
    );

    const handleMarkerClick = useCallback((pointData: any) => {
      setPoint(pointData);
    }, []);

    useEffect(() => {
      dispatch(fetchOfficeListSdek({ toLocationFiasCode: fiasCode })).then(
        (resuly) => {
          setOfficeList(resuly.payload as DeliveryPointI[] | null);
        }
      );
    }, []);

    if (!officeList) {
      return <Spinner />;
    }

    return (
      <div>
        <Typography variant='h5' component='h5' sx={{ textAlign: 'center' }}>
          Пункт выдачи заказа:
        </Typography>

        <ShowDimensionsDeliveryPoint />

        <div className={cls.citySearchWrapper}>
          <AutocompleteInput
            className={cls.autocomplete}
            value={deliveryPointAddress || ''}
            options={autocompleteOptions}
            onChange={handleChangeAutocomplete}
          />
          <Button
            variant='contained'
            className={cls.acceptButton}
            onClick={onCloseModal}
          >
            Подтвердить
          </Button>
        </div>

        <MapWidget
          deliveryPointCode={deliveryPointCode}
          latitude={latitude}
          longitude={longitude}
          handleMarkerClick={handleMarkerClick}
          officeListLocation={officeList || []}
        />
      </div>
    );
  }
);
