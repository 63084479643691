import { log } from 'console';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AddProductToOrderDataI } from 'features/Orders/lib/types';

export const addProductToOrder = createAsyncThunk<
  string,
  AddProductToOrderDataI,
  ThunkConfig<string>
>(
  'stock/addProductToOrder',
  async (addProductToOrderData, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.post(
        ApiRoutes.StockProduct,
        addProductToOrderData
      );

      if (!data) {
        showErrorNotification(['Не удалось добавить продукт к заказу']);
        return rejectWithValue('Не удалось добавить продукт к заказу');
      }

      showSuccessNotification(['Элемент успешно обновлен']);
      
      if (!addProductToOrderData.orderId) {
        window.open(`/dashboard/orders/order/${data}`)
      } else {
        window.location.reload();
      }

      return data;
    } catch (error) {
      console.log(error, 'update error');

      const { message } = apiErrorHandler(error);
      showErrorNotification([message]);
      return rejectWithValue('Не удалось добавить продукт к заказу');
    }
  }
);
