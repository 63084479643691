import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router";
import { getCustomerAuthStatus } from "redux/CustomerSlice/selector";
import { useAppSelector } from "redux/hooks";
import { UserAuthStatus, UserType } from "consts";

export interface CartSuccessOrderDialogProps {
  open: boolean;
  linkId: string | undefined;
  onClose: () => void;
}

const CustomButton = styled(Button)({
  textTransform: "none",
  flex: 1,
  minWidth: "180px",
  margin: "0 0.5rem 1rem",
});

export default function CartSuccessOrderDialog(props: CartSuccessOrderDialogProps) {
  const { open, linkId, onClose } = props;
  const navigate = useNavigate();
  const authStatus = useAppSelector(getCustomerAuthStatus);
  const isAuth = authStatus === UserAuthStatus.Authorized
  const dialogMessage = `Спасибо! Ваш заказ успешно оформлен. ${!isAuth ? 'Чтобы отслеживать статус заказа вы можете зарегистрироваться или просто вернуться на страницу магазина': ''}`

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>{dialogMessage}</DialogTitle>
        <Box sx={{display: "flex", flexWrap: "wrap"}}>
          <CustomButton
            onClick={() => {
              onClose()
               navigate(`/${linkId}`)
            }}
            variant="outlined"
          >
            Вернуться к товарам
          </CustomButton>
          {
            !isAuth &&
              <CustomButton
                autoFocus
                onClick={() => {
                  onClose()
                   navigate(`/signup?userType=${UserType.Customer}`)
                }}
                variant="contained"
              >
                Зарегистрироваться
              </CustomButton>
          }
        </Box>
      </Dialog>
    </Box>
  );
}
