import { LinksBlock } from 'consts/links';
import {
  BannerBlockType,
  BrandBlockType,
  ButtonsBlockType,
  GeneralBlockType,
  ServiceBlockType,
  SocialLinksBlockType,
  SortType,
  ZeroBlockType,
} from 'types/links';
import { ProductCardType } from 'types/products';

export enum PriceFilterEnum {
  DEFAULT,
  ASC,
  DESC,
}

export type CustomLinkSliceType = {
  products: ProductCardType[] | null;
  productCount: number;
  categories: string[];
  isLoading: boolean;
  name: string;
  sellerId: string;
  content: CustomLinkContentType | null;
  access: boolean;
  isWhiteLableAvailable: boolean;
  isPremiumModulesAvailable: boolean;
  isUserVerified: boolean;
  isActive: boolean;
  isPaidTariff: boolean;
  password?: string;
  privateChannelLink?: string;
  productViewType: ProductViewTypeEnum;
};

export enum ProductViewTypeEnum {
  GRID,
  LIST,
}

export type CustomLinkContentType = {
  [LinksBlock.GENERAL]: { content: GeneralBlockType; order: number };
  [LinksBlock.BUTTONS]: {
    [key: string]: {
      content: ButtonsBlockType;
      order: number;
    };
  };
  [LinksBlock.BRAND]: { content: BrandBlockType; order: number };
  [LinksBlock.SOCIAL_LINKS]: { content: SocialLinksBlockType; order: number };
  [LinksBlock.BANNER]: { content: BannerBlockType; order: number };
  [LinksBlock.SERVICE]: { content: ServiceBlockType };
  [LinksBlock.ZERO]: { content: ZeroBlockType };
};

export interface CustomLinkGetProductsParamsI {
  linkId: string;
  page: number;
  rowsPerPage: number;
  searchValue: string | null;
  categoryFilter: string[];
  sortBy?: string;
  sortType?: string;
}

export type SortFieldType = {
  value: string;
  label: string;
};

export const sortPriceValues: SortFieldType[] = [
  { value: SortType.ASC, label: 'По возрастанию' },
  { value: SortType.DESC, label: 'По убыванию' },
  { value: 'default', label: 'Без сортировки' },
];

export const DEFAULT_PAGE_ITEM_COUNT = 40;