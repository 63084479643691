import { Skeleton } from '@mui/material';
import { AppWebsiteVisits } from 'components/widgets';
import {
  getIsOrdersDashboardLoading,
  getStatsByDayForOrderDashboard,
} from 'features/OrdersDashboard/model/selectors/orderDashboardSelectors';
import { transformDataForChart } from 'features/OrdersDashboard/model/services/transformDataForChart';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';

const MainChart = () => {
  const isLoading = useAppSelector(getIsOrdersDashboardLoading);
  const statsByDate = useAppSelector(getStatsByDayForOrderDashboard);

  const { CHART_LABELS, CHART_DATA } = useMemo(
    () => transformDataForChart(statsByDate),
    [statsByDate]
  );

  if (isLoading) {
    return <Skeleton width={'100%'} height={'300px'} />;
  }  

  return (
    <AppWebsiteVisits
      title={'Динамика продаж'}
      subheader={''}
      chartLabels={CHART_LABELS}
      chartData={CHART_DATA}
    />
  );
};

export default MainChart;
