// component

import SvgColor from 'components/svg-color/SvgColor';

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Дашборд',
    path: '/dashboard/main',
    icon: icon('ic_analytics'),
    isAdminAccess: false,
  },
  {
    title: 'Проекты',
    path: '/dashboard/projects',
    icon: icon('ic_user'),
    isAdminAccess: false,
  },
  {
    title: 'Ссылки',
    path: '/admin/links',
    icon: icon('ic_orders'),
    isAdminAccess: true,
  },
  {
    title: 'Товары',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
    isAdminAccess: false,
  },
  {
    title: 'Заказы',
    path: '/dashboard/orders',
    icon: icon('ic_order'),
    isAdminAccess: false,
  },
  {
    title: 'Отзывы',
    path: '/dashboard/reviews',
    icon: icon('ic_reviews'),
    isAdminAccess: false,
  },
  {
    title: 'Пользователи',
    path: '/admin/users',
    icon: icon('ic_users'),
    isAdminAccess: true,
  },
  {
    title: 'Клиенты',
    path: '/dashboard/clients',
    icon: icon('ic_users'),
  },
  {
    title: 'Настройки',
    path: '/dashboard/settings',
    icon: icon('ic_setting'),
    isAdminAccess: false,
  },
  {
    title: 'Обучение',
    path: '/dashboard/onboarding',
    icon: icon('ic_help'),
    isAdminAccess: false,
  },
  {
    title: 'Тарифы',
    path: '/dashboard/payments',
    icon: icon('ic_orders'),
    isAdminAccess: false,
  },
  {
    title: 'Логистика',
    path: '/dashboard/logistics/orders',
    icon: icon('ic_tour'),
    isAdminAccess: false,
  },
];

export default navConfig;
