import { AnySchema, ArraySchema, ObjectSchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import { AssertsShape } from 'yup/lib/object';
import { AnyObject, Maybe } from 'yup/lib/types';

type ValidationSchemaType = ObjectSchema<
  AnyObject,
  AnyObject,
  any,
  Maybe<AssertsShape<AnyObject>>
>;

export const getValidationError = async <T>(
  schema: ValidationSchemaType,
  data: T,
  params?: AnyObject,
) => {
  const errorMessages: Record<string, string> = {};
  await schema.validate(data, { abortEarly: false, context: {...params} }).catch((err: any) => {
    err.inner.forEach((e: any) => {
      errorMessages[e.path] = e.message;
    });
  });

  return errorMessages;
};

type ValidationArraySchemaType = ArraySchema<
  Lazy<any, any> | AnySchema,
  AnyObject,
  any,
  Maybe<any[]>
>;

export type ArrayErrorMessages = Record<string, Record<string, string>>;

export const getValidationArrayError = async <T>(
  schema: ValidationArraySchemaType,
  data: T
) => {
  const errorMessages: ArrayErrorMessages = {};

  await schema.validate(data, { abortEarly: false }).catch((err: any) => {
    err.inner.forEach((e: any) => {
      const [indexField, field] = e.path.split('.');
      const index = indexField.match(/\[(.*)\]/)[1];

      if (!errorMessages[index]) {
        errorMessages[index] = {};
      }

      errorMessages[index][field] = e.message;
    });
  });

  return errorMessages;
};
