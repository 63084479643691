import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DimensionsType,
  PackageSettingType,
  PackagesSettingType,
} from '../types/packages';
import { ErrorOrderI, OrderSdekSchema } from '../types/orderSdek';
import { TariffType } from '../types/tariff';
import { createOrderSdek } from '../services/createOrderSdek';
import { getDefaultState } from '../../lib/helpers/order';

const initialState: OrderSdekSchema = getDefaultState();

export const CreateOrderSdekSlice = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    setTariff: (state, action: PayloadAction<TariffType | null>) => {
      state.tariff = action.payload;
      state.errors = undefined;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
      state.errors = undefined;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.errors = undefined;
    },
    setLocationTo: (state, action) => {
      state.locationTo = {
        ...state.locationTo,
        ...action.payload,
      };
      state.errors = undefined;
    },

    setPoint: (state, action) => {
      state.point = action.payload;
      state.errors = undefined;
    },

    setLocationFrom: (state, action) => {
      state.locationFrom = {
        ...state.locationFrom,
        ...action.payload,
      };
      state.errors = undefined;
    },

    setOfficeListLocationFrom: (state, action) => {
      state.officeListLocationFrom = action.payload;
    },

    setOfficeListLocationTo: (state, action) => {
      state.officeListLocationTo = action.payload;
    },

    setPackages: (state, action: PayloadAction<PackagesSettingType>) => {
      state.packages = action.payload;
      state.errors = undefined;
    },

    updatePackageField: (
      state,
      action: PayloadAction<{
        packageId: string;
        fieldName: keyof PackageSettingType;
        value: any;
      }>
    ) => {
      const { packageId, fieldName, value } = action.payload;

      if (state.packages[packageId]) {
        state.packages[packageId][fieldName] = value;
        state.errors = undefined;
      }
    },
    setPriceCOD: (state, action) => {
      state.priceCOD = action.payload;
      state.errors = undefined;
    },
    setErrors: (state, action: PayloadAction<ErrorOrderI>) => {
      state.errors = action.payload;
    },

    setDimensionsPackages: (state, action: PayloadAction<DimensionsType>) => {
      state.dimensions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrderSdek.pending, (state) => {
        state.errors = undefined;
        state.isLoadingOrder = true;
      })
      .addCase(createOrderSdek.fulfilled, (state) => {
        state.isLoadingOrder = false;

        state = getDefaultState();
      })
      .addCase(createOrderSdek.rejected, (state) => {
        state.isLoadingOrder = false;
      });
  },
});

export const { actions: CreateOrderSdekActions } = CreateOrderSdekSlice;
export const { reducer: CreateOrderSdekReducer } = CreateOrderSdekSlice;
