import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BaseSyntheticEvent, useState } from "react";
import { Stack } from "@mui/system";
import { Rating } from "react-simple-star-rating";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ShoweRequestErrorAlert from "components/ShoweRequestErrorAlert/ShoweRequestErrorAlert";
import { getCurrentOrder } from "redux/OrdersSlice/selector";
import { getReviewErrorMessage, getReviewLoadingStatus, getReviewProductId } from "entities/Reviews";
import { postReview } from "../model/services/postReview";
import BaseInput from "components/ui/BaseInput/BaseInput";
import BaseImageUploader from "components/ui/BaseImageUploader/BaseImageUploader";

export function PostReviewForm() {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [images, setImages] = useState<File[]>([]); // Измените тип состояния на File[]
  const dispatch = useAppDispatch();
  const error = useAppSelector(getReviewErrorMessage);
  const isLoading = useAppSelector(getReviewLoadingStatus);
  const productId = useAppSelector(getReviewProductId);
  const order = useAppSelector(getCurrentOrder);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const onChangeReview = (evt: BaseSyntheticEvent) => setReviewText(evt.currentTarget.value)

  const handleSubmit = () => {
    dispatch(postReview({
      reviewText,
      rating,
      productId,
      orderId: order?.orderId || '',
      images,
    }));
  };

  return (
    <Box component={"form"} className="review-form">
      <Stack spacing={2}>
        <Typography component="h2" variant="h4">
          Оставить отзыв
        </Typography>

        <Rating
          tooltipDefaultText="Ваша оценка"
          tooltipArray={["ужасно", "плохо", "так себе", "хорошо", "отлично"]}
          size={22}
          transition={true}
          showTooltip={true}
          onClick={handleRating}
          tooltipStyle={{
            backgroundColor: "white",
            color: "inherit",
            fontSize: "14px",
            marginLeft: 0,
            paddingTop: 0,
          }}
        />
        <BaseInput
          id="revew"
          value={reviewText}
          onChange={onChangeReview}
          multiline
          rows={4}
        />
        <BaseImageUploader images={images} setImages={setImages} />

        <LoadingButton
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
        >
          Сохранить
        </LoadingButton>

        {error && <ShoweRequestErrorAlert error={error} />}
      </Stack>
    </Box>
  );
}
