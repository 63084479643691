import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRoutes } from "consts";
import { apiErrorHandler } from "services/apiErrorHandler";
import {
  resetOrderError,
  setCurrentOrder,
  setIsLoadingOrder,
  setOrderError,
  setOrdersList,
  updateOrderStatusState,
  deleteProject
} from "./OrdersPageSlice";
import { RequestStatusCode } from "consts";
import { ThunkConfig } from "app/providers/StoreProvider";

export const fetchOrdersAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("orders/fetchOrders", async (_args, { dispatch,extra: { api } }) => {
  dispatch(setIsLoadingOrder(true));
  dispatch(resetOrderError());
  try {
    const { data } = await api.get(ApiRoutes.Orders);

    if (data) {
      dispatch(setOrdersList(data));
      dispatch(setIsLoadingOrder(false));
    }
  } catch (error) {
    const { message, status } = apiErrorHandler(error);
    dispatch(setOrderError(`${status} ${message}`));
    dispatch(setIsLoadingOrder(false));
    setTimeout(() => {
      dispatch(resetOrderError());
    }, 3000);
  }
});

export const fetchCurrentOrderAction = createAsyncThunk<
  void,
  { orderId: string },
  ThunkConfig<string>
>("order/fetchCurrentOrder", async ({ orderId }, { dispatch,extra: { api } }) => {
  dispatch(setIsLoadingOrder(true));
  dispatch(resetOrderError());
  try {
    const { data } = await api.get(`${ApiRoutes.Orders}/${orderId}`, {
      params: { orderId },
    });

    if (data) {
      dispatch(setCurrentOrder(data));
      dispatch(setIsLoadingOrder(false));
    }
  } catch (error) {
    const { message, status } = apiErrorHandler(error);
    dispatch(setOrderError(`${status} ${message}`));
    dispatch(setIsLoadingOrder(false));
    setTimeout(() => {
      dispatch(resetOrderError());
    }, 3000);
  }
});

export const updateOrderStatus = createAsyncThunk<
  void,
  { orderStatus: string; orderId: string },
  ThunkConfig<string>
>(
  "order/updateOrderStatus",
  async ({ orderStatus, orderId }, { dispatch,extra: { api } }) => {
    dispatch(setIsLoadingOrder(true));
    dispatch(resetOrderError());
    try {
      const { data } = await api.patch(ApiRoutes.Orders, {
        orderStatus,
        orderId,
      });
      if (data) {
        dispatch(updateOrderStatusState({ orderStatus, orderId }));
        dispatch(setIsLoadingOrder(false));
      }
    } catch (error) {
      const { message, status } = apiErrorHandler(error);
      dispatch(setOrderError(`${status} ${message}`));
      dispatch(setIsLoadingOrder(false));
      setTimeout(() => {
        dispatch(resetOrderError());
      }, 3000);
    }
  }
);

export const fetchCustomerOrdersAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("orders/fetchOrders", async (_args, { dispatch,extra: { api } }) => {
  dispatch(setIsLoadingOrder(true));
  dispatch(resetOrderError());
  try {
    const { data } = await api.get(ApiRoutes.CustomerOrders);

    if (data) {
      dispatch(setOrdersList(data));
      dispatch(setIsLoadingOrder(false));
    }
  } catch (error) {
    const { message, status } = apiErrorHandler(error);
    dispatch(setOrderError(`${status} ${message}`));
    dispatch(setIsLoadingOrder(false));
    setTimeout(() => {
      dispatch(resetOrderError());
    }, 3000);
  }
});

export const fetchCurrentCustomerOrderAction = createAsyncThunk<
  void,
  { orderId: string },
  ThunkConfig<string>
>("order/fetchCurrentCustomerOrder", async ({ orderId }, { dispatch,extra: { api } }) => {
  dispatch(setIsLoadingOrder(true));
  dispatch(resetOrderError());
  try {
    const { data } = await api.get(`${ApiRoutes.CustomerOrders}/${orderId}`, {
      params: { orderId },
    });
    
    if (data) {
      dispatch(setCurrentOrder(data));
      dispatch(setIsLoadingOrder(false));
    }
  } catch (error) {
    const { message, status } = apiErrorHandler(error);
    dispatch(setOrderError(`${status} ${message}`));
    dispatch(setIsLoadingOrder(false));
    setTimeout(() => {
      dispatch(resetOrderError());
    }, 3000);
  }
});


export const DeleteOrderAction = createAsyncThunk<
  void,
  { orderId: string },
  ThunkConfig<string>
>(
  "orders/DeleteOrder",
  async ({orderId}, { dispatch, getState,extra: { api } }) => {
    dispatch(setIsLoadingOrder(true));
    dispatch(resetOrderError());
    try {
      const response = await api.delete(ApiRoutes.Orders, {
        data: { orderId },
      });

      if (response.status === RequestStatusCode.OK) {
        dispatch(deleteProject(orderId));
        dispatch(setIsLoadingOrder(false));
      }
    } catch (error) {
      dispatch(setIsLoadingOrder(false));
      const { message, status } = apiErrorHandler(error);
    dispatch(setOrderError(`${status} ${message}`));
      setTimeout(() => {
        dispatch(resetOrderError());
      }, 3000);
    }
  }
);

