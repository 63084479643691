import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseButtonModal from "components/common/CloseIconModal/CloseIconModal";

const style = {
  position: "absolute",
  minWidth: "300px",
  maxHeight: "100vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
  overflow: "auto"
};

interface IModal {
  onClose: () => void;
  open: boolean;
  children?: JSX.Element | JSX.Element[];
}

export default function BasicModal(props: IModal) {
  const { onClose, open, children } = props;

  const closeModal = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseButtonModal onClose={closeModal} />
          {children}
        </Box>
      </Modal>
    </div>
  );
}
