import { CartProductCardTypeObj, ProductCardType } from 'types/products';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import style from './ProductCard.module.scss';
import ImageSlider from './ImgSlider/ImgSlider';
import { ProductReviewsSmallWidget } from 'entities/Reviews';
import AddProductsToCartButton from '../AddProductsToCartButton/AddProductsToCartButton';
import { useAppSelector } from 'redux/hooks';
import { getCartProductsObj } from 'entities/Cart';
import { getNextProductCountAndPrice } from 'utils';
import { ProductViewTypeEnum } from 'entities/CustomLink';
import { formatAmount } from 'utils/formatAmount';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

const getProductCountInCart = (
  cartProductObj: CartProductCardTypeObj,
  productId: string
) => {
  const keys = Object.keys(cartProductObj);

  const targetElem = keys.find((el) => el.includes(productId));

  if (!targetElem) {
    return 0;
  }

  return cartProductObj[targetElem]?.count || 0;
};

const ProductCard = ({
  product,
  productViewType = ProductViewTypeEnum.GRID,
}: {
  product: ProductCardType;
  productViewType?: ProductViewTypeEnum;
}) => {
  const navigate = useNavigate();
  const isWhite = useIsWhiteMoreContrast();

  if (!product) {
    return null;
  }  

  const cartProducts = useAppSelector(getCartProductsObj);

  const targetProductCartCount = useCallback(
    () => getProductCountInCart(cartProducts, product.productId),
    [cartProducts, product.productId]
  )();

  const nextPriceAndCount = useCallback(
    () => getNextProductCountAndPrice(product, targetProductCartCount),
    [product, targetProductCartCount]
  )();

  const { name, s3ImgList, productId, reviews } = product;

  const imageList = s3ImgList.map((el, ind) => ({
    src: el.imgLink,
    alt: `Картинка №${ind} для товара ${name}`,
  }));

  const handleCardClick = () => {
    navigate(`${productId}`);
  };

  if (productViewType === ProductViewTypeEnum.LIST) {
    return (
      <div
        className={classNames(style.cardListWrapper, {
          [style.cardListWrapperWhite]: isWhite,
        })}
        onClick={handleCardClick}
      >
        <div
          className={classNames(
            style.imgSliderWrapper,
            style.imgSliderWrapperList
          )}
        >
          <ImageSlider viewType={productViewType} slides={imageList} />
          {Boolean(targetProductCartCount) && (
            <div className={style.imgSliderMask}>{targetProductCartCount}</div>
          )}
        </div>
        <div className={style.cardListContent}>
          <div className={style.cardListTextContent}>
            <p
              className={classNames(style.cardTitle, {
                [style.cardTitleWhite]: isWhite,
              })}
            >
              {name}
            </p>

            <ProductReviewsSmallWidget reviews={reviews} />
          </div>

          <div className={style.cardListActions}>
            <AddProductsToCartButton
              targetProductCartCount={targetProductCartCount}
              cartProductId={product.productId + '?'}
              product={product}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={style.cardWrapper} onClick={handleCardClick}>
      <div className={style.imgSliderWrapper}>
        <ImageSlider viewType={productViewType} slides={imageList} />
        {Boolean(targetProductCartCount) && (
          <div className={style.imgSliderMask}>{targetProductCartCount}
          {nextPriceAndCount && (
        <>
          <div
            className={classNames(style.moreTextWrapper, {
              [style.moreTextWrapperWhite]: isWhite,
            })}
          >
            <span className={style.moreText}>
              ещё {nextPriceAndCount.nextCount} до{' '}
              {formatAmount(nextPriceAndCount.nextPrice)}
            </span>
          </div>
        </>
      )}
          </div>
        )}
      </div>
      <div className={style.cardContent}>
        <p
          className={classNames(style.cardTitle, {
            [style.cardTitleWhite]: isWhite,
          })}
        >
          {name}
        </p>

        <ProductReviewsSmallWidget reviews={reviews} />
      </div>

      <AddProductsToCartButton
        targetProductCartCount={targetProductCartCount}
        cartProductId={product.productId + '?'}
        product={product}
      />
    </div>
  );
};

export default memo(ProductCard);
