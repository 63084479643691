// component
import SvgColor from "components/svg-color/SvgColor";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Заказы",
    path: "/customer/orders",
    icon: icon("ic_analytics"),
    isAdminAccess: false,
  },
  {
    title: "Магазины",
    path: "/customer/shops",
    icon: icon("ic_user"),
    isAdminAccess: false,
  },
  {
    title: "Настройки",
    path: "/customer/settings",
    icon: icon("ic_cart"),
    isAdminAccess: false,
  },
];

export default navConfig;
