import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const page = searchParams.get('pageIndex') || '0';
  const size = searchParams.get('pageSize') || '5';
  const search = searchParams.get('search') || '';
  const filter = searchParams.get('filter') || '';
  const rating = searchParams.get('rating') || '0';

  return { page, size, search, filter, rating, setSearchParams };
};
