import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActiveEditProductDataI,
  GetStockRequestDataI,
  StockSliceI,
  UpdateStockItemI,
  ValidationErrorsType,
} from '../../lib/types';
import { getStockData } from '../services/getStockData';
import { updateStockItem } from '../services/updateStockItem';

const initialState: StockSliceI = {
  stockData: null,
  totalItemCount: 0,
  page: 1,
  pageSize: 50,
  isLoading: false,
  isCellLoading: false,
  validationErrors: {},
  isCreateModalVisible: false,
};

const StockSlice = createSlice({
  name: 'StockSlice',
  initialState,
  reducers: {
    setStockData: (state, action: PayloadAction<StockSliceI['stockData']>) => {
      state.stockData = action.payload;
    },
    setTotalItemCount: (state, action: PayloadAction<number>) => {
      state.totalItemCount = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setValidationErrors: (
      state,
      action: PayloadAction<ValidationErrorsType>
    ) => {
      state.validationErrors = action.payload;
    },
    setIsStockOrderEditVisible: (state, action: PayloadAction<boolean>) => {
      state.isCreateModalVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStockData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getStockData.fulfilled,
        (state, action: PayloadAction<GetStockRequestDataI>) => {
          state.isLoading = false;

          state.stockData = action.payload.stockData;
          state.totalItemCount = action.payload.totalCount;
        }
      )
      .addCase(getStockData.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateStockItem.pending, (state) => {
        state.isCellLoading = true;
      })
      .addCase(
        updateStockItem.fulfilled,
        (state, action: PayloadAction<UpdateStockItemI | undefined>) => {
          state.isCellLoading = false;

          if (!action.payload) {
            return;
          }
        }
      )
      .addCase(updateStockItem.rejected, (state, action) => {
        state.isCellLoading = false;
      });
  },
});

export default StockSlice;
export const StockSliceActions = StockSlice.actions;
