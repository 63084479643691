import {
  CartClientDataType,
  CustomerLoginDataType,
  UserLoginDataType,
} from './../types/user';
import { CartProductCardType, S3ImgBase64 } from 'types/products';
import { CreateLinkWiTypes, UpdateLinkTypes } from 'types/links';
import { UserAuthResponse } from 'types/api';
import { ApiRoutes } from 'consts';
import axios from 'axios';
import propertyOf from 'lodash/propertyOf';
import { IAutocomplitingAddress, PointType } from 'features/sdek/NewSdekOrder';
import { BASE_URL, apiInstance } from './apiInstance';

interface SendContactDataPropsI {
  phone: string;
  tgNickname: string;
  marketingApprove: boolean;
}

export const sendContactData = async (data: SendContactDataPropsI) => {
  const response = await apiInstance.post(ApiRoutes.UserContact, data);

  return response?.data;
};

export const getTariffData = async () => {
  const response = await apiInstance.get(ApiRoutes.Tariff);

  return response?.data;
};

const api = {
  async restorePassword({
    code,
    newPassword,
  }: {
    code: string;
    newPassword: string;
  }) {
    const response = await apiInstance.put(`/${ApiRoutes.RestorePassword}`, {
      code,
      newPassword,
    });

    return response;
  },

  async restorePasswordPhone({
    code,
    newPassword,
  }: {
    code: string;
    newPassword: string;
  }) {
    const response = await apiInstance.put(
      `/${ApiRoutes.RestorePasswordCustomer}`,
      {
        code,
        newPassword,
      }
    );

    return response;
  },

  async sendVerificationCodeToEmail(email: string) {
    const response = await apiInstance.post(`/${ApiRoutes.SendCode}`, {
      email,
    });

    return response;
  },

  async getPaginationData(route: string, page?: number, size?: number | null) {
    const { data } = await apiInstance.get(`/${route}`, {
      params: { page, size },
    });

    return data;
  },

  async sendVerificationCodeToPhone(phone: string) {
    const response = await apiInstance.post(`/${ApiRoutes.SendCodeCustomer}`, {
      phone,
    });

    return response;
  },

  async sendUserVerificationCode(code: string) {
    const res = await apiInstance.post(`/${ApiRoutes.Verification}`, {
      code,
    });

    return res;
  },

  async sendCustomerVerificationCode(code: string) {
    const res = await apiInstance.post(`/${ApiRoutes.VerificationCustomer}`, {
      code,
    });

    return res;
  },

  async customerLogin({ phone, password }: CustomerLoginDataType) {
    const response = await axios.post(`${BASE_URL}/auth/customer/login`, {
      userLogin: phone,
      userPassword: password,
    });

    return response.data as UserAuthResponse;
  },

  async login(userLoginData: UserLoginDataType) {
    const response = await apiInstance.post(`/auth/signin`, {
      // toDo переделать схему данных на просто email и password
      userLogin: userLoginData.email,
      userPassword: userLoginData.password,
    });

    return response.data as UserAuthResponse;
  },

  async getTokenByAdmin(userId: string) {
    const response = await apiInstance.get(`/auth/${userId}`, {
      params: { userId },
    });

    return response.data;
  },

  async sendCart({
    products,
    clientData,
    sellerId,
    linkId,
    point,
    address,
  }: {
    products: CartProductCardType[];
    clientData: CartClientDataType;
    sellerId: string;
    linkId: string;
    point: PointType | null;
    address: IAutocomplitingAddress | null;
  }) {
    const { data } = await apiInstance.post(ApiRoutes.Orders, {
      products,
      clientData,
      sellerId,
      linkId,
      point,
      address,
    });

    return data;
  },

  async getLink(
    linkId: string,
    page: number,
    rowsPerPage: number,
    search?: string
  ) {
    const response = await apiInstance.get(`/link`, {
      params: { linkId, page, rowsPerPage, search },
    });
    return response.data;
  },

  async getLinkProducts(
    linkId: string,
    page: number,
    rowsPerPage: number,
    search?: string,
    sortBy?: string,
    sortType?: string,
    categories?: string
  ) {
    const response = await apiInstance.get(`/link/products`, {
      params: {
        linkId,
        page,
        rowsPerPage,
        search,
        sortBy,
        sortType,
        categories,
      },
    });

    return response.data;
  },

  async getEditLinkData(linkId: string) {
    const response = await apiInstance.get(
      `/link?linkId=${linkId}&isEditMode=true`
    );
    return response.data;
  },

  async getPrivateLinkData(linkId: string, password: string) {
    const response = await apiInstance.post(`/link/auth`, {
      linkId,
      password,
    });

    return response.data;
  },

  async createLink(pageData: CreateLinkWiTypes) {
    const response = await apiInstance.post(`/link`, pageData);

    return response.data;
  },

  async updateRedirectLink(linkData: UpdateLinkTypes) {
    const response = await apiInstance.put(`/link`, linkData);

    return response.data;
  },

  async updateProductPrice(productId: string, newPrice: number) {
    const response = await apiInstance.put(`/products/price`, {
      productId,
      newPrice,
    });

    return response.data;
  },

  async updateProductCostPrice(productId: string, costPrice: number) {
    const response = await apiInstance.put(`/products/cost-price`, {
      productId,
      costPrice,
    });

    return response.data;
  },

  getCompanyData: async () => {
    const response = await apiInstance.get(`/api/account/user`);
    return propertyOf(response)(['data']);
  },

  addNewUser: async (email: string, userId: string, companyName: string) => {
    return await apiInstance.post(`${BASE_URL}/api/account/user`, {
      email,
      userId,
      companyName,
    });
  },

  copyProduct: async (productId: string) => {
    const result = await apiInstance.post(`/products/copy/${productId}`);

    return result?.data;
  },

  async getImageFile(fileName: string) {
    const { data } = await apiInstance.get(`s3yandex/${fileName}`);

    return data.Body.data;
  },

  async getSellerTgChannelList() {
    const result = await apiInstance.get('/bot/tg-channel');

    return result.data;
  },
};

export default api;
