import { memo } from 'react';
import CheckboxMUI from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CheckboxProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
}

export const Checkbox = memo(({ checked, onChange, label }: CheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <CheckboxMUI
          checked={checked}
          onChange={(evt) => onChange(evt.target.checked)}
        />
      }
      label={label}
    />
  );
});
