import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, Button, Drawer, ListItemText, Stack } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import useResponsive from "hooks/useResponsive";
import Logo from "components/logo/logo";
import NavSection from "components/NavSection/NavSection";
import navConfig from "./config";
import {
  StyledNavItem,
  StyledNavItemIcon,
} from "components/NavSection/styles";
import { useAppDispatch } from "redux/hooks";
import { logoutCustomerAction } from "redux/CustomerSlice/asyncActions";

const NAV_WIDTH = 280;
interface INav {
  openNav: boolean;
  onCloseNav: () => void;
}

export default function Nav({ openNav, onCloseNav }: INav) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useResponsive("up", "lg");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleClickLogout = () => {
    dispatch(logoutCustomerAction());
     navigate("/signin");
  };

  const renderContent = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <Box sx={{ px: 2.5, pt: 3, display: "inline-flex" }}>
          <Logo />
        </Box>

        <NavSection data={navConfig} />

        <Box sx={{ flexGrow: 1 }} />

        <Stack spacing={2} sx={{ px: 2.5, pb: 3, mt: "auto" }}>
          <StyledNavItem
            target="_blank"
            rel="noreferrer"
            component={RouterLink}
            to="https://t.me/LinkWiSupportBot"
            sx={{
              "&.active": {
                color: "text.primary",
                bgcolor: "action.selected",
                fontWeight: "fontWeightBold",
              },
            }}
          >
            <StyledNavItemIcon>
              <TelegramIcon />
            </StyledNavItemIcon>

            <ListItemText disableTypography primary="Служба поддержки" />
          </StyledNavItem>

          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={handleClickLogout}
          >
            Выйти
          </Button>
        </Stack>
      </Box>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
