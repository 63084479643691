import CartDeliveryForm from "../CartDeliveryForm/CartDeliveryForm";
import CartProductsBlock from "../CartProductBlock/CartProductsBlock";
import CartShowAllData from "../CartShowAllData/CartShowAllData";

type CartSwitchBlockProps = {
  activeStep: number;
  setActiveStep: (cb: (activeStep: number) => number) => void;
  cartDeliveryFormGoNext: number;
};

const CartSwitchBlock = ({
  activeStep,
  setActiveStep,
  cartDeliveryFormGoNext,
}: CartSwitchBlockProps) => {
  switch (activeStep) {
    case 0: {
      return <CartProductsBlock />;
    }
    case 1: {
      return (
        <CartDeliveryForm
          cartDeliveryFormGoNext={cartDeliveryFormGoNext}
          setActiveStep={setActiveStep}
        />
      );
    }
    default: {
      return <CartShowAllData setActiveStep={setActiveStep} />;
    }
  }
};

export default CartSwitchBlock;
