import { StateSchema } from 'app/providers/StoreProvider';
import { UserAuthStatus } from 'consts';
import { TelergamDataType } from 'types/user';

export const getCustomerPhone = (state: StateSchema): string =>
  state.CustomerSlice.phone;

export const getCustomerRequestStatus = (state: StateSchema): number =>
  state.CustomerSlice.requestStatus;

export const getCustomerErrorMessage = (state: StateSchema): string =>
  state.CustomerSlice.error;

export const getCustomerLoadingStatus = (state: StateSchema): boolean =>
  state.CustomerSlice.isLoading;

export const getCustomerAuthStatus = (state: StateSchema): UserAuthStatus =>
  state.CustomerSlice.customerAuthStatus;

export const getCustomerTelegramData = (state: StateSchema): TelergamDataType =>
  state.CustomerSlice.telegramData;
