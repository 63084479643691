import { StateSchema } from 'app/providers/StoreProvider';

export const getPromocodeErrorText = (state: StateSchema): string =>
  state.Promocode.errorText;

export const getPromocodeValue = (state: StateSchema): string =>
  state.Promocode.promocodeValue;

export const getPromocodeLoadingStatus = (state: StateSchema): boolean =>
  state.Promocode.isLoading;

export const getIsModalVisible = (state: StateSchema): boolean =>
  state.Promocode.isModalVisible;

export const getPromoDiscount = (state: StateSchema): number =>
  state.Promocode.promoDiscount;
