import {
  Stack,
  FormControl,
  Typography,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';

import { useEffect } from 'react';
import { OptionsOrderType } from 'types/order';
import { CustomFieldListType } from 'types/products';
import { getCustomFieldsOrValuesSortedByIndex } from 'utils/CustomFieldsUtils';

type ProductsOptionButtonsProps = {
  options: CustomFieldListType | undefined;
  selectedProductOptions: OptionsOrderType | null;
  setOptionsProduct: (value: OptionsOrderType) => void;
};

const ProductsOptionButtons = ({
  options,
  selectedProductOptions,
  setOptionsProduct,
}: ProductsOptionButtonsProps) => {
  const handleChangeCustomValues = (
    value: string,
    name: string,
    id: string
  ) => {
    const options = cloneDeep(selectedProductOptions);
    if (!options) {
      const newSelectedOption: any = {};
      newSelectedOption[id] = { value, name };
      return setOptionsProduct(newSelectedOption);
    }
    options[id] = { value, name };
    return setOptionsProduct(options);
  };

  if (!options) {
    return null;
  }

  return (
    <Stack>
      {getCustomFieldsOrValuesSortedByIndex(options).map((field) => (
        <FormControl fullWidth key={field.id}>
          <Typography variant='subtitle2' sx={{ mb: '0.5rem' }}>
            {field.name}
          </Typography>
          <Select
            sx={{
              maxWidth: 350,
              '& .MuiSelect-select': {
                p: '7px 12px',
              },
            }}
            value={selectedProductOptions?.[field.id]?.value}
            onChange={(evt: any) =>
              handleChangeCustomValues(evt.target.value, field.name, field.id)
            }
          >
            {getCustomFieldsOrValuesSortedByIndex(field.values).map(
              ({ id, value }) => (
                <MenuItem key={id} value={value} sx={{ maxWidth: 350 }}>
                  <ListItemText primary={value} sx={{ textWrap: 'wrap' }} />
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      ))}
    </Stack>
  );
};

export default ProductsOptionButtons;
