import { Stack } from '@mui/system';
import { memo, useEffect, useState } from 'react';
import { Box, FormControl, TextareaAutosize, TextField } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { styled } from '@mui/material/styles';
import BasePhoneInput from 'components/ui/BasePhoneInput/BasePhoneInput';
import { CartDeliveryValidation } from './lib/validation/CartDeliveryValidationSchema';
import { AddressDelivery } from './ui/AddressDelivery/AddressDelivery';
import { IAutocomplitingAddress } from 'features/sdek/NewSdekOrder';
import { PointType } from 'features/sdek/NewSdekOrder';
import { getCustomLinkTariffRestriction } from 'entities/CustomLink';
import { CartActions, getCartClientData } from 'entities/Cart';

const TextFieldDelivery = styled(TextField)({
  '& .MuiInputBase-input': {
    backgroundColor: '#F6F8FA',
  },
  '& input:valid + fieldset': {
    borderColor: 'green',
    borderWidth: 2,
  },
});

type CartDeliveryFormProps = {
  setActiveStep: (cb: (activeStep: number) => number) => void;
  cartDeliveryFormGoNext: number;
};

const CartDeliveryForm = ({
  setActiveStep,
  cartDeliveryFormGoNext,
}: CartDeliveryFormProps) => {
  const dispatch = useAppDispatch();
  const clientData = useAppSelector(getCartClientData);
  const [didMount, setDidMount] = useState(false);
  const matches = useMediaQuery('(max-width:650px)');
  const { isPremiumModulesAvailable } = useAppSelector(
    getCustomLinkTariffRestriction
  );

  const [name, setName] = useState('');
  const [surName, setSurName] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState<IAutocomplitingAddress | null>(null);
  const [comment, setComment] = useState('');
  const [tgNick, setTgNick] = useState('');
  const [point, setPoint] = useState<PointType | null>(null);
  const [isUseSdek, setIsUseSdek] = useState(
    isPremiumModulesAvailable ?? false
  );

  useEffect(() => {
    if (clientData) {
      const { name, surName, fatherName, phone, comment, tgNick } = clientData;

      setName(name);
      setSurName(surName);
      setFatherName(fatherName);
      setPhone(phone);
      setAddress(address);
      setComment(comment);
      setTgNick(tgNick);
    }
  }, []);

  const onSubmit = async () => {
    const { isValid } = await CartDeliveryValidation({
      name,
      surName,
      fatherName,
      phone,
      address,
      comment,
      tgNick,
      point,
      isUseSdek,
    });

    if (isValid) {
      return;
    }

    dispatch(
      CartActions.setCartClientData({
        name,
        surName,
        fatherName,
        phone,
        comment,
        tgNick,
      })
    );

    dispatch(CartActions.setAddress(address as IAutocomplitingAddress));
    dispatch(CartActions.setPoint(point as PointType));

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (didMount) {
      onSubmit();
    }

    setDidMount(true);
  }, [cartDeliveryFormGoNext]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
        <b className='cart-main__name'>Доставка</b>
      </Box>
      <FormControl
        component='form'
        sx={{ width: '100%', padding: '15px', maxWidth: '1500px' }}
      >
        <Stack spacing={3}>
          <Stack className='cart-main__user-name'>
            <TextField
              id='surName'
              name='surName'
              variant='filled'
              hiddenLabel
              placeholder='Фамилия*'
              fullWidth
              onChange={(evt) => setSurName(evt.target.value)}
              value={surName}
              sx={{
                marginRight: matches ? '0' : '30px',
                marginBottom: matches ? '30px' : '0px',
                flexGrow: '1',
              }}
            />
            <TextFieldDelivery
              id='name'
              name='name'
              hiddenLabel
              variant='filled'
              placeholder='Имя*'
              fullWidth
              onChange={(evt) => setName(evt.target.value)}
              value={name}
              FormHelperTextProps={{}}
              sx={{
                backgroundColor: '#fff',
                marginRight: matches ? '0' : '30px',
                marginBottom: matches ? '30px' : '0px',
                flexGrow: '1',
                '.MuiFormHelperText': {
                  position: 'absolute',
                },
              }}
            />
            <TextField
              id='fatherName'
              name='fatherName'
              variant='filled'
              hiddenLabel
              placeholder='Отчество'
              fullWidth
              onChange={(evt) => setFatherName(evt.target.value)}
              value={fatherName}
              sx={{ flexGrow: '1' }}
            />
          </Stack>

          <BasePhoneInput
            id='phone'
            name='phone'
            hiddenLabel
            variant='filled'
            onlyCountries={['RU', 'KZ', 'BY', 'UA']}
            langOfCountryName='ru'
            defaultCountry='RU'
            onChange={(phone: string) => setPhone(phone)}
            value={phone}
            inputProps={{
              autoComplete: 'off',
              maxLength: 16 // maxPhoneLength
            }}
          />

          <TextField
            id='tgNick'
            name='tgNick'
            hiddenLabel
            variant='filled'
            placeholder='Telegram (необязательное)'
            fullWidth
            onChange={(evt) => setTgNick(evt.target.value)}
            value={tgNick}
          />

          <AddressDelivery
            setAddress={setAddress}
            address={address}
            setPoint={setPoint}
            point={point}
            setIsUseSdek={setIsUseSdek}
            isUseSdek={isUseSdek}
          />
          <TextareaAutosize
            placeholder='Комментарий (необязательное)'
            id='comment'
            name='comment'
            style={{
              borderRadius: '10px',
              border: '1px solid  rgb(0 0 0 / 20%)',
              padding: '15px',
              fontSize: '18px',
              background: 'inherit',
              resize: 'none',
            }}
            minRows={6}
            onChange={(evt) => setComment(evt.target.value)}
            value={comment}
          />
        </Stack>
      </FormControl>
    </>
  );
};

export default memo(CartDeliveryForm);
