import { StateSchema } from 'app/providers/StoreProvider';
import { ActiveEditProductDataI, ValidationErrorsType } from 'entities/Stock/lib/types';

export const getStockPagination = (
  state: StateSchema
): { page: number; pageSize: number } => ({
  page: state?.Stock?.page,
  pageSize: state?.Stock?.pageSize,
});

export const getIsStockDataLoading = (state: StateSchema): boolean =>
  state?.Stock?.isLoading;

export const getIsStockDataCellLoading = (state: StateSchema): boolean =>
  state?.Stock?.isCellLoading;

export const getStockDataList = (state: StateSchema): any[] | null =>
  state?.Stock?.stockData;

export const getValidationErrors = (state: StateSchema): ValidationErrorsType =>
  state?.Stock?.validationErrors;

export const getIsCreateIOrderModalVisible = (state: StateSchema): boolean =>
  state?.Stock?.isCreateModalVisible;
