import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getCustomerSuggestions } from '../../model/services/getCustomerSuggestions';
import { OrderSliceActions } from 'features/Orders/model/slice/stockOrderEditSlice';
import {
  getCurrentOrderData,
  getCustomerSuggestionList,
  getNewCustomerData,
} from '../../model/selectors/stockOrderEditSelectors';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';

import style from './OrderClientPhone.module.scss';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { CustomerInfoI } from 'features/Orders/lib/types';

import { addRussianCountryCodeIfNeeded } from 'utils';

interface OrderClientPhoneProps {
  phoneValue: string;
  onPhoneChange: (value: string) => void;
}

const OrderClientPhone = ({
  phoneValue,
  onPhoneChange,
}: OrderClientPhoneProps) => {
  const dispatch = useAppDispatch();
  const { customerInfo } = useAppSelector(getCurrentOrderData);
  const newCustomerData = useAppSelector(getNewCustomerData);
  const suggestionList = useAppSelector(getCustomerSuggestionList);

  const debouncedDispatchCustomerSuggestions = debounce((phone) => {
    dispatch(getCustomerSuggestions(phone));
  }, 300);

  const handlePhoneChange = (_: any, value: string, reason: string) => {
    if (reason === 'input') {
      onPhoneChange(value);

      const with7number = addRussianCountryCodeIfNeeded(value);
      // Добавляем +7 при необходимости
      let formattedValue = new AsYouType('RU').input(with7number);
      // Обнулим id если что-то было
      dispatch(
        OrderSliceActions.setNewClientData({
          phone: formattedValue,
          id: undefined,
        })
      );

      if (value.length >= 3) {
        const phoneNumber = parsePhoneNumberFromString(with7number, 'RU');
        if (phoneNumber && phoneNumber.number) {
          debouncedDispatchCustomerSuggestions(phoneNumber.number);
        }
      }
    }
  };

  const handleSelect = (event: any, value: string | CustomerInfoI | null) => {
    if (value && typeof value !== 'string') {
      // Форматируем номер телефона выбранного клиента, добавляя +7 при необходимости
      const formattedPhone = new AsYouType('RU').input(
        addRussianCountryCodeIfNeeded(value.phone || '')
      );
      dispatch(
        OrderSliceActions.setNewClientData({ ...value, phone: formattedPhone })
      );
    }
  };

  return (
    <div className={style.wrapper}>
      <Autocomplete
        freeSolo
        id='autocomplete-client-phone'
        options={suggestionList || []}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : new AsYouType('RU').input(option.phone || '') || ''
        }
        onInputChange={handlePhoneChange}
        onChange={handleSelect}
        inputValue={phoneValue}
        renderInput={(params) => (
          <BaseInput {...params} label='Телефон' fullWidth />
        )}
        renderOption={(props, option) => {
          const isOptionType = (option: any): option is CustomerInfoI =>
            typeof option !== 'string';
          return (
            <li
              {...props}
              key={isOptionType(option) ? option.id : 'freeSoloInputValue'}
              className={style.item}
            >
              <div className={style.itemContent}>
                {isOptionType(option) ? (
                  <>
                    <p
                      className={style.itemTitle}
                    >{`${option.surName} ${option.name} ${option.fatherName}`}</p>
                    <p>{new AsYouType('RU').input(option.phone || '')}</p>
                  </>
                ) : (
                  <p>{option}</p>
                )}
              </div>
            </li>
          );
        }}
      />
    </div>
  );
};

export default OrderClientPhone;
