import CustomLinkPageContent from 'components/CustomLinkPageContent/CustomLinkPageContent';
import CustomLinkLayout from 'app/layouts/CustomLinkLayout/CustomLinkLayout';
import CartPage from 'pages/CartPage/CartPage';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const ProductDetailPage = lazy(
  () => import('pages/ProductDetailPage/ProductDetailPage')
);

const CustomLinkRoutes = () => {
  return (
    <Routes>
      <Route element={<CustomLinkLayout />}>
        <Route path='/' element={<CustomLinkPageContent />} />
      </Route>
      <Route path='/:productId' element={<ProductDetailPage />} />
      <Route path='/' element={<CustomLinkPageContent />} />
      <Route path='/cart' element={<CartPage />} />
    </Routes>
  );
};

export default CustomLinkRoutes;
