import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box/Box';
import BaseInput from 'components/ui/BaseInput/BaseInput';

type AutocompleteInputProps = {
  value: string;
  options: string[];
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  className?: string;
};

const AutocompleteInput = ({
  value,
  options,
  onChange,
  className,
}: AutocompleteInputProps) => {
  return (
    <Box
      className={className}
      sx={{
        my: '20px',
      }}
    >
      <Autocomplete
        freeSolo
        id='autocomplete'
        disableClearable
        value={value}
        options={options}
        renderInput={(params) => (
          <BaseInput
            {...params}
            label='Найти'
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        onChange={onChange}
      />
    </Box>
  );
};

export default AutocompleteInput;
