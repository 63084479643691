import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import { DEFAULT_PAGE_ITEM_COUNT, sortPriceValues } from '../lib/types';

const useSyncSearchWithUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Мемоизация функции получения значения фильтра
  const getFilterValue = useCallback(
    (filterName: string): string | null => {
      return searchParams.get(filterName);
    },
    [searchParams]
  );

  // Мемоизация функции обновления фильтра
  const updateFilter = useCallback(
    (filterName: string, value: string | null) => {
      const newSearchParams = new URLSearchParams(searchParams.toString());

      if (value === null || !value) {
        newSearchParams.delete(filterName);
      } else {
        newSearchParams.set(filterName, value);
      }      

      if (filterName === 'sortType') {        
        if (value === 'default') {
          newSearchParams.delete('sortBy');
          newSearchParams.delete('sortType');
        } else {
          newSearchParams.set('sortBy', 'price');
        }
      }

      if (filterName === 'search') {
        newSearchParams.set('page', '1');
      }

      setSearchParams(newSearchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  // Дебаунс для функции обновления фильтра с использованием useCallback, чтобы гарантировать стабильность ссылки
  const debouncedUpdateFilter = useCallback(debounce(updateFilter, 500), [
    updateFilter,
  ]);

  // Возвращаем значения фильтров и функции для их обновления, мемоизированные с использованием useMemo
  return useMemo(
    () => ({
      searchValue: getFilterValue('search'),
      sortBy: getFilterValue('sortType') ? 'price' : undefined,
      sortTypeValue:
        getFilterValue('sortType') ||
        sortPriceValues.find((el) => el.value === 'default')?.value,
      categoryFilter: getFilterValue('category')?.split(';') || [],
      page: Number(getFilterValue('page')) || 1,
      rowsPerPage:
        Number(getFilterValue('rowsPerPage')) || DEFAULT_PAGE_ITEM_COUNT,
      updateSearch: (value: string | null) =>
        debouncedUpdateFilter('search', value),
      updateSort: (value: string | null) =>
        debouncedUpdateFilter('sortType', value),
      updateCategory: (value: string[] | null) =>
        debouncedUpdateFilter('category', value ? value.join(';') : null),
    }),
    [debouncedUpdateFilter, getFilterValue]
  );
};

export default useSyncSearchWithUrl;
