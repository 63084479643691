import { StateSchema } from 'app/providers/StoreProvider';
import { DEFAULT_SUMMARY_DATA } from '../../lib/const';
import { OrderDashboardDailyItemI, SummaryDataI } from '../../lib/types';


const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

export const getOrderDashboardDates = (
  state: StateSchema
): { dateFrom: Date; dateTo: Date } => ({
  dateFrom: state?.OrdersDashboard?.dateFrom || thirtyDaysAgo, // Значение по умолчанию, если dateFrom не определено
  dateTo: state?.OrdersDashboard?.dateTo || new Date(), // Значение по умолчанию, если dateTo не определено
});

export const getIsOrdersDashboardLoading = (state: StateSchema): boolean =>
  state?.OrdersDashboard?.isLoading || false;

export const getStatsByDayForOrderDashboard = (
  state: StateSchema
): OrderDashboardDailyItemI[] => state?.OrdersDashboard?.statsByDay || [];

export const getOrdersDashboardStats = (state: StateSchema): SummaryDataI =>
  state?.OrdersDashboard?.summaryData || DEFAULT_SUMMARY_DATA;
