import { apiInstance } from "services/apiInstance";

export const applyPromocode = async (promocode: string) => {

  try {
    const response = await apiInstance.post(`/promocode/apply/${promocode}`);

    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      return {
        errorText: error?.response?.data?.message || '',
      }
    }

    return false;
  }
};
