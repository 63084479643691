import 'animate.css';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './logging/init';
import App from './App';
import './components/style.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { MapProvider } from 'react-map-gl';
import { SnackbarCloseButton } from 'components/ui/SnackbarCloseButton/SnackbarCloseButton';
import { StoreProvider } from 'app/providers/StoreProvider';

const container = document.getElementById('root')!;
const root = createRoot(container);

const CAPTCHA_KEY =
  process.env.REACT_APP_RECAPTCHA_KEY ||
  '6Ledct8lAAAAAAmfYFSE64--K7YSSJclMAuUz1W8';

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <StoreProvider>
        <BrowserRouter>
          <GoogleReCaptchaProvider
            reCaptchaKey={CAPTCHA_KEY}
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
          >
            <HelmetProvider>
              <MapProvider>
                <SnackbarProvider
                  action={(snackbarKey) => (
                    <SnackbarCloseButton snackbarKey={snackbarKey} />
                  )}
                  autoHideDuration={3000}
                  preventDuplicate
                >
                  <App />
                </SnackbarProvider>
              </MapProvider>
            </HelmetProvider>
          </GoogleReCaptchaProvider>
        </BrowserRouter>
      </StoreProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
