import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getCurrentOrderData,
  getNewCustomerData,
} from '../../model/selectors/stockOrderEditSelectors';
import { OrderSliceActions } from '../../model/slice/stockOrderEditSlice';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { AddressInput } from 'features/FeatchAddressInput';
import { Button } from '@mui/material';
import classNames from 'classnames';
import style from './OrderClient.module.scss';
import { addNewClient } from '../../model/services/addNewClient';
import OrderClientPhone from '../OrderClientPhone/OrderClientPhone';

// Определение типа для состояния editedFields
type EditedFieldsState = {
  surName: boolean;
  name: boolean;
  fatherName: boolean;
  tgNick: boolean;
  address: boolean;
  phone: boolean;
};

const OrderClient: React.FC = () => {
  const dispatch = useAppDispatch();
  const { customerInfo } = useAppSelector(getCurrentOrderData);
  const newCustomerData = useAppSelector(getNewCustomerData);

  // Локальные состояния с типами
  const [editedFields, setEditedFields] = useState<EditedFieldsState>({
    surName: false,
    name: false,
    fatherName: false,
    tgNick: false,
    address: false,
    phone: false,
  });

  // Функция для обработки изменений в инпутах с типами
  const handleInputChange = (
    field: keyof EditedFieldsState,
    value: string
  ): void => {
    setEditedFields((prev) => ({ ...prev, [field]: true }));
    dispatch(OrderSliceActions.setNewClientData({ [field]: value }));
  };

  // Функция для определения значения поля с типами
  const fieldValue = (
    field: keyof EditedFieldsState,
    newValue: string | undefined,
    fallbackValue: string | undefined
  ): string =>
    editedFields[field] ? newValue ?? '' : newValue || fallbackValue || '';

  return (
    <>
      <div className={style.wrapper}>
        <p className={style.header}>Клиент</p>
        <div className={style.inputWrapper}>
          <OrderClientPhone
            phoneValue={fieldValue(
              'phone',
              newCustomerData?.phone,
              customerInfo?.phone
            )}
            onPhoneChange={(value: string) => handleInputChange('phone', value)}
          />
          <BaseInput
            label='Фамилия'
            variant='outlined'
            margin='normal'
            value={fieldValue(
              'surName',
              newCustomerData?.surName,
              customerInfo?.surName
            )}
            onChange={(evt) => handleInputChange('surName', evt.target.value)}
            className={style.Input}
          />
          <BaseInput
            label='Имя'
            variant='outlined'
            margin='normal'
            value={fieldValue(
              'name',
              newCustomerData?.name,
              customerInfo?.name
            )}
            onChange={(evt) => handleInputChange('name', evt.target.value)}
            className={style.Input}
          />
          <BaseInput
            label='Отчество'
            variant='outlined'
            margin='normal'
            value={fieldValue(
              'fatherName',
              newCustomerData?.fatherName,
              customerInfo?.fatherName
            )}
            onChange={(evt) =>
              handleInputChange('fatherName', evt.target.value)
            }
            className={style.Input}
          />
          <BaseInput
            label='Ник в Telegram'
            variant='outlined'
            margin='normal'
            value={fieldValue(
              'tgNick',
              newCustomerData?.tgNick,
              customerInfo?.tgNick
            )}
            onChange={(evt) => handleInputChange('tgNick', evt.target.value)}
            className={style.Input}
          />
          <AddressInput
            id='address'
            label='Адрес'
            className={classNames(style.Input, style.InputAddress)}
            value={fieldValue(
              'address',
              newCustomerData?.address,
              customerInfo?.address
            )}
            setLocation={(location: any) =>
              handleInputChange('address', location.value)
            } // Предполагается, что setLocation принимает объект с полем value типа string
          />
        </div>
        <Button
          sx={{ ml: 'auto' }}
          variant='outlined'
          onClick={() => dispatch(addNewClient())}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
};

export default OrderClient;
