import { createSlice } from "@reduxjs/toolkit";

import { ProjectType } from "types/projects";
import { LinkDataType, LinkObjType } from "types/links";

export type LinkSliceType = {
  project: ProjectType | null;
  links: LinkObjType;
  isLoading: boolean;
  errorMessage: string;
  currentLink: LinkDataType | null;
};

const initialState: LinkSliceType = {
  links: {},
  project: null,
  isLoading: false,
  errorMessage: "",
  currentLink: null
};

const LinkSlice = createSlice({
  name: "LinkSlice",
  initialState,
  reducers: {
    setCurrentLink: (state, action) => {
      state.currentLink = action.payload;
    },

    setIsLoadingLinks: (state, action) => {
      state.isLoading = action.payload;
    },

    setLinks: (state, action) => {
      state.links = action.payload;
    },

    addLink: (state, action) => {
      state.links[action.payload.linkId] = action.payload;
    },

    deleteLink: (state, action) => {
      delete state.links[action.payload];
      state.currentLink = null;
    },

    setLinkError: (state, action) => {
      state.errorMessage = action.payload;
    },

    updateLink: (state, action) => {
      state.links[action.payload.linkId].name = action.payload.name;
      state.currentLink = null;
    },

    resetLinkError: (state) => {
      state.errorMessage = "";
    },
  },
});

export default LinkSlice;

export const {
  addLink,
  setLinks,
  deleteLink,
  updateLink,
  setLinkError,
  setCurrentLink,
  resetLinkError,
  setIsLoadingLinks,
} = LinkSlice.actions;
