import { memo } from 'react';
import { Stack } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { getCartAddress, getCartClientData } from 'entities/Cart';

const CartShowUserData = () => {
  const address = useAppSelector(getCartAddress);
  const clientData = useAppSelector(getCartClientData);

  return (
    <Stack sx={{ marginBottom: '30px', width: '100%' }}>
      <Stack className='cart-main__show-field'>
        <span>ФИО:</span>
        <p>
          {clientData?.name} {clientData?.surName}
        </p>
      </Stack>

      <Stack className='cart-main__show-field'>
        <span>Номер телефона:</span>
        <p>{clientData?.phone}</p>
      </Stack>

      <Stack className='cart-main__show-field'>
        <span>Адрес:</span>
        <p>{address?.value}</p>
      </Stack>

      {clientData?.comment && (
        <Stack className='cart-main__show-field'>
          <span>Комментарий:</span>
          <p>{clientData?.comment}</p>
        </Stack>
      )}
    </Stack>
  );
};

export default memo(CartShowUserData);
