import { useEffect, useState } from "react";

export const useIsWhiteMoreContrast = () => {
    const [isWhiteMoreContrast, setIsWhiteMoreContrast] = useState<boolean>(false);
  
    useEffect(() => {
      const updateContrast = () => {
        const bgColor = localStorage.getItem('currentPageBgColor');
        if (!bgColor) {
          console.error('Цвет фона не найден в localStorage');
          return;
        }
  
        const r = parseInt(bgColor.slice(1, 3), 16);
        const g = parseInt(bgColor.slice(3, 5), 16);
        const b = parseInt(bgColor.slice(5, 7), 16);
  
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        setIsWhiteMoreContrast(brightness > 186);
      };
  
      updateContrast();
  
      const handleCustomStorageChange = (event: any) => {
        if (event.detail.key === 'currentPageBgColor') {
          updateContrast();
        }
      };
  
      window.addEventListener('local-storage-change', handleCustomStorageChange);
  
      return () => {
        window.removeEventListener('local-storage-change', handleCustomStorageChange);
      };
    }, []);
  
    return isWhiteMoreContrast;
  };