import isEmpty from 'lodash/isEmpty';
import { useAppDispatch } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';
import { memo, useState, useEffect, useCallback } from 'react';

import { CartActions } from 'entities/Cart';
import Iconify from 'components/common/Iconify/Iconify';
import { ProductCardType } from 'types/products';
import { formatAmount } from 'utils/formatAmount';
import {
  getCurrentPrice,
  getNextProductCountAndPrice,
} from 'utils';

import style from './ProductCounter.module.scss';
import classNames from 'classnames';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

type ProductCountControllerProps = {
  startCount: number;
  cartProductId: string;
  minValue?: number;
  product: ProductCardType;
};
const ProductCount = ({
  startCount,
  cartProductId,
  // TODO: Проверить нах оно вообще))
  minValue = 0,
  product,
}: ProductCountControllerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [count, setCount] = useState<number | ''>(startCount);
  const multiplicityCount = product.multiplicityCount || 1;

  const currentPrice = useCallback(
    () => getCurrentPrice(product, startCount),
    [product, startCount]
  )();

  const nextPriceAndCount = useCallback(
    () => getNextProductCountAndPrice(product, startCount),
    [product, startCount]
  )();

  useEffect(() => {
    setCount(startCount);
  }, [startCount]);

  const handleIncCountProduct = (event: React.SyntheticEvent) => {
    event.stopPropagation();

    if (!isEmpty(product.options)) {
      navigate(`${product.productId}`);
      return;
    }

    dispatch(
      CartActions.incProductInCart({ cartProductId, multiplicityCount })
    );
    setCount((prev) => Number(prev) + multiplicityCount);
  };

  const handleDecCountProduct = (event: React.SyntheticEvent) => {
    event.stopPropagation();

    if (!isEmpty(product.options)) {
      navigate(`${product.productId}`);
      return;
    }

    setCount((prev) => Number(prev) - multiplicityCount);
    if (count === '') {
      return;
    }
    if (count > multiplicityCount) {
      dispatch(
        CartActions.decProductInCart({ cartProductId, multiplicityCount })
      );
      return;
    }
    dispatch(CartActions.deleteProductFromCart(cartProductId));
  };

  const isWhite = useIsWhiteMoreContrast();

  return (
    <div className={style.wrapper}>
      <div
        className={classNames(style.counterWrapper, {
          [style.counterWrapperWhite]: isWhite,
        })}
      >
        <button
          className={classNames(style.button, {
            [style.buttonWhite]: isWhite,
          })}
          onClick={handleDecCountProduct}
          disabled={count === minValue}
        >
          <Iconify
            sx={{
              color: isWhite ? '#000000' : '#ffffff',
            }}
            icon='ant-design:minus-outlined'
          />
        </button>
        <span
          className={classNames(style.currentPrice, {
            [style.currentPriceWhite]: isWhite,
          })}
        >
          {formatAmount(currentPrice)}
        </span>
        <button
          className={classNames(style.button, {
            [style.buttonWhite]: isWhite,
          })}
          onClick={handleIncCountProduct}
        >
          <Iconify
            sx={{
              color: isWhite ? '#000000' : '#ffffff',
            }}
            icon='ant-design:plus-outlined'
          />
        </button>
      </div>
      {nextPriceAndCount && (
        <div className={style.moreTextWrapper}>
          <span
            className={classNames(style.moreText, {
              [style.moreTextWhite]: isWhite,
            })}
          >
            ещё {nextPriceAndCount.nextCount} до{' '}
            {formatAmount(nextPriceAndCount.nextPrice)}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(ProductCount);
