import classNames from 'classnames';
import style from './TaskItem.module.scss';

const TaskItem = ({
  title,
  text,
  isTaskCompleted = false,
}: {
  title: string;
  text: string;
  isTaskCompleted: Boolean;
}) => {
  return (
    <div className={style.wrapper}>
      <h3 className={style.title}>{title}</h3>
      <p className={style.text}>{text}</p>
      <p
        className={classNames(style.accent, {
          [style.accentCompleted]: isTaskCompleted,
        })}
      >
        {isTaskCompleted
          ? 'Задание выполнено'
          : "Награда: + 2 дня на тарифе Оптовый"}
      </p>
    </div>
  );
};

export default TaskItem;
