import cls from './Packages.module.scss';
import { Button, FormControl, FormLabel, Typography } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { PackageDetails } from './PackageDetails/PackageDetails';
import CloseButtonModal from 'components/common/CloseIconModal/CloseIconModal';
import { useAppSelector } from 'redux/hooks';
import { useAppDispatch } from 'redux/hooks';
import { CreateOrderSdekActions } from '../../model/slice/newSdekOrderSlice';
import {
  getPackageError,
  getPackages,
} from '../../model/selectors/newOrderSdekSelectors';
import { getDefaultPackage } from '../../lib/helpers/package';
import { ShowErrorOnPage } from 'shared/ui/ShowErrorOnPage/ShowErrorOnPage';

const Packages = () => {
  const dispatch = useAppDispatch();
  const packages = useAppSelector(getPackages);
  const errors = useAppSelector(getPackageError);

  const handleAddPackage = () => {
    const newPackage = getDefaultPackage();

    dispatch(
      CreateOrderSdekActions.setPackages({
        ...packages,
        ...newPackage,
      })
    );
  };

  const handleDeletePackage = (id: string) => {
    const newPackages = cloneDeep(packages);
    delete newPackages[id];

    dispatch(
      CreateOrderSdekActions.setPackages({
        ...newPackages,
      })
    );
  };

  const packagesList = useMemo(
    () =>
      Object.values(packages).map((packageItem, i) => (
        <div key={packageItem.id} className={cls.PackageWrapper}>
          <Typography variant='h6' component='p' sx={{ mb: '1rem' }}>
            Место № {i + 1}
          </Typography>

          <PackageDetails
            packageItem={packageItem}
            errors={errors?.[i]}
          />

          {i !== 0 && (
            <CloseButtonModal
              ariaLabel='удалить'
              onClose={() => handleDeletePackage(packageItem.id)}
            />
          )}
        </div>
      )),
    [packages, errors]
  );

  return (
    <div className={cls.Packages}>
      <FormControl component='fieldset' variant='standard' sx={{ mt: '2rem' }}>
        <FormLabel id='recipient-group'>Cписок посылок</FormLabel>
      </FormControl>

      {errors && <ShowErrorOnPage errorMessage={errors[0]?.dimensions} />}

      {packagesList}

      <Button
        variant='contained'
        onClick={handleAddPackage}
        className={cls.PackagesBtn}
      >
        Добавить место
      </Button>
    </div>
  );
};

export default Packages;
