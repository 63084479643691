const CartIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
      <g clipPath='url(#a)'>
        <path
          d='M22.125 8.012A1.5 1.5 0 0 0 21 7.5h-3.41l-5.027-5.744a.751.751 0 0 0-1.126 0L6.41 7.5H3a1.5 1.5 0 0 0-1.487 1.698l1.3 9.75A1.5 1.5 0 0 0 4.3 20.25h15.4a1.5 1.5 0 0 0 1.488-1.301l1.3-9.75a1.5 1.5 0 0 0-.363-1.187zM7.65 17.25a.687.687 0 0 1-.076 0 .75.75 0 0 1-.75-.675l-.525-5.25a.75.75 0 1 1 1.493-.15l.525 5.25a.75.75 0 0 1-.667.825zm5.1-.75a.75.75 0 1 1-1.5 0v-5.25a.75.75 0 1 1 1.5 0v5.25zm-4.347-9L12 3.39l3.597 4.11H8.403zm9.293 3.825-.525 5.25a.75.75 0 0 1-.745.675.686.686 0 0 1-.076 0 .75.75 0 0 1-.671-.821l.525-5.25a.75.75 0 1 1 1.492.15v-.004z'
          fill='#fff'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CartIcon;