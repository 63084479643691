import { useEffect, useState } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header/Header';
import Nav from './nav/Nav';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { checkAuthUserAction } from 'redux/UserSlice/asyncActions';
import {
  getUserAuthStatus,
  getUserContactInfoStatus,
} from 'redux/UserSlice/selector';
import { UserAuthStatus } from 'consts';
import {
  fetchAllAnalytics,
  fetchOrdersAnalytics,
} from 'redux/AnalyticsSlice/asyncActions';
import { fetchLinkAction } from 'redux/LinkSlice/asyncActions';
import { fetchOrdersAction } from 'redux/OrdersSlice/asyncActions';
import ContactModal from 'components/ContactModal/ContactModal';
import accessTokenController from 'services/accessTokenController';
import { CarrotModule } from 'entities/Analytic';
import { Helmet } from 'react-helmet-async';


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useSearchParams();
  const authStatus = useAppSelector(getUserAuthStatus);
  const userInfoStatus = useAppSelector(getUserContactInfoStatus);

  useEffect(() => {
    const accessToken = search.get('accessToken');

    if (accessToken) {
      accessTokenController.save(accessToken);
    }
    dispatch(checkAuthUserAction());
  }, [dispatch]);

  useEffect(() => {
    if (authStatus === UserAuthStatus.Authorized) {
      dispatch(fetchAllAnalytics());
      dispatch(fetchLinkAction());
      dispatch(fetchOrdersAction());
      dispatch(fetchOrdersAnalytics());
    }
  }, [authStatus]);

  if (authStatus === UserAuthStatus.Unauthorized) {
    return <Navigate to='/signin' />;
  }

  if (authStatus === UserAuthStatus.Authorized) {
    return (
      <StyledRoot>
        <Helmet>
          {CarrotModule()}
        </Helmet>
        <Header onOpenNav={() => setOpen(true)} />

        <Nav openNav={open} onCloseNav={() => setOpen(false)} />

        <Main>
          <Outlet />
        </Main>
        <ContactModal />
      </StyledRoot>
    );
  }

  return null;
}
