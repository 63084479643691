import isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import style from './ClientCell.module.scss';
import { IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { useAppDispatch } from 'redux/hooks';
import { StockSliceActions } from 'entities/Stock/model/slice/stockSlice';

const ClientCell = ({ cell, row }: any) => {
  const dispatch = useAppDispatch();
  const customerOrderInfo = row.original?.customerOrderInfo;

  if (!customerOrderInfo) {
    return null;
  }

  const { name, surName, fatherName, phone, tgNick } = customerOrderInfo;

  const handleEditButtonClick = () => {
    dispatch(StockSliceActions.setIsStockOrderEditVisible(true));
  };

  return (
    <div className={style.wrapper}>
      <p className={style.name}>{`${surName} ${name} ${fatherName}`}</p>
      <p>
        <a href={`tel:${phone}`}>{phone}</a>&nbsp;
        <a href={`https://t.me/${tgNick}`}>{tgNick}</a>
      </p>
    </div>
  );
};

export default memo(ClientCell);
