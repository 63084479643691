import CustomerLayout from "app/layouts/CustomerLayout/CustomerLayout";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const CustomerOrdersPage = lazy(
  () => import("pages/CustomerOrdersPage/CustomerOrdersPage")
);
const CustomerSettingsPage = lazy(
  () => import("pages/CustomerSettingsPage/CustomerSettingsPage")
);
const CustomerShopsPage = lazy(
  () => import("pages/CustomerShopsPage/CustomerShopsPage")
);

const CustomerRoutes = () => {
  return (
    <Routes>
      <Route element={<CustomerLayout />}>
        <Route path="/orders" element={<CustomerOrdersPage />} />
        <Route path="/shops" element={<CustomerShopsPage />} />
        <Route path="/settings" element={<CustomerSettingsPage />} />
      </Route>
    </Routes>
  );
};

export default CustomerRoutes;
