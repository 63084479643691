import { CreateOrderSdekActions } from 'features/sdek/NewSdekOrder/model/slice/newSdekOrderSlice';
import cls from './PackageDetails.module.scss';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { PackageSettingType } from 'features/sdek/NewSdekOrder/model/types/packages';
import { memo } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { ArrayErrorMessages } from 'utils/validation';

type PackageDetailsProps = {
  packageItem: PackageSettingType;
  errors?: Record<string, string>;
};

export const PackageDetails = memo(
  ({ packageItem, errors }: PackageDetailsProps) => {
    const dispatch = useAppDispatch();

    const { id, description, weight, width, height, length, packageNumber } =
      packageItem;

    const changePackagesField = (
      packageId: string,
      fieldName: keyof PackageSettingType,
      value: string
    ) => {
      dispatch(
        CreateOrderSdekActions.updatePackageField({
          packageId,
          fieldName,
          value,
        })
      );
    };

    return (
      <>
        <div className={cls.PackageDetails}>
          <BaseInput
            label='Вес(кг)'
            fullWidth
            type='number'
            inputProps={{
              inputMode: 'numeric',
              onWheel: (evt) => evt.currentTarget.blur(),
            }}
            value={weight}
            name={`packages.${id}.weight`}
            onChange={(evt) =>
              changePackagesField(id, 'weight', evt.target.value)
            }
            error={Boolean(errors?.weight)}
            helperText={errors?.weight}
          />
          <BaseInput
            label='Длина(см)'
            fullWidth
            type='number'
            inputProps={{
              inputMode: 'numeric',
              onWheel: (evt) => evt.currentTarget.blur(),
            }}
            value={length}
            name={`packages.${id}.length`}
            onChange={(evt) =>
              changePackagesField(id, 'length', evt.target.value)
            }
            error={Boolean(errors?.length)}
            helperText={errors?.length}
          />
          <BaseInput
            label='Ширина(см)'
            fullWidth
            type='number'
            inputProps={{
              inputMode: 'numeric',
              onWheel: (evt) => evt.currentTarget.blur(),
            }}
            value={width}
            name={`packages.${id}.width`}
            onChange={(evt) =>
              changePackagesField(id, 'width', evt.target.value)
            }
            error={Boolean(errors?.width)}
            helperText={errors?.width}
          />
          <BaseInput
            label='Высота(см)'
            fullWidth
            type='number'
            inputProps={{
              inputMode: 'numeric',
              onWheel: (evt) => evt.currentTarget.blur(),
            }}
            value={height}
            name={`packages.${id}.height`}
            onChange={(evt) =>
              changePackagesField(id, 'height', evt.target.value)
            }
            error={Boolean(errors?.height)}
            helperText={errors?.height}
          />
        </div>
        <div className={cls.descriptionWrapper}>
          <BaseInput
            label='Описание груза'
            fullWidth
            name={`packages.${id}.description`}
            value={description}
            onChange={(evt) =>
              changePackagesField(id, 'description', evt.target.value)
            }
            error={Boolean(errors?.description)}
            helperText={errors?.description}
          />
          <BaseInput
            label='Номер упаковки'
            fullWidth
            value={packageNumber}
            name={`packages.${id}.packageNumber`}
            onChange={(evt) =>
              changePackagesField(id, 'packageNumber', evt.target.value)
            }
            error={Boolean(errors?.packageNumber)}
            helperText={errors?.packageNumber}
          />
        </div>
      </>
    );
  }
);
