export interface TariffInfoI {
  id: number;
  title: string;
  price: number;
  term: number;
  maxLinkCount: number;
  maxProductCount: number;
  isTelegramNotificationAvailable: boolean;
  isSEOInstrumentsAvailable: boolean;
  isTelegramIntegrationAvailable: boolean;
  isWhiteLableAvailable: boolean;
  isPremiumModulesAvailable: boolean;
  endDate: Date;
  isPaidTariff: boolean;
}

export enum TariffTheme {
  Grey = 'grey',
  Blue = 'blue',
  Yellow = 'yellow',
  Orange = 'orange',
}

export const TariffThemeByTitle = {
  'Базовый': 'grey',
  'Стандарт': 'blue',
  'Бизнес': 'yellow',
  'Оптовый': 'orange',
}

export const TranslatedTariffName = {
  BASE: 'Базовый',
  STANDART: 'Стандарт',
  BUSINESS: 'Бизнес',
  WHOLESALE: 'Оптовый',
}

export enum PaymentPeriod {
    Monthly = 'Monthly',
    Quarter = 'Quarter',
    HalfYear = 'HalfYear',
    Yearly = 'Yearly',
  }