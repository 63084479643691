import debounce from 'lodash/debounce';
import { featchAddress } from '../model/services/featchAddress';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { IAutocomplitingAddress } from 'features/sdek/NewSdekOrder/model/types/location';
import { apiErrorHandler } from 'services/apiErrorHandler';

interface AddressInputProps {
  label: string;
  id: string;
  value: string;
  setLocation: (location: IAutocomplitingAddress) => void;
  errorMessage?: string;
  onlyCities?: boolean;
  className?: string;
}

export const AddressInput = ({
  label,
  id,
  value,
  setLocation,
  errorMessage,
  onlyCities,
  className,
}: AddressInputProps) => {
  const [options, setOptions] = useState<IAutocomplitingAddress[]>([]);
  const [error, setError] = useState('');

  const onChangeAdressDebounce = debounce(onChangeInput, 300);

  async function onChangeInput(
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    try {
      const data = await featchAddress(evt.target.value, onlyCities);

      setOptions(data);
    } catch (error) {
      const { message } = apiErrorHandler(error);
      setError(message);
    }
  }

  function onChangeAutocomplete(
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) {
    const location = options.find((el) => el.value === value);
    if (!location) {
      return;
    }
    setLocation(location);
  }

  const onChangeBaseInput = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChangeAdressDebounce(evt);
  };

  return (
    <Autocomplete
      fullWidth
      disablePortal
      id={id}
      options={options.map((el) => el.value)}
      filterOptions={(options) => options}
      value={value}
      className={className}
      noOptionsText={error}
      onChange={onChangeAutocomplete}
      renderInput={(params) => (
        <BaseInput
          {...params}
          name={id}
          label={label}
          fullWidth
          onChange={onChangeBaseInput}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
      )}
    />
  );
};
