import { Box } from '@mui/material';
import BasicModal from 'components/common/BasicModal/BasicModal';
import { ProductReviewCard, getCurrentReview } from 'entities/Reviews';
import { setCurrentReview } from 'entities/Reviews';
import { ReviewReplyForm } from 'features/ReviewReply';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export const ReviewModal = () => {
  const review = useAppSelector(getCurrentReview);
  const dispatch = useAppDispatch();

  if (!review) {
    return null;
  }

  return (
    <BasicModal
      open={Boolean(review)}
      onClose={() => {
        dispatch(setCurrentReview(null));
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingBlock: '40px',
        }}
      >
        <ProductReviewCard data={review} />

        <ReviewReplyForm />
      </Box>
    </BasicModal>
  );
};
