export const getReviewWord = (count: number): string => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;
  
    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return `${count} отзыв`;
    } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
      return `${count} отзыва`;
    } else {
      return `${count} отзывов`;
    }
  }