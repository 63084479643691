import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ThemeProvider from "./theme";

import { TailSpin } from "react-loader-spinner";
import CustomerRoutes from "Routes/CustomerRoutes";
import SellerRoutes from "Routes/SellerRoutes";
import CustomLinkRoutes from "Routes/CustomLinkRoutes";
import AdminRoutes from "Routes/AdminRoutes";

const FAQPage = lazy(() => import("pages/FAQPage/FAQPage"));
const OfferPage = lazy(() => import("pages/OfferPage"));
const AgreementPage = lazy(() => import("pages/AgreementPage"));
const PersonDataPage = lazy(() => import("pages/PersonDataPage"));
const CookiePage = lazy(() => import("pages/CookiePage"));
const ContactsPage = lazy(() => import("pages/ContactsPage/ContactsPage"));
const SignUpPage = lazy(() => import("pages/SignUpPage/SignUpPage"));
const SignInPage = lazy(() => import("pages/SignInPage/SignInPage"));
const Page404 = lazy(() => import("pages/Page404"));
const MainPage = lazy(() => import("pages/MainPage/MainPage"));
const VerificationPage = lazy(
  () => import("pages/VerificationPage/VerificationPage")
);
const ResetPasswordPhonePage = lazy(
  () => import("pages/ResetPasswordPhonePage/ResetPasswordPhonePage")
);
const ResetPasswordEmailPage = lazy(
  () => import("pages/ResetPasswordEmailPage/ResetPasswordEmailPage")
);
const NewPasswordPage = lazy(
  () => import("pages/NewPasswordPage/NewPasswordPage")
);
const MarketingTochkaPdfPage = lazy(() => import("pages/MarketingTochkaPdfPage"));

const Router = () => {
  return (
    <ThemeProvider>
      <Suspense
        fallback={
          <TailSpin
            color="#2274a5"
            wrapperStyle={{ margin: "30vw", justifyContent: "center" }}
          />
        }
      >
        <Routes>
          <Route path="/" element={<MainPage />} />

          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />

          <Route
            path="/reset-password-email"
            element={<ResetPasswordEmailPage />}
          />
          <Route
            path="/reset-password-phone"
            element={<ResetPasswordPhonePage />}
          />
          <Route path="/new-password" element={<NewPasswordPage />} />
          <Route path="/verification" element={<VerificationPage />} />

          <Route path="/customer/*" element={<CustomerRoutes />} />
          <Route path="/dashboard/*" element={<SellerRoutes />} />
          <Route path="/:linkId/*" element={<CustomLinkRoutes />} />
          <Route path="/admin/*" element={<AdminRoutes />} />

          <Route path="/faq" element={<FAQPage />} />
          <Route path="/offer" element={<OfferPage />} />
          <Route path="/agreement" element={<AgreementPage />} />
          <Route path="/person" element={<PersonDataPage />} />
          <Route path="/cookie" element={<CookiePage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/marketing/tochka" element={<MarketingTochkaPdfPage />} />


          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
};

export default Router;
