import { memo } from 'react';

const ListIcon = ({ style }: { style: any }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='26'
      viewBox='0 0 27 26'
      style={style}
    >
      <rect
        x='0.613281'
        y='14.1248'
        width='25.7931'
        height='11.6683'
        rx='4.125'
        fill={style.fill}
      />
      <rect rx='4.125' x='0.613281' width='25.7931' height='11.6683' fill={style.fill} />
    </svg>
  );
};

export default memo(ListIcon);
