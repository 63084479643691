import { memo } from 'react';

const ReviewsIcon = ({ fill }: { fill: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' fill={fill}>
      <g opacity='.3' clipPath='url(#a)'>
        <path
          d='M19 11.498A7.501 7.501 0 0 1 7.975 18.12l-2.456.819a1.154 1.154 0 0 1-1.46-1.46l.82-2.456a7.502 7.502 0 0 1 8.178-10.86A7.5 7.5 0 0 1 19 11.498z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill={fill} d='M0 0h23v23H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(ReviewsIcon);
