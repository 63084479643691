import { OptionsOrderType } from './../../types/order';
import { createSlice } from '@reduxjs/toolkit';
import { ProductCardType } from 'types/products';

export interface DetailProductPageSliceI {
  isLoading: boolean;
  productData: ProductCardType | null;
  selectedProductOptions: OptionsOrderType | null,
}

const initialState: DetailProductPageSliceI = {
  isLoading: false,
  productData: null,
  selectedProductOptions: null,
};

const DetailProductPageSlice = createSlice({
  name: 'DetailProductPageSlice',
  initialState,
  reducers: {
    setDetailProductPageLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    setProductData: (state, action) => {
      state.productData = action.payload;
    },
    setSelectedProductOptions: (state, action) => {
        state.selectedProductOptions = action.payload;
    }
  },
});

export default DetailProductPageSlice;

export const { setDetailProductPageLoadingStatus, setProductData, setSelectedProductOptions } =
  DetailProductPageSlice.actions;
