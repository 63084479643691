import { styled, alpha } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import NotificationsPopover from "./NotificationsPopover";
import { useAppSelector } from "redux/hooks";
import { getUserId, getUserIsByAdmin } from "redux/UserSlice/selector";
import Alert from "@mui/material/Alert";
import Iconify from "components/common/Iconify/Iconify";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  backdropFilter: `blur(6px)`,
  WebkitBackdropFilter: `blur(6px)`,
  backgroundColor: alpha("#F9FAFB", 0.8),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`
  },
}));

// ----------------------------------------------------------------------

interface IHeader {
  onOpenNav: () => void;
}

export default function Header({ onOpenNav }: IHeader) {
  const userId = useAppSelector(getUserId);
  const isByAdmin = useAppSelector(getUserIsByAdmin);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        {isByAdmin && <Alert severity="warning">
          {`Вы вошли как пользователь ${userId}`}
        </Alert>}
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover /> */}
          <NotificationsPopover />
          {/* <AccountPopover /> */}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
