import { ApiAxiosError } from './../../services/apiInstance';
import { SdekAvailableStatus, UserAuthStatus, UserRole } from 'consts';
import { ApiRoutes } from './../../consts/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  resetUserError,
  setOwnTgBotStatus,
  setTgBotError,
  setUserAuthStatus,
  setUserContactInfoStatus,
  setUserEmail,
  setUserId,
  setUserIsByAdmin,
  setUserIsSDEKAvailable,
  setUserLoading,
  setUserRole,
  setUserTariff,
  setUserTelegramData,
} from './UserSlice';
import {
  TokenDataType,
  UserLoginDataType,
  UserSignUpDataType,
} from 'types/user';
import accessTokenController from 'services/accessTokenController';
import jwtDecode from 'jwt-decode';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { refresh } from 'services/apiInstance';
import { showErrorNotification, showSuccessNotification } from 'utils/notification';

export const checkAuthUserAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>('user/checkAuthUser', async (_args, { dispatch }) => {
  dispatch(resetUserError());
  try {
    const { accessToken } = await refresh();
    
    if (!accessToken) {
      dispatch(setUserAuthStatus(UserAuthStatus.Unauthorized));
      return;
    }

    const userData = accessTokenController.decodeToken(accessToken);

    if (!userData) {
      showErrorNotification(['Не удалось авторизовать пользователя']);
      return;
    }
    const { role, email, userId, isByAdmin, isSDEKAvailable, tariffData } =
      userData;

    if (accessToken && (role === UserRole.User || role === UserRole.Admin)) {
      dispatch(setUserId(userId));
      dispatch(setUserRole(role));
      dispatch(setUserEmail(email));
      dispatch(setUserAuthStatus(UserAuthStatus.Authorized));
      dispatch(setUserIsByAdmin(isByAdmin));
      dispatch(setUserTariff(tariffData));
      dispatch(
        setUserIsSDEKAvailable(
          isSDEKAvailable
            ? SdekAvailableStatus.IsAvailable
            : SdekAvailableStatus.NotAvailable
        )
      );
    } else {
      dispatch(setUserAuthStatus(UserAuthStatus.Unauthorized));
    }
  } catch (error: unknown) {
    dispatch(setUserAuthStatus(UserAuthStatus.Unauthorized));
    dispatch(setUserLoading(false));
    showErrorNotification([(error as ApiAxiosError).errorData.message]);
  }
});

export const logoutAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>('user/logout', async (_args, { dispatch, extra: {api} }) => {
  dispatch(resetUserError());
  try {
    dispatch(setUserId(''));
    dispatch(setUserRole(UserRole.Unknowe));
    dispatch(setUserEmail(''));
    dispatch(setUserAuthStatus(UserAuthStatus.Unauthorized));
    dispatch(setUserIsByAdmin(false));

    accessTokenController.logout();
    await api.put(ApiRoutes.Logout);
    
  } catch (error: unknown) {
    dispatch(setUserLoading(false));
    showErrorNotification([(error as ApiAxiosError).errorData.message]);
  }
});

export const loginAction = createAsyncThunk<
  void,
  { userLogin: UserLoginDataType; captchaToken: string },
  ThunkConfig<string>
>(
  'user/login',
  async ({ userLogin, captchaToken }, { dispatch, extra: { api } }) => {
    dispatch(setUserLoading(true));
    dispatch(resetUserError());
    try {
      const { data } = await api.post(ApiRoutes.Login, {
        userLogin: userLogin.email,
        userPassword: userLogin.password,
        captchaToken,
      });

      if (data.accessToken && data.status) {
        const { accessToken, analyticHash } = data;

        accessTokenController.save(accessToken);
        const user: TokenDataType = jwtDecode(accessToken);

        dispatch(setUserId(user.userId));
        dispatch(setUserRole(user.role));
        dispatch(setUserEmail(user.email));
        dispatch(setUserAuthStatus(UserAuthStatus.Authorized));
        dispatch(setUserContactInfoStatus(user.contactInfoStatus === true));
        dispatch(setUserTariff(user.tariffData));
        dispatch(setUserLoading(false));
        dispatch(
          setUserIsSDEKAvailable(
            user.isSDEKAvailable
              ? SdekAvailableStatus.IsAvailable
              : SdekAvailableStatus.NotAvailable
          )
        );

        // @ts-ignore
        window.carrotquest.auth(user.userId, analyticHash);

        // @ts-ignore
        window.carrotquest.identify({
          '$email': user.email,
          'Тариф': user.tariffData.title,
          'Срок тарифа': user.tariffData.term,
          'Дата окончания тарифа': user.tariffData.endDate
        })
      }
    } catch (error: unknown) {
      dispatch(setUserLoading(false));
      showErrorNotification([(error as ApiAxiosError).errorData.message]);
    }
  }
);

export const registrationAction = createAsyncThunk<
  void,
  UserSignUpDataType,
  ThunkConfig<string>
>(
  'user/registration',
  async ({ email, captchaToken }, { dispatch, extra: { api } }) => {
    dispatch(setUserLoading(true));
    dispatch(resetUserError());
    try {
      const { data } = await api.post(ApiRoutes.Registration, { email, captchaToken });

      if (data.accessToken && data.status) {
        const { accessToken } = data;
        accessTokenController.save(accessToken);
        const user: TokenDataType = jwtDecode(accessToken);

        dispatch(setUserId(user.userId));
        dispatch(setUserRole(user.role));
        dispatch(setUserEmail(user.email));
        dispatch(setUserAuthStatus(UserAuthStatus.Authorized));
        dispatch(setUserContactInfoStatus(user.contactInfoStatus === true));
        dispatch(setUserTariff(user.tariffData));
        dispatch(setUserLoading(false));
        dispatch(
          setUserIsSDEKAvailable(
            user.isSDEKAvailable
              ? SdekAvailableStatus.IsAvailable
              : SdekAvailableStatus.NotAvailable
          )
        );

        window.open('/dashboard/onboarding', '_self');
      }

      dispatch(setUserLoading(false));
    } catch (error: unknown) {
      dispatch(setUserLoading(false));
      showErrorNotification([(error as ApiAxiosError).errorData.message]);
    }
  }
);

export const fetchTelegramVerificationCodeAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>(
  'user/fetchTelegramVerificationCode',
  async (_args, { dispatch, extra: { api } }) => {
    dispatch(setUserLoading(true));
    dispatch(resetUserError());
    try {
      const { data } = await api.get(ApiRoutes.User);
      if (data) {
        dispatch(
          setUserTelegramData({
            telegramVerificationCode: data.telegramVerificationCode,
            telegramId: data.telegramId,
          })
        );
        dispatch(setUserLoading(false));
      }
    } catch (error) {
      dispatch(setUserLoading(false));
      showErrorNotification([(error as ApiAxiosError).errorData.message]);
    }
  }
);

export const addUserTgBot = createAsyncThunk<
  void,
  { userTgBotToken: string },
  ThunkConfig<string>
>("user/addUserTgBot", async ({ userTgBotToken }, { dispatch, extra: { api } }) => {
  dispatch(setUserLoading(true));
  dispatch(resetUserError());
  try {
    const { data } = await api.post(ApiRoutes.UserBot, {
      tokenBot: userTgBotToken,
    });
    if (data) {
      dispatch(setOwnTgBotStatus(true));

      showSuccessNotification(['Бот успешно добавлен']);

      if (data.isTelegramNotification) {
        showSuccessNotification(['Ура, подключен первый бот. Начислили бонус ;)']);
      }
    }
    dispatch(setUserLoading(false));
  } catch (error) {
    dispatch(setUserLoading(false));
    const errorMessage = (error as ApiAxiosError).errorData.message;
    dispatch(setTgBotError(errorMessage));
    showErrorNotification([(error as ApiAxiosError).errorData.message]);
  }
});

export const fetchUserTgBot = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("user/fetchUserTgBot", async (_args, { dispatch, extra: { api } }) => {
  dispatch(setUserLoading(true));
  dispatch(resetUserError());
  try {
    const { data } = await api.get(ApiRoutes.UserBot);
    if (data) {
      dispatch(setOwnTgBotStatus(data.userBotStatus));
    }
    dispatch(setUserLoading(false));
  } catch (error) {
    dispatch(setUserLoading(false));
    showErrorNotification([(error as ApiAxiosError).errorData.message]);
  }
});

export const deleteUserTgBot = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("user/deleteUserTgBot", async (_args, { dispatch, extra: { api } }) => {
  dispatch(setUserLoading(true));
  dispatch(resetUserError());
  try {
    const { data } = await api.delete(ApiRoutes.UserBot);
    if (data) {
      dispatch(setOwnTgBotStatus(false));
    }
    dispatch(setUserLoading(false));
  } catch (error) {
    dispatch(setUserLoading(false));
    showErrorNotification([(error as ApiAxiosError).errorData.message]);
  }
});

export const resyncBot = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("user/resyncBot", async (_args, { dispatch, extra: { api } }) => {
  dispatch(setUserLoading(true));
  dispatch(resetUserError());
  try {
    await api.put(ApiRoutes.UserBot);
    showSuccessNotification(['Бот успено синхронизирован'])
    dispatch(setUserLoading(false));
  } catch (error) {
    dispatch(setUserLoading(false));
    showErrorNotification([(error as ApiAxiosError).errorData.message]);
  }
});
