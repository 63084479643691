import { Stack } from '@mui/material';
import CartStepper from './CartSteppert/CartStepper';
import { Button } from '@mui/material';
import CartSummary from './CartSummary/CartSummary';
import CartLinkBack from './CartLinkBack/CartLinkBack';
import { useState, useEffect } from 'react';
import CartSwitchBlock from './CartSwitchBlock/CartSwitchBlock';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { LoadingButton } from '@mui/lab';
import ShoweRequestErrorAlert from 'components/ShoweRequestErrorAlert/ShoweRequestErrorAlert';
import { useParams } from 'react-router-dom';
import api from 'services/api';

import { apiErrorHandler } from 'services/apiErrorHandler';
import CartSuccessOrderDialog from './CartSuccessOrderDialog/CartSuccesOrderDialog';
import useAsyncReducer, {
  ReducersList,
} from 'shared/lib/hooks/useAsyncReducer';
import {
  CartActions,
  CartSlice,
  CheckCartSellerAction,
  getCartAddress,
  getCartClientData,
  getCartPoint,
  getCartProducts,
  getCartSellerId,
} from 'entities/Cart';

const reducers: ReducersList = {
  CartSlice: CartSlice.reducer,
};

const Cart = () => {
  useAsyncReducer({ reducers, removeAfterUnmount: false });
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const clientData = useAppSelector(getCartClientData);
  const products = useAppSelector(getCartProducts);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const sellerId = useAppSelector(getCartSellerId);
  const { linkId } = useParams();
  const point = useAppSelector(getCartPoint);
  const address = useAppSelector(getCartAddress);

  //Маркер для определения нажатия кнопки далее
  const [cartDeliveryFormGoNext, setCartDeliveryFormGoNext] = useState(0);

  useEffect(() => {
    dispatch(CheckCartSellerAction());
  }, []);

  const handleNext = () => {
    if (activeStep === 1) {
      setCartDeliveryFormGoNext((prev) => prev + 1);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClearCart = () => {
    setIsSuccessRequest(false);
    dispatch(CartActions.setCart({}));
    dispatch(CartActions.setCartClientData(null));
  };

  const handleSendCartData = async () => {
    if (!products || !clientData || !linkId) {
      setError('Неверно заполнены данные');

      return;
    }
    setIsLoading(true);

    api
      .sendCart({ products, clientData, sellerId, linkId, point, address })
      .then((data) => {
        if (data) {
          setIsLoading(false);
          setIsSuccessRequest(true);
        }
      })
      .catch((error) => {
        const { status, message } = apiErrorHandler(error);
        setError(`${status} ${message}`);
        setIsLoading(false);

        setTimeout(() => {
          setError('');
        }, 3000);
      });

    return;
  };

  return (
    <>
      <Stack className='cart-main__products-wrapper'>
        <Stack className='cart-main__products-list'>
          <Stack className='cart-stepper-wrapper'>
            <CartStepper activeStep={activeStep} />
          </Stack>
          <CartSwitchBlock
            activeStep={activeStep}
            cartDeliveryFormGoNext={cartDeliveryFormGoNext}
            setActiveStep={setActiveStep}
          />
        </Stack>

        <Stack className='cart-main__order-summary-wrapper'>
          <CartLinkBack activeStep={activeStep} handleBack={handleBack} />
          <CartSummary currency={products[0]?.currency ?? 'rub'} />

          {activeStep < 2 ? (
            <Button
              variant='contained'
              color='info'
              sx={{ color: '#fff', width: '100%' }}
              onClick={handleNext}
              disabled={!products.length}
            >
              Далее
            </Button>
          ) : (
            <LoadingButton
              variant='contained'
              color='info'
              sx={{
                color: '#fff',
                width: '100%',
                display: isSuccessRequest ? 'none' : 'inherit',
              }}
              onClick={handleSendCartData}
              disabled={activeStep >= 1 && !clientData}
              loading={isLoading}
            >
              Подтвердить
            </LoadingButton>
          )}
        </Stack>
      </Stack>

      {error && <ShoweRequestErrorAlert error={error} />}

      <CartSuccessOrderDialog
        open={isSuccessRequest}
        linkId={linkId}
        onClose={handleClearCart}
      />
    </>
  );
};

export default Cart;
