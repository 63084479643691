import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { memo, useEffect, useState } from 'react';

import { Modal } from 'shared/ui/Modal/Modal';
import { DeleteProductModalI } from '../../lib/types';
import style from './DeleteProductModal.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { Button } from '@mui/material';

import { showErrorNotification } from 'utils/notification';
import { deleteOrderProduct } from '../../model/services/deleteOrderProduct';

const DeleteProductModal = ({
  handleModalClose,
  isOpen,
}: DeleteProductModalI) => {
  const dispatch = useAppDispatch();

  const handleDeleteProductClick = async () => {
    try {
      dispatch(deleteOrderProduct());
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        // Если ошибка является ошибкой валидации от yup, показываем уведомление с ошибками
        showErrorNotification(error.errors);
      } else {
        // Обработка других типов ошибок
        showErrorNotification(['Произошла ошибка при удалении товара']);
      }
    }
  };

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <div className={style.wrapper}>
        <p className={style.header}>
          Вы уверены, что хотите удалить товар из заказа?
        </p>

        <Button
          sx={{
            mt: '10px',
          }}
          fullWidth
          color='error'
          variant='contained'
          onClick={handleDeleteProductClick}
        >
          Удалить
        </Button>
      </div>
    </Modal>
  );
};

export default memo(DeleteProductModal);
