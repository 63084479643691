import { memo } from 'react';
import cls from './ZeroBlock.module.scss';

interface ZeroBlockProps {
  content: string;
}

export const ZeroBlock = memo(({ content }: ZeroBlockProps) => {
  
  return (
    <section className={cls.ZeroBlock}>
      <div>
        <iframe
          sandbox='allow-same-origin allow-scripts'
          loading='lazy'
          width={'100%'}
          height={500}
          style={{ border: 'none' }}
          srcDoc={content}
        ></iframe>
      </div>
    </section>
  );
});
