import style from './OnboardingPage.module.scss';
import ProgressBar from './ProgressBar/ProgressBar';
import OnboardingTaskList from './OnboardingTaskList/OnboardingTaskList';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getUserOnboardingStatus } from '../model/services/getUserOnboardingStatus';
import { getIsOnboardingLoading } from '../model/selectors/onboardingSelectors';
import { Skeleton } from '@mui/material';

const OnboardingPage = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsOnboardingLoading);

  useEffect(() => {
    dispatch(getUserOnboardingStatus());
  }, []);

  return (
    <div className={style.wrapper}>
      <p className={style.header}>Обучение</p>
      {isLoading ? (
        <Skeleton variant='rectangular' height={400} />
      ) : (
        <>
          <ProgressBar />
          <OnboardingTaskList />
        </>
      )}
    </div>
  );
};

export default OnboardingPage;
