import { TableCell, TableHead, TableRow } from "@mui/material";

const CartTableHeadShow = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ width: "30%" }}
          className="cart-main__product-header-field"
        >
          Товар
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field">
          Цена
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field">
          Количество
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field">
          Опции
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field">
          Полная стоимость
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CartTableHeadShow;
