import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';

import styles from './PriceFilter.module.scss';

import classNames from 'classnames';
import useSyncSearchWithUrl from 'entities/CustomLink/hooks/useSyncSearchWithURL';
import { sortPriceValues } from 'entities/CustomLink/lib/types';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

interface PriceFilterPropsI {
  classname?: string;
}

const PriceFilter = ({ classname }: PriceFilterPropsI) => {
  const { sortTypeValue, updateSort } = useSyncSearchWithUrl();

  const isWhite = useIsWhiteMoreContrast();
  
  return (
    <Select
      className={classNames(styles.select, {
        [styles.selectWhite]: isWhite,
      }, classname)}
      value={sortTypeValue}
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: isWhite ? 'rgba(0,0,0, 0.3) !important' : 'rgba(255,255,255, 0.3) !important',
        },
        '.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: isWhite ? 'rgba(0,0,0, 0.3) !important' : 'rgba(255,255,255, 0.3) !important',
        },
      }}
      onChange={(evt) => updateSort(evt.target.value)}
    >
      {sortPriceValues.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PriceFilter;
