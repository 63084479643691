import { Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import IconBack from "assets/images/arrow-icon.svg";
import Cookies from "js-cookie";

type CartLinkBackProps = {
  handleBack: () => void;
  activeStep: number;
};

const svgIcon = () => (
  <Icon>
    <img src={IconBack} />
  </Icon>
);

const CartLinkBack = ({ handleBack, activeStep }: CartLinkBackProps) => {
  const { linkId } = useParams();
  const cookiesLastLinkId = Cookies.get("cookiesLastLinkId")
  const resultLinkId = cookiesLastLinkId ? cookiesLastLinkId : linkId

  if (activeStep > 0) {
    return (
      <Button
        className="cart__link-back"
        variant="text"
        onClick={handleBack}
        startIcon={svgIcon()}
      >
        Назад
      </Button>
    );
  }

  return (
    <Button
      startIcon={svgIcon()}
      component={Link}
      className="cart__link-back"
      to={`/${resultLinkId}`}
    >
      Продолжить покупки
    </Button>
  );
};

export default CartLinkBack;
