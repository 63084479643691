import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  resetReviewError,
  setReviewError,
  setReviewLoadingStatus,
} from "./ReviewSlice";
import { apiErrorHandler } from "services/apiErrorHandler";
import { setReviewsList } from "./ReviewSlice";
import { ThunkConfig } from "app/providers/StoreProvider";
import { ApiRoutes } from "consts";

export const fetchSellerReviews = createAsyncThunk<
  void,
  { page?: string; size?: string; search?: string },
  ThunkConfig<string>
>(
  "review/fetchSellerReviews",
  async ({ page = 0, size = 10, search }, { dispatch,extra: { api } }) => {
    dispatch(setReviewLoadingStatus(true));
    dispatch(resetReviewError());
    try {
      const { data } = await api.get(ApiRoutes.Review, {
        params: { page, size, search },
      });

      if (data) {
        dispatch(setReviewsList(data.rows));
        dispatch(setReviewLoadingStatus(false));
      }
    } catch (error) {
      dispatch(setReviewLoadingStatus(false));
      const { message, status } = apiErrorHandler(error);
      dispatch(setReviewError(`${status} ${message}`));
      setTimeout(() => {
        dispatch(resetReviewError());
      }, 3000);
    }
  }
);
