import { useAppSelector } from "redux/hooks";
import getSymbolFromCurrency from "currency-symbol-map";
import { getSummaryPrice } from "entities/Cart";

interface CartSummaryProps {
  currency: string;
}

const CartSummary = ({currency}: CartSummaryProps) => {
  const sum = useAppSelector(getSummaryPrice);

  return (
      <div className="cart-main__sum">
        <p>Сумма заказа:  <span>{`${Math.ceil(sum)} ${getSymbolFromCurrency(currency)}`}</span></p>
      </div>
  );
};

export default CartSummary;
