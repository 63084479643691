import { Box } from "@mui/material";
import { memo, useCallback } from "react";

import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import InstagramIcon from "@mui/icons-material/Instagram";
import VKIcon from "components/VKIcon/VKIcon";
import { SocialLinkType, SocialSourceType } from "types/links";
import "./socialLink.scss";

type SocialLinksItemProps = {
  url: string;
  type: SocialSourceType;
};

const SocialLinks = ({ type, url }: SocialLinksItemProps) => {
  if (!url) {
    return null;
  }

  const getCurrentIcon = () => {
    switch (type) {
      case SocialSourceType.email:
        return <EmailIcon />;

      case SocialSourceType.whatsApp:
        return <WhatsAppIcon />;

      case SocialSourceType.vk:
        return <VKIcon />;

      case SocialSourceType.phone:
        return <PhoneIphoneIcon />;

      case SocialSourceType.youtube:
        return <YouTubeIcon />;

      case SocialSourceType.telegram:
        return <TelegramIcon />;

      case SocialSourceType.instagram:
        return <InstagramIcon />;

      default:
        return <EmailIcon />;
    }
  };

  const currentIcon = useCallback(() => getCurrentIcon(), [url]);

  // toDo переделать с прокидывание _blank параметра
  // if mail
  if (type === SocialSourceType.email) {
    return (
      <Box component="a" href={`mailto:${url}`} className="social-link">
        {currentIcon()}
      </Box>
    );
  }

  //if tel
  if (type === SocialSourceType.phone) {
    return (
      <Box component="a" href={`tel:${url}`} className="social-link">
        {currentIcon()}
      </Box>
    );
  }

  return (
    <Box
      component="a"
      href={url}
      target="_blank"
      rel="noreferrer"
      className="social-link"
      sx={{ marginRight: "3px" }}
    >
      {currentIcon()}
    </Box>
  );
};

export default memo(SocialLinks);
