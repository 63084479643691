import { StateSchema } from "app/providers/StoreProvider";
import { ReviewType } from "entities/Reviews";

export const getReviewErrorMessage = (state: StateSchema): string =>
  state.ReviewSlice.error;

export const getReviewProductId = (state: StateSchema): string =>
  state.ReviewSlice.productId;

export const getCurrentReview = (state: StateSchema): ReviewType | null =>
  state.ReviewSlice.currentReview;

export const getReviewsList = (state: StateSchema): ReviewType[] =>
  state.ReviewSlice.reviewsList;

  export const getAllReviewsCount = (state: StateSchema): Number =>
  state.ReviewSlice.reviewsList.length;

export const getReviewLoadingStatus = (state: StateSchema): boolean =>
  state.ReviewSlice.isLoading;
