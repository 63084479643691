import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "app/providers/StoreProvider";
import { setCart, setSellerId } from "../slice/cartSlice";

export const CheckCartAction = createAsyncThunk<
  void,
  { sellerId: string },
  ThunkConfig<string>
>("cart/SendCart", async ({ sellerId }, { dispatch }) => {
  dispatch(setSellerId(sellerId));
  const products = JSON.parse(localStorage.getItem(sellerId) as string);
  
  if (products) {
    dispatch(setCart(products));
  }
});

export const CheckCartSellerAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("cart/CheckCartSeller", async (_args, { dispatch }) => {
  const sellerId = localStorage.getItem("sellerId") as string;

  if (sellerId) {
    dispatch(setSellerId(sellerId));
    dispatch(CheckCartAction({ sellerId }));
  }
});
