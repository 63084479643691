import cls from './VerifiedPopoverContent.module.scss';

export const VerifiedPopoverContent = () => {
  return (
    <>
      <p className={cls.VerifiedPopoverContent}>
        Данный магазин прошел проверку и&nbsp;доказал, что&nbsp;имеет реальный
        товар, не&nbsp;менее 10&nbsp;&laquo;живых&raquo; отзывов,
        а&nbsp;владелец подтвердил паспортные данные{' '}
      </p>
      <a href='https://wiki.linkwi.ru/ru/verivication' className={cls.Link}>
        Подробнее о проверки магазинов
      </a>
    </>
  );
};
