import { OrderDashboardDailyItemI } from '../../lib/types';

export const transformDataForChart = (data: OrderDashboardDailyItemI[]) => {
  const CHART_LABELS = data.map((item) => item.date);

  const CHART_DATA = [
    {
      name: 'Продажи',
      type: 'line', // Линейный график для Total Sales
      fill: 'solid',
      data: data.map((item) => item.totalSales),
    },
    {
      name: 'Закупка товара',
      type: 'line', // Линейный график для Total Cost
      fill: 'solid',
      data: data.map((item) => item.totalCost),
    },
    {
      name: 'Кол-во заказов',
      type: 'column', // Вертикальные бары только для Orders Count
      fill: 'solid',
      data: data.map((item) => item.ordersCount),
    },
  ];

  return { CHART_LABELS, CHART_DATA };
};
