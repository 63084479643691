import { createSlice } from "@reduxjs/toolkit";
import { UsersObjType, UserType } from "types/user";

export type UserPageState = {
  users: UsersObjType;
  currentUser: UserType | null;
  isLoading: boolean;
  errorMessage: string;
};

const initialState: UserPageState = {
  users: {},
  currentUser: null,
  isLoading: false,
  errorMessage: "",
};

const usersPageSlice = createSlice({
  name: "usersPage",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    updateUser: (state, action) => {
      state.users[action.payload.userId] = action.payload;
    },
    updateCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setUsersLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUsersError: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetUsersError(state) {
      state.errorMessage = "";
    },
  },
});

export const {
  setUsers,
  setUsersLoading,
  setUsersError,
  resetUsersError,
  setCurrentUser,
  updateUser,
  updateCurrentUser
} = usersPageSlice.actions;

export default usersPageSlice;
