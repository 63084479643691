import Typography from "@mui/material/Typography";
import { OptionsOrderProduct } from "types/order";

type ProductOptionsPropsType = {
  optionList: OptionsOrderProduct[];
};
const ProductOptionsString = (props: ProductOptionsPropsType) => {
  const { optionList } = props;  
  const optionListString = optionList.map((option) => option.value).join(", ");
  return optionList.length === 0 ? null : (
    <Typography>{`${optionListString}`}</Typography>
  );
};

export default ProductOptionsString;
