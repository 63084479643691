import { Box, TableCell, TableRow, Link } from '@mui/material';
import ProductOptionsString from 'components/Products/ProductOptions/ProductOptionsString';
import { CartProductCardType } from 'types/products';
import getSymbolFromCurrency from 'currency-symbol-map';

type CartProductListProps = {
  products: CartProductCardType[];
};

const CartProductsListShow = ({ products }: CartProductListProps) => {
  return (
    <>
      {products.map((product) => (
        <TableRow
          key={product.cartProductId}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}
        >
          <TableCell component='th' scope='row'>
            <Box className='cart-main__product-name'>
              {product.s3ImgList && (
                <img
                  className='cart-main__product-img'
                  src={product.s3ImgList[0].imgLink}
                  alt={product.name}
                />
              )}
              <Link
                href={product.productId}
                underline='none'
                sx={{ color: 'black' }}
                target='_blank'
              >
                <p className='cart-main__product-name-text'>{product.name}</p>
              </Link>
            </Box>
          </TableCell>
          <TableCell
            align='right'
            className='cart-main__product-field cart-main__product-field--price'
          >
            {`${product.finalPrice} ${getSymbolFromCurrency(
              product.currency ?? 'rub'
            )}`}
          </TableCell>
          <TableCell align='right' className='cart-main__product-field'>
            <span>{product.count}</span>
          </TableCell>
          <TableCell align='right' className='cart-main__product-field'>
            <ProductOptionsString optionList={product.options} />
          </TableCell>
          <TableCell
            align='right'
            className='cart-main__product-field cart-main__product-field--summary-price'
          >
            {product.finalPrice * product.count} {getSymbolFromCurrency(
              product.currency ?? 'rub'
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default CartProductsListShow;
