import {CustomFieldListType, CustomFieldType, CustomFieldValueType} from "types/products";

export const sortByIndexAsc = (
  a: CustomFieldValueType | CustomFieldType,
  b: CustomFieldValueType | CustomFieldType,
) => {
  if (a.index !== undefined && b.index !== undefined) {
    return a.index - b.index;
  } else {
    return 0
  }
};

// названия конечно так себе, но чет другого не придумал

// превращаем объекты из данных в массивы (проходная функция, но нужна, если отсортировать не сможем изначально,
// например,  если нет индексов на старых данных)
export const getArrayFromCustomValuesOrFields = (
  values: {[id: string]: CustomFieldValueType} | CustomFieldListType
) => {
  return Object.values(values);
};

// тоже превращаем в объекты и сортируем по индексу
export const getCustomFieldsOrValuesSortedByIndex = (
  values: {[id: string]: CustomFieldValueType} | CustomFieldListType
) => {
  return Object.values(values).sort(sortByIndexAsc);
};

// добавляем дефолтный индекс или меняем индексы для наших данных (объектов)
export const changeIndexesInCustomFieldsOrValues = (
  currentArray:  CustomFieldValueType[],
  copyCustomFields: CustomFieldListType,
  currentField?: CustomFieldType
) => {
  currentArray.forEach((value, arrayIndex) => {
    if (currentField) {
      copyCustomFields[currentField.id].values[value.id].index = value.index || arrayIndex
    } else {
      copyCustomFields[value.id].index = value.index || arrayIndex
    }
  })
};

