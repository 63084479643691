import accessTokenController from 'services/accessTokenController';
import { io } from 'socket.io-client';

export const socketSdek = io(
  process.env.REACT_APP_API_SOCKET_URL || 'ws://localhost:3000', {
  auth: {
    token: accessTokenController.getToken(),
  },
  reconnection: true,            // Включает/отключает повторное подключение
  reconnectionAttempts: 3,       // Максимальное количество повторных попыток подключения
  reconnectionDelay: 100,
});

socketSdek.on("connect", () => {
  console.log('Сокет включен')
});

socketSdek.on("disconnect", () => {
  console.log('Сокет выключен')
});

socketSdek.on("connect_error", (err) => {
  console.error("Connection Error: ", err);
  socketSdek.disconnect(); // Отключиться после ошибки
});
