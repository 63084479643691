import {
  Button,
  IconButton,
  Popover as PopoverMui,
  PopoverOrigin,
  SxProps,
  Theme,
} from '@mui/material';
import { ReactNode, memo, useState } from 'react';

interface PopoverProps {
  title: ReactNode;
  children: ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>
}

const defaultAnchorOrigin = {
  vertical: 'bottom' as const,
  horizontal: 'center' as const,
};

const defaultTransformOrigin = {
  vertical: 'top' as const,
  horizontal: 'center' as const,
};

export const Popover = memo((props: PopoverProps) => {
  const {
    children,
    title,
    anchorOrigin = defaultAnchorOrigin,
    transformOrigin = defaultTransformOrigin,
    sx=[]
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else
    handlePopoverClose();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
        sx={[{ borderRadius: '50px', zIndex: '2000' }, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {title}
      </Button>{' '}
      <PopoverMui
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
        sx={{ borderRadius: '50px' }}
      >
        {children}
      </PopoverMui>
    </div>
  );
});
