import GridIcon from './GridIcon';
import ListIcon from './ListIcon';

import style from './Filters.module.scss';
import PriceFilter from './PriceFilter/PriceFilter';
import CategoryFilter from './CategoryFilter/CategoryFilter';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CustomLinkActions, getProductViewType } from 'entities/CustomLink';
import { ProductViewTypeEnum } from 'entities/CustomLink/lib/types';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

const Filters = () => {
  const isWhite = useIsWhiteMoreContrast();

  const productViewType = useAppSelector(getProductViewType);

  const dispatch = useAppDispatch();

  // Функция для определения стиля иконки в зависимости от типа отображения
  const getIconStyle = (viewType: ProductViewTypeEnum) => {
    const baseColor = isWhite ? '#000000' : '#FFFFFF';
    if (productViewType === viewType) {
      return { fill: baseColor, opacity: 1 }; // Если текущий тип отображения совпадает с типом иконки
    }
    return { fill: baseColor, opacity: 0.3 }; // Если текущий тип отображения не совпадает с типом иконки
  };

  const handleViewTypeChange = () => {
    if (productViewType === ProductViewTypeEnum.GRID) {
      dispatch(CustomLinkActions.setProductViewType(ProductViewTypeEnum.LIST));
    } else {
      dispatch(CustomLinkActions.setProductViewType(ProductViewTypeEnum.GRID));
    }
  };

  return (
    <div className={style.wrapper}>
      <div>
        <PriceFilter />
        <CategoryFilter />
      </div>
      <div onClick={handleViewTypeChange} className={style.visibilityTypeWrapper}>
        <GridIcon style={getIconStyle(ProductViewTypeEnum.GRID)} />
        <ListIcon style={getIconStyle(ProductViewTypeEnum.LIST)} />
      </div>
    </div>
  );
};

export { Filters };
