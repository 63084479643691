import { StateSchema } from 'app/providers/StoreProvider';
import { getListByObj } from 'utils';

export const getCustomLinkProductsObj = (state: StateSchema) => {
  if (state.CustomLinkSlice.products) {
    return getListByObj(state.CustomLinkSlice.products, 'productId');
  }
  return {};
};

export const getCustomLinkProductsData = (state: StateSchema) =>
  state.CustomLinkSlice.products;

export const getPrivateChannelLink = (state: StateSchema) =>
  state.CustomLinkSlice.privateChannelLink;

export const getCategories = (state: StateSchema) =>
  state.CustomLinkSlice.categories;

export const getCustomLinkName = (state: StateSchema) =>
  state.CustomLinkSlice.name;

export const getVerifiedStatus = (state: StateSchema) =>
  state.CustomLinkSlice.isUserVerified;

export const getProductCount = (state: StateSchema) =>
  state.CustomLinkSlice.productCount;

export const getCustomLinkSellerId = (state: StateSchema) =>
  state.CustomLinkSlice.sellerId;

export const getCustomLinkContent = (state: StateSchema) =>
  state.CustomLinkSlice.content;

export const getCustomLinkLoadingStatus = (state: StateSchema) =>
  state.CustomLinkSlice.isLoading;

export const getAccessStatus = (state: StateSchema) =>
  state.CustomLinkSlice.access;

export const getIsActiveLink = (state: StateSchema) =>
  state.CustomLinkSlice.isActive;

export const getCustomLinkTariffRestriction = (state: StateSchema) => ({
  isWhiteLabelAvailable: state.CustomLinkSlice.isWhiteLableAvailable,
  isPremiumModulesAvailable: state.CustomLinkSlice.isPremiumModulesAvailable,
});

export const getIsPaidTariff = (state: StateSchema) =>
  state.CustomLinkSlice.isPaidTariff;

export const getProductViewType = (state: StateSchema) =>
  state.CustomLinkSlice.productViewType;

