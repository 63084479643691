import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const updateStockItemProduct = createAsyncThunk<
  any,
  any,
  ThunkConfig<string>
>('stock/updateStockItemProduct', async (activeProduct, thunkApi) => {
  const {
    extra: { api },
  } = thunkApi;
  try {
    const data = await api.put(ApiRoutes.StockProduct, activeProduct);

    if (!data) {
      showErrorNotification(['Не удалось обновить']);

      return;
    }

    showSuccessNotification(['Элемент успешно обновлен']);

    return activeProduct;
  } catch (error) {
    console.log(error, 'update rror');

    const { message } = apiErrorHandler(error);
    showErrorNotification([message]);
    // Важно возвращать явно undefined, если функция должна завершиться после ошибки
    return undefined;
  }
});
