import { memo } from "react";

const FiltersIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <g clipPath='url(#clip0_379_6793)'>
        <path
          d='M3 7.5C3 7.30109 3.07902 7.11032 3.21967 6.96967C3.36032 6.82902 3.55109 6.75 3.75 6.75H6.75C6.94891 6.75 7.13968 6.82902 7.28033 6.96967C7.42098 7.11032 7.5 7.30109 7.5 7.5C7.5 7.69891 7.42098 7.88968 7.28033 8.03033C7.13968 8.17098 6.94891 8.25 6.75 8.25H3.75C3.55109 8.25 3.36032 8.17098 3.21967 8.03033C3.07902 7.88968 3 7.69891 3 7.5ZM20.25 15.75H18V14.25C18 14.0511 17.921 13.8603 17.7803 13.7197C17.6397 13.579 17.4489 13.5 17.25 13.5H15.75C15.5511 13.5 15.3603 13.579 15.2197 13.7197C15.079 13.8603 15 14.0511 15 14.25V18.75C15 18.9489 15.079 19.1397 15.2197 19.2803C15.3603 19.421 15.5511 19.5 15.75 19.5H17.25C17.4489 19.5 17.6397 19.421 17.7803 19.2803C17.921 19.1397 18 18.9489 18 18.75V17.25H20.25C20.4489 17.25 20.6397 17.171 20.7803 17.0303C20.921 16.8897 21 16.6989 21 16.5C21 16.3011 20.921 16.1103 20.7803 15.9697C20.6397 15.829 20.4489 15.75 20.25 15.75ZM12.75 15.75H3.75C3.55109 15.75 3.36032 15.829 3.21967 15.9697C3.07902 16.1103 3 16.3011 3 16.5C3 16.6989 3.07902 16.8897 3.21967 17.0303C3.36032 17.171 3.55109 17.25 3.75 17.25H12.75C12.9489 17.25 13.1397 17.171 13.2803 17.0303C13.421 16.8897 13.5 16.6989 13.5 16.5C13.5 16.3011 13.421 16.1103 13.2803 15.9697C13.1397 15.829 12.9489 15.75 12.75 15.75ZM9.75 10.5H11.25C11.4489 10.5 11.6397 10.421 11.7803 10.2803C11.921 10.1397 12 9.94891 12 9.75V8.25H20.25C20.4489 8.25 20.6397 8.17098 20.7803 8.03033C20.921 7.88968 21 7.69891 21 7.5C21 7.30109 20.921 7.11032 20.7803 6.96967C20.6397 6.82902 20.4489 6.75 20.25 6.75H12V5.25C12 5.05109 11.921 4.86032 11.7803 4.71967C11.6397 4.57902 11.4489 4.5 11.25 4.5H9.75C9.55109 4.5 9.36032 4.57902 9.21967 4.71967C9.07902 4.86032 9 5.05109 9 5.25V9.75C9 9.94891 9.07902 10.1397 9.21967 10.2803C9.36032 10.421 9.55109 10.5 9.75 10.5Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_379_6793'>
          <rect width='24' height='24' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(FiltersIcon);
