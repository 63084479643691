import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomLinkActions } from '../slice/CustomLinkSlice';
import api from 'services/api';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { RequestStatusCode } from 'consts';
import { CustomLinkGetProductsParamsI } from 'entities/CustomLink/lib/types';

export const getCustomLinkProducts = createAsyncThunk<
  void,
  CustomLinkGetProductsParamsI,
  ThunkConfig<string>
>(
  'customLink/fetchCustomLinkParams',
  async (
    { linkId, page, rowsPerPage, searchValue, categoryFilter, sortBy, sortType },
    { dispatch }
  ) => {
    dispatch(CustomLinkActions.setCustomLinkLoadingStatus(true));
    dispatch(CustomLinkActions.setAccess(true));
    
    api
      .getLinkProducts(
        linkId as string,
        page as number,
        rowsPerPage as number,
        searchValue as string,
        sortBy,
        sortType,
        categoryFilter.map(el => el)?.join(';')
      )
      .then((data) => {
          dispatch(
            CustomLinkActions.setCustomLinkProducts({
              products: data.products,
              productCount: data.productCount,
            })
          );
      })
      .catch((error) => {
        console.log(error, 'getProductList');
        
        dispatch(
          CustomLinkActions.setCustomLinkProducts({
            products: [],
            productCount: 0,
          })
        );
        if (error.response.status === RequestStatusCode.NotFound) {
          window.open('/404', '_self'); //TODO: Сделать нормальный редирект
        }
      });

    dispatch(CustomLinkActions.setCustomLinkLoadingStatus(false));
  }
);
