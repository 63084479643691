import { OrderSdekSchema } from '../../model/types/orderSdek';
import { getDefaultPackage } from './package';

export const getDefaultState = (): OrderSdekSchema => {
  return {
    packages: getDefaultPackage(),
    locationTo: {
      value: '',
      fiasCode: '',
      lat: '',
      lon: '',
    },
    locationFrom: {
      value: '',
      fiasCode: '',
      lat: '',
      lon: '',
    },
    officeListLocationTo: null,
    officeListLocationFrom: null,
    isOrderPayed: false,
    priceCOD: 0,
    phone: '',
    name: '',
    tariff: null,
    isLoadingOrder: false,
    point: null,
  };
};
