import { DimensionsType, PackageSettingType } from '../../model/types/packages';
import { validationLocations } from './validationLocations';
import { validationPackages } from './validationPackages';
import { validationTariff } from './validationTariff';
import { TariffType } from '../../model/types/tariff';
import { validationRecipient } from './validationRecipient';
import { PointType } from '../../model/types/location';

interface ValidationOrderCreateI {
  packages: PackageSettingType[];
  dimensions?: DimensionsType;
  pickupType: string;
  fromLocationFiasCode?: string;
  toLocationFiasCode?: string;
  tariff: TariffType | null;
  priceCOD?: number;
  recipientName: string;
  recipientPhone: string;
}

export const validationOrderCreate = async ({
  packages,
  dimensions,
  pickupType,
  fromLocationFiasCode,
  toLocationFiasCode,
  tariff,
  priceCOD,
  recipientName,
  recipientPhone,
}: ValidationOrderCreateI) => {
  // TODO: check point
  const { isValid: isValidPackages, errors: packagesErrors } =
    await validationPackages(packages, dimensions);

  const { isValid: isValidLocations, errors: locationErrors } =
    await validationLocations({
      pickupType,
      fromLocationFiasCode,
      toLocationFiasCode,
    });

  const { isValid: isValidRecipient, errors: recipientErrors } =
    await validationRecipient({
      priceCOD,
      recipientName,
      recipientPhone,
      minPrice: tariff?.delivery_sum ?? 0,
    });

  const { isValid: isValidTariff, errors: tariffErrors } =
    await validationTariff(tariff);

  if (
    !isValidPackages ||
    !isValidLocations ||
    !isValidTariff ||
    !isValidRecipient
  ) {
    return {
      isValid: false,
      packagesErrors,
      locationErrors,
      tariffErrors,
      recipientErrors,
    };
  }

  return {
    isValid: true,
    packagesErrors,
    locationErrors,
    tariffErrors,
    recipientErrors,
  };
};
