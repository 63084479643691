import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "@sentry/tracing";

const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_PROD_ENV || 'development';

Sentry.init({
  dsn: SENTRY_URL,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: SENTRY_ENVIRONMENT,
});
