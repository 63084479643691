import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StockOrderHeader from '../StockOrderHeader/OrderHeader';
import OrderSlice from '../../model/slice/stockOrderEditSlice';
import useAsyncReducer, {
  ReducersList,
} from 'shared/lib/hooks/useAsyncReducer';
import StockOrderProducts from '../OrderProducts/OrderProducts';

import StockOrderClient from '../OrderClient/OrderClient';
import { useAppDispatch } from 'redux/hooks';
import { getOrderData } from 'features/Orders/model/services/getOrderData';

const reducers: ReducersList = {
  StockOrderEdit: OrderSlice.reducer,
};

const OrderPage = () => {
  useAsyncReducer({ reducers, removeAfterUnmount: true });
  const { orderId } = useParams();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!state && orderId) {
      dispatch(getOrderData(orderId));
      return;
    }

    // Диспачим данные текущего заказа
  }, []);

  return (
    <div>
      <StockOrderHeader />
      <StockOrderClient />
      <StockOrderProducts />
    </div>
  );
};

export default OrderPage;
