import { Stack } from "@mui/material";
import { log } from "console";
import { memo } from "react";
import { SocialLinksBlockType, SocialSourceType } from "types/links";
import SocialLinksItem from "./SocialLinksItem";

type SocialLinksProps = {
  socialLinks: SocialLinksBlockType;
};

const SocialLinks = ({ socialLinks }: SocialLinksProps) => {
  const {
    email,
    vk,
    youtube,
    instagram,
    phone,
    telegram,
    whatsApp,
    socialLinksColor,
  } = socialLinks;

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      sx={{ color: socialLinksColor }}
    >
      <SocialLinksItem key={SocialSourceType.email} url={email} type={SocialSourceType.email} />
      <SocialLinksItem key={SocialSourceType.phone} url={phone} type={SocialSourceType.phone} />
      <SocialLinksItem key={SocialSourceType.vk} url={vk} type={SocialSourceType.vk} />
      <SocialLinksItem key={SocialSourceType.whatsApp} url={whatsApp} type={SocialSourceType.whatsApp} />
      <SocialLinksItem key={SocialSourceType.instagram} url={instagram} type={SocialSourceType.instagram} />
      <SocialLinksItem key={SocialSourceType.telegram} url={telegram} type={SocialSourceType.telegram} />
      <SocialLinksItem key={SocialSourceType.youtube} url={youtube} type={SocialSourceType.youtube} />
    </Stack>
  );
};

export default memo(SocialLinks);
