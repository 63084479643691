import useAsyncReducer, { ReducersList } from "shared/lib/hooks/useAsyncReducer";
import OrdersTable from "./OrdersTable/OrdersTable";
import StockSlice, { StockSliceActions } from "../model/slice/stockSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getIsCreateIOrderModalVisible } from "../model/selectors/stockSelectors";
import { StockOrderEdit } from "features/Orders";
import { OrderDashboardSlice } from "features/OrdersDashboard";
import { OrdersDashboard } from "features/OrdersDashboard";

const reducers: ReducersList = {
  Stock: StockSlice.reducer,
  OrdersDashboard: OrderDashboardSlice.reducer,
};

const AccountingPage = () => {
  useAsyncReducer({ reducers, removeAfterUnmount: false });
  const dispatch = useAppDispatch();
  const isStockOrderEditOpen = useAppSelector(getIsCreateIOrderModalVisible);

  const handleModalClose = () => {
    dispatch(StockSliceActions.setIsStockOrderEditVisible(false));
  };

  return (
    <section>
      <OrdersDashboard />
      <OrdersTable />
      <StockOrderEdit isOpen={isStockOrderEditOpen} handleModalClose={handleModalClose} />
    </section>
  );
};

export default AccountingPage;
