import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { getValidationError } from 'utils/validation';

const locationsSchema = Yup.object().shape({
  fromLocationFiasCode: Yup.string()
    .typeError('Введите корректное значение')
    .required('Это поле обязательное!'),
  toLocationFiasCode: Yup.string()
    .typeError('Введите корректное значение')
    .required('Это поле обязательное!'),
  pickupType: Yup.string()
    .typeError('Введите корректное значение')
    .required('Выберите пункт доставки!'),
});

export const validationLocations = async (locationData: {
  fromLocationFiasCode?: string;
  toLocationFiasCode?: string;
  pickupType?: string;
}) => {
  const errors = await getValidationError(locationsSchema, locationData);

  if (!isEmpty(errors)) {
    return { errors: errors, isValid: false };
  }

  return { errors: undefined, isValid: true };
};
