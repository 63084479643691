import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import BaseInput from 'components/ui/BaseInput/BaseInput';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setPromoDiscount,
  setIsModalVisible,
  setIsPromocodeLoading,
  setPromocodeErrorText,
  setPromocodeValue,
} from '../model/slice/promocodeSlice';
import {
  getIsModalVisible,
  getPromoDiscount,
  getPromocodeErrorText,
  getPromocodeLoadingStatus,
  getPromocodeValue,
} from '../model/selectors/promocodeSelectors';
import { applyPromocode } from '../model/services/promocodeApi';
import BasicModal from 'components/common/BasicModal/BasicModal';
import { Box, Typography } from '@mui/material';
import { refresh } from 'services/apiInstance';
import { useEffect } from 'react';

const PromocodeForm = () => {
  const dispatch = useAppDispatch();
  const promocode = useAppSelector(getPromocodeValue);
  const errorText = useAppSelector(getPromocodeErrorText);
  const isModalVisible = useAppSelector(getIsModalVisible);
  const isLoading = useAppSelector(getPromocodeLoadingStatus);
  const promoDiscount = useAppSelector(getPromoDiscount);

  useEffect(() => {
    return () => {
      if (errorText) {
        dispatch(setPromocodeErrorText(''));
      }
    };
  }, []);

  const handlePromoApplyButtonClick = async () => {
    dispatch(setIsPromocodeLoading(true));

    const result = await applyPromocode(promocode);

    if (!result || result?.errorText) {
      dispatch(setIsPromocodeLoading(false));
      dispatch(setPromocodeErrorText('Не удалось применить промокод'));
    }

    if (result?.errorText) {
      dispatch(setPromocodeErrorText(result?.errorText));
    }

    if (result.discount) {
      dispatch(setPromoDiscount(result.discount));
    }

    if (result.result) {
      dispatch(setIsPromocodeLoading(false));
      dispatch(setIsModalVisible(true));
      setPromocodeErrorText('');
      await refresh();
    }
  };

  const handleModalClose = async () => {
    dispatch(setIsModalVisible(false));
  };  

  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <BaseInput
          sx={{
            mr: '10px',
          }}
          label='Промокод'
          value={promocode}
          onChange={(evt) => dispatch(setPromocodeValue(evt.target.value))}
          error={Boolean(errorText)}
          helperText={errorText || ''}
          InputProps={{
            endAdornment: isLoading && (
              <InputAdornment position='end'>
                <CircularProgress />
              </InputAdornment>
            ),
          }}
        />
        <Button variant='contained' onClick={handlePromoApplyButtonClick}>
          Применить
        </Button>
      </Stack>
      {isModalVisible && (
        <BasicModal open={isModalVisible} onClose={handleModalClose}>
          <Box maxWidth={'250px'}>
            <Typography fontSize={'22px'}>
              Промокод успешно применен.<br />
              {Boolean(promoDiscount) && `Скидка ${promoDiscount}% будет применена на странице оплаты`}
            </Typography>
          </Box>
        </BasicModal>
      )}
    </>
  );
};

export { PromocodeForm };
