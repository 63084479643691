import {
  CustomerSignUpDataType,
  CustomerLoginDataType,
  TokenCustomerDataType,
} from "types/customer";
import accessTokenController from "services/accessTokenController";
import { ApiRoutes, UserAuthStatus, UserRole } from "consts";
import { apiErrorHandler } from "services/apiErrorHandler";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import {
  resetCustomerErrorMessage,
  setCustomerLoadingStatus,
  setCustomerRequestStatus,
  setCustomerErrorMessage,
  setCustomerTelegramData,
  setCustomerAuthStatus,
  setCustomerPhone,
  setCustomerRole,
  setCustomerId,
} from "./CustomerSlice";
import { ThunkConfig } from "app/providers/StoreProvider";

export const checkAuthCustomerAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("customer/checkAuthCustomer", async (_args, { dispatch,extra: { api } }) => {
  dispatch(resetCustomerErrorMessage());
  try {
    const token = accessTokenController.getToken();

    if (!token) {
      dispatch(setCustomerAuthStatus(UserAuthStatus.Unauthorized));
      return;
    }

    const { customerId, role, phone } = jwtDecode(
      token
    ) as TokenCustomerDataType;

    if (token && role === UserRole.Customer) {
      dispatch(setCustomerId(customerId));
      dispatch(setCustomerRole(role));
      dispatch(setCustomerPhone(phone));
      dispatch(setCustomerAuthStatus(UserAuthStatus.Authorized));
    } else {
      dispatch(setCustomerAuthStatus(UserAuthStatus.Unauthorized));
    }
  } catch (error: unknown) {
    const { message } = apiErrorHandler(error);
    dispatch(setCustomerErrorMessage(message));
  }
});

export const registrationCustomerAction = createAsyncThunk<
  void,
  { customerRegistration: CustomerSignUpDataType },
  ThunkConfig<string>
>(
  "customer/registration",
  async ({ customerRegistration }, { dispatch,extra: { api } }) => {
    dispatch(setCustomerLoadingStatus(true));
    dispatch(resetCustomerErrorMessage());
    try {
      const { data } = await api.post(
        ApiRoutes.RegistrationCustomer,
        customerRegistration
      );

      if (data.status) {
        dispatch(setCustomerPhone(data.phone));
      }
      dispatch(setCustomerLoadingStatus(false));
    } catch (error: unknown) {
      const { message, status } = apiErrorHandler(error);
      dispatch(setCustomerErrorMessage(message));
      dispatch(setCustomerRequestStatus(status));
      dispatch(setCustomerLoadingStatus(false));
    }
  }
);

export const loginCustomerAction = createAsyncThunk<
  void,
  { customerLogin: CustomerLoginDataType, captchaToken: string },
  ThunkConfig<string>
>(
  "customer/loginCustomer",
  async ({ customerLogin, captchaToken }, { dispatch,extra: { api } }) => {
    dispatch(setCustomerLoadingStatus(true));
    dispatch(resetCustomerErrorMessage());
    try {
      const { data } = await api.post(ApiRoutes.LoginCustomer, {
        phone: customerLogin.phone,
        password: customerLogin.password,
        captchaToken
      });

      if (data.token && data.status) {
        const { token } = data;

        accessTokenController.save(token);
        const { customerId, role, phone }: TokenCustomerDataType =
          jwtDecode(token);

        dispatch(setCustomerId(customerId));
        dispatch(setCustomerRole(role));
        dispatch(setCustomerPhone(phone));
        dispatch(setCustomerAuthStatus(UserAuthStatus.Authorized));
      }

      dispatch(setCustomerLoadingStatus(false));
    } catch (error: unknown) {
      const { message, status } = apiErrorHandler(error);
      dispatch(setCustomerErrorMessage(message));
      dispatch(setCustomerRequestStatus(status));
      dispatch(setCustomerLoadingStatus(false));
    }
  }
);

export const logoutCustomerAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>("customer/logoutCustomer", async (_args, { dispatch,extra: { api } }) => {
  dispatch(resetCustomerErrorMessage());
  try {
    dispatch(setCustomerId(""));
    dispatch(setCustomerRole(""));
    dispatch(setCustomerPhone(""));
    dispatch(setCustomerAuthStatus(UserAuthStatus.Unauthorized));

    accessTokenController.logout();
  } catch (error: unknown) {
    const { message, status } = apiErrorHandler(error);
    dispatch(setCustomerErrorMessage(message));
    dispatch(setCustomerRequestStatus(status));
  }
});

export const fetchCustomerTelegramVerificationCodeAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>(
  "customer/fetchCustomerTelegramVerificationCode",
  async (_args, { dispatch,extra: { api } }) => {
    dispatch(setCustomerLoadingStatus(true));
    dispatch(resetCustomerErrorMessage());
    try {
      const { data } = await api.get(ApiRoutes.Customer);

      if (data) {
        dispatch(
          setCustomerTelegramData({
            telegramVerificationCode: data.telegramVerificationCode,
            telegramId: data.telegramId,
          })
        );

        dispatch(setCustomerLoadingStatus(false));
      }
    } catch (error) {
      const { message, status } = apiErrorHandler(error);
      dispatch(setCustomerErrorMessage(message));
      dispatch(setCustomerRequestStatus(status));
      dispatch(setCustomerLoadingStatus(false));
      setTimeout(() => {
        dispatch(resetCustomerErrorMessage());
      }, 3000);
    }
  }
);
