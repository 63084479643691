export const DEFAULT_BRAND = {
  title: "Сделано на LinkWi🔥",
  url: "https://linkwi.ru",
  linkColor: "#FFFFFF",
};

export const CHART_LABELS = [
  "01/01/2022",
  "02/01/2022",
  "03/01/2022",
  "04/01/2022",
  "05/01/2022",
  "06/01/2022",
  "07/01/2022",
  "08/01/2022",
  "09/01/2022",
  "10/01/2022",
  "11/01/2022",
]

export const CHART_DATA = [
  {
    name: "Team A",
    type: "column",
    fill: "solid",
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
  },
  {
    name: "Team B",
    type: "area",
    fill: "gradient",
    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  },
  {
    name: "Team C",
    type: "line",
    fill: "solid",
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
  },
]

export const CHART_LANG = "en"
export const CHART_LOCALE = [{
  name: 'en',
  options: {
    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  }
}]

export const CAPTCHA_VERIFY_ACTIONS = {
  login: 'login',
  registration: 'registration',
  newProject: 'newProject',
  newProduct: 'newProduct',
  newLink: 'newLink'
}

export const CAPTCHA_ERROR_MSG = 'Execute recaptcha not yet available';
export const ASPECT_BANNER_MOBILE = 2.5;
export const ASPECT_BANNER_DESKTOP = 3;
