export const BUTTON_TEMPLATE = {
  blockId: "",
  bg: "#fff",
  textColor: "#223BDD",
  textLink: "Ваша ссылка",
  urlLink: "",
  animation: "",
  animationSpeed: "",
  s3IconImg: null,
  iconImgUrl: "",
  borderThickness: 0,
  borderColor: "#000",
};
