import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          backgroundColor: "#fff",
          opacity: 0.7,
          "&.Mui-selected": {
            backgroundColor: "#fff",
            fontWeight: 500,
            opacity: 1,
            color: theme.palette.primary.main
          }
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: "center",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          height: 40,
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeSmall: {
          fontSize: 12,
        },
        sizeMedium: {
          fontSize: 16,
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "Arial, sans-serif",
          fontSize: 12,
          border: "1px solid #C8CEF7",
          height: 22,
          [theme.breakpoints.up("sm")]: {
            fontSize: 14,
            height: 26,
          },
          [theme.breakpoints.up("md")]: {
            fontSize: 16,
            height: 32,
          },
        }
      }
    }
  };
}
