import { StateSchema } from 'app/providers/StoreProvider';
import { CurrentOrderDataI, CustomerInfoI } from '../../lib/types';

export const getStockOrderProductList = (state: StateSchema): any[] =>
  // @ts-ignore
  state?.StockOrderEdit?.productList || [];

export const getStockOrderProductListSearch = (state: StateSchema): string =>
  // @ts-ignore
  state?.StockOrderEdit?.search;

export const getIsLoading = (state: StateSchema): boolean =>
  // @ts-ignore
  state?.StockOrderEdit?.isLoading;

export const getCurrentOrderData = (state: StateSchema): CurrentOrderDataI => ({
  // @ts-ignore
  orderId: state.StockOrderEdit?.orderId,
  // @ts-ignore
  orderProducts: state.StockOrderEdit?.orderProducts,
  // @ts-ignore
  customerInfo: state.StockOrderEdit?.customerInfo,
  // @ts-ignore
  createdAt: state.StockOrderEdit?.createdAt,
  // @ts-ignore
  status: state.StockOrderEdit?.status,
});

export const getIsAddProductModalVisible = (state: StateSchema): boolean =>
  // @ts-ignore
  state.StockOrderEdit?.isAddProductModalVisible;

export const getIsDeleteProductModalVisible = (state: StateSchema): boolean =>
  // @ts-ignore
  state.StockOrderEdit?.isDeleteProductModalVisible;

export const getProductDataToDelete = (state: StateSchema): string =>
  // @ts-ignore
  state.StockOrderEdit?.productDataToDelete;

export const getNewCustomerData = (state: StateSchema): CustomerInfoI =>
  // @ts-ignore
  state.StockOrderEdit?.newCustomerInfo;

export const getCustomerSuggestionList = (state: StateSchema): CustomerInfoI[] =>
  // @ts-ignore
  state.StockOrderEdit?.clientSuggestionList;
