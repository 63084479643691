import { memo } from 'react';
import isEmpty from 'lodash/isEmpty';

import style from './OrderProductsItem.module.scss';

const OrderProductsItem = ({ row }: any) => {
  const orderProduct = row.original;
  
  const { name, options, s3ImgList, } = orderProduct;
  
  return (
    <div className={style.wrapper}>
      <div className={style.imgWrapper}>
        <img className={style.img} src={s3ImgList[0].imgLink} alt={name} />
      </div>
      <div className={style.textWrapper}>
        <div className={style.headerAndOptionsWrapper}>
          <p className={style.name}>{name}</p>
          {!isEmpty(options) &&
            options?.map((option: any, index: number) => (
              <div key={index}>
                <span className={style.optionName}>{option.name}:</span>
                <span className={style.optionValue}>{option.value}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default memo(OrderProductsItem);
