import { DimensionsType } from './../types/packages';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { showErrorNotification } from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { CreateOrderSdekActions } from '../slice/newSdekOrderSlice';
import { IAutocomplitingAddress, PointType } from '../types/location';

interface InitRouteOrderStateProps {
  address: IAutocomplitingAddress;
  dimensions: DimensionsType;
  name: string;
  phone: string;
  point: PointType;
}

export const initRouteOrderState = createAsyncThunk<
  void,
  InitRouteOrderStateProps,
  ThunkConfig<string>
>(
  'sdek/initRouteOrderState',
  async ({ address, dimensions, name, phone, point }, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      dispatch(CreateOrderSdekActions.setLocationTo(address));
      dispatch(CreateOrderSdekActions.setPoint(point));
      dispatch(CreateOrderSdekActions.setPhone(phone));
      dispatch(CreateOrderSdekActions.setName(name));
      dispatch(CreateOrderSdekActions.setDimensionsPackages(dimensions));
    } catch (error) {
      const { message } = apiErrorHandler(error);
      showErrorNotification([message]);
    }
  }
);
