import { formatAmount } from 'utils/formatAmount';
import OrderProductsItem from '../OrderProductsItem/OrderProductsItem';

const OrderProductsTableColumns = [
  {
    accessorKey: 'name', //access nested data with dot notation
    header: 'Товар',
    size: 300,
    Cell: ({ row }: any) => <OrderProductsItem row={row} />,
  },
  {
    accessorKey: 'count',
    header: 'Кол-во',
    size: 50,
  },
  {
    accessorKey: 'costPrice',
    header: 'Себест. за ед',
    Cell: ({ cell }: any) => <>{formatAmount(cell.getValue())}</>,
  },
  {
    accessorKey: 'finalPrice', //normal accessorKey
    header: 'Цена за ед',
    size: 70,
    Cell: ({ cell }: any) => <>{formatAmount(cell.getValue())}</>,
  },
  {
    accessorKey: 'costPriceTotal',
    header: 'Себест. общ.',
    Cell: ({ row }: any) => (
      <>
        {formatAmount(Math.ceil(row.original.count * row.original.costPrice))}
      </>
    ),
  },
  {
    accessorKey: 'finalPriceTotal', //normal accessorKey
    header: 'Цена общ.',
    Cell: ({ row }: any) => (
      <>
        {formatAmount(Math.ceil(row.original.count * row.original.finalPrice))}
      </>
    ),
  },
];

export default OrderProductsTableColumns;
