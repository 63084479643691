import { TableCell, TableHead, TableRow } from "@mui/material";

const CartTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ width: "30%", pl: "24px" }}
          className="cart-main__product-header-field"
        >
          Товар
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field">
          Цена
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field" sx={{width: "170px"}}>
          Количество
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field">
          Опции
        </TableCell>
        <TableCell align="right" className="cart-main__product-header-field" sx={{ width: "50px" }}>
          Полная стоимость
        </TableCell>
        <TableCell align="right" sx={{ width: "50px" }}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CartTableHead;
