import { CartProductCardType } from 'types/products';
import { Stack, Box, Link } from '@mui/material';
import ProductOptionsString from 'components/Products/ProductOptions/ProductOptionsString';
import getSymbolFromCurrency from 'currency-symbol-map';

type CartProductListMobileProps = {
  products: CartProductCardType[];
};

const CartProductListMobileShow = ({
  products,
}: CartProductListMobileProps) => {
  return (
    <Stack className='cart-main_mobile-list'>
      {products.map((product) => (
        <Stack
          direction='row'
          key={product.cartProductId}
          className='cart-main_mobile-card'
        >
          <Box className='cart-main__product-img-wrapper'>
            {product.s3ImgList && (
              <img src={product.s3ImgList[0].imgLink} alt={product.name} />
            )}
          </Box>

          <Stack className='cart-main__product-description'>
            <Box className='cart-main__product-name-mobile-showAll'>
              <Link
                href={product.productId}
                underline='none'
                sx={{ color: 'black' }}
                target='_blank'
              >
                <h3 className='cart-main__product-name-mobile-text'>
                  {product.name}
                </h3>
              </Link>
            </Box>
            <Box className='cart-main__product-options-showAll-mobile'>
              <ProductOptionsString optionList={product.options} />
            </Box>
            <Stack
              direction='row'
              flexWrap='wrap'
              sx={{ alignItems: 'center', mt: 'auto' }}
            >
              <Box className='cart-main__mobile-product-price' mr='5px'>
                <p>{` ${product.finalPrice} ${getSymbolFromCurrency(
                  product.currency ?? 'rub'
                )} ×`}</p>
              </Box>
              <Box className='cart-main__mobile-product-price' mr='5px'>
                <p>{`${product.count} шт.`}</p>
              </Box>
              <Box className='cart-main__mobile-product-summary-price'>
                <p>{
                  product.finalPrice * product.count
                } {getSymbolFromCurrency(product.currency ?? 'rub')}</p>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default CartProductListMobileShow;
