import 'mapbox-gl/dist/mapbox-gl.css';
import { Box } from '@mui/material';
import {
  NavigationControl,
  MapLayerMouseEvent,
  MarkerProps,
} from 'react-map-gl';
import type { MapRef } from 'react-map-gl';
import Map from 'react-map-gl';
import { CustomMarkerList } from './CustomMarkerList';
import { memo, useCallback, useRef } from 'react';
import { DeliveryPointI } from 'features/sdek/NewSdekOrder/model/types/location';
import useResponsive from 'hooks/useResponsive';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiZGJlbG9ib3JvZG92IiwiYSI6ImNsY243eTI2MTA5eWgzcW1ycTJmNjRxeHAifQ.hOuaMNAYu5Mgb3Qt8Czq0Q';

type MapWidgetProps = {
  deliveryPointCode?: string;
  latitude: number;
  longitude: number;
  handleMarkerClick: (pointData: MarkerProps) => void;
  officeListLocation: DeliveryPointI[];
};

const MAP_DEFAULT_ZOOM = 12;

export const MapWidget = memo(
  ({
    deliveryPointCode,
    latitude,
    longitude,
    handleMarkerClick,
    officeListLocation,
  }: MapWidgetProps) => {
    const mapRef = useRef<MapRef>(null);
    const isMobile = useResponsive('down', 'sm');
    const mapStyle = { width: isMobile ? '100%' : '60vw', height: 400 };
    if (!location) {
      return null;
    }

    function handleMapClick(evt: MapLayerMouseEvent) {
      evt.target.flyTo({
        center: [evt.lngLat.lng, evt.lngLat.lat],
      });
    }

    const changeMapLanguage = useCallback(() => {
      mapRef?.current?.getStyle().layers.forEach((layer: any) => {
        if (layer?.layout && layer?.layout['text-field']) {
          mapRef?.current
            ?.getMap()
            .setLayoutProperty(layer.id, 'text-field', [
              'coalesce',
              ['get', 'name_ru'],
              ['get', 'name'],
            ]);
        }
      });
    }, []);

    return (
      <Box>
        <Map
          ref={mapRef}
          id='mapSdek'
          onLoad={changeMapLanguage}
          scrollZoom={false}
          mapboxAccessToken={process.env.MAPBOX_TOKEN || MAPBOX_TOKEN}
          initialViewState={{
            longitude: Number(longitude),
            latitude: Number(latitude),
            zoom: MAP_DEFAULT_ZOOM,
          }}
          style={mapStyle}
          mapStyle='mapbox://styles/mapbox/streets-v9'
          onClick={handleMapClick}
        >
          <CustomMarkerList
            deliveryPointCode={deliveryPointCode}
            handleMarkerClick={handleMarkerClick}
            officeListLocation={officeListLocation}
          />
          <NavigationControl />
        </Map>
      </Box>
    );
  }
);
