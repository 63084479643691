import { createSlice } from "@reduxjs/toolkit";

export interface PromocodeSchema {
  isLoading: boolean;
  promocodeValue: string;
  errorText: string;
  isModalVisible: boolean;
  promoDiscount: number;
};

const initialState: PromocodeSchema = {
  isLoading: false,
  promocodeValue: '',
  errorText: '',
  isModalVisible: false,
  promoDiscount: 0,
};

const promocodeSlice = createSlice({
  name: "promocode",
  initialState,
  reducers: {
    setPromocodeValue: (state, action) => {
      state.promocodeValue = action.payload;
    },
    setIsPromocodeLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPromocodeErrorText: (state, action) => {
      state.errorText = action.payload;
    },
    setIsModalVisible: (state, action) => {
      state.isModalVisible = action.payload;
    },
    setPromoDiscount: (state, action) => {      
      state.promoDiscount = action.payload;
    }
  },
});

export const {
    setPromocodeValue,
    setIsPromocodeLoading,
    setPromocodeErrorText,
    setIsModalVisible,
    setPromoDiscount,
} = promocodeSlice.actions;

export default promocodeSlice;
