import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ActionMenuItem,
} from 'material-react-table';
import OrderProductsTableColumns from './OrderProductsTableColumns';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Delete from '@mui/icons-material/Delete';
import { useAppDispatch } from 'redux/hooks';
import { OrderSliceActions } from 'features/Orders/model/slice/stockOrderEditSlice';

const OrderProductsTable = ({
  orderProducts,
  isActionsVisible = true,
  orderId,
}: {
  orderProducts: any[];
  isActionsVisible: boolean;
  orderId: string;
}) => {
  const dispatch = useAppDispatch();

  const handleDeleteProductItemClick = (productId: string, orderId: string) => {
    dispatch(OrderSliceActions.setProductDataToDelete({
      productId,
      orderId,
    }));
    dispatch(OrderSliceActions.setIsDeleteProductModalVisible(true));
  };

  const table = useMaterialReactTable({
    columns: OrderProductsTableColumns,
    data: orderProducts,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    localization: MRT_Localization_RU,
    enableRowActions: isActionsVisible,
    positionActionsColumn: 'last',
    renderRowActionMenuItems: ({ row, table }) => [
      <MRT_ActionMenuItem
        icon={<Delete />}
        key='delete'
        label='Удалить'
        onClick={() => handleDeleteProductItemClick(row.original.productId, orderId)}
        table={table}
      />,
    ],
  });

  return <MaterialReactTable table={table} />;
};

export default OrderProductsTable;
