import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type CloseButtonModalProps = {
  onClose: () => void;
  ariaLabel?: string
};

const CloseButtonModal = ({ onClose, ariaLabel='close' }: CloseButtonModalProps) => {
  return (
    <IconButton
      title={ariaLabel}
      aria-label={ariaLabel}
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 15,
        color: (theme) => theme.palette.error.main,
        zIndex: 1000
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButtonModal;
