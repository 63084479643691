import { TailSpin } from 'react-loader-spinner';

export const Spinner = () => {
  return (
    <TailSpin
      color='#2274a5'
      wrapperStyle={{ margin: '30vw', justifyContent: 'center' }}
    />
  );
};
