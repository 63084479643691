import { styled } from '@mui/material/styles';
import { BaseColors } from '../colors';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';
import { memo } from 'react';

const BasePhoneInput = styled(MuiTelInput)<MuiTelInputProps>(() => ({
  '.MuiInputBase-root': {
    borderRadius: '12px',
    color: BaseColors.textPrimary,
    border: `1px solid ${BaseColors.strokePrimary}`,
    backgroundColor: "transparent",

    '&:hover': {
      border: `1px solid ${BaseColors.strokeContrast}`,
    },

    '&:after, &:before': {
      display: 'none',
    },
  },

  '.MuiInputLabel-root': {
    position: 'static',
    transform: 'none',
    fontWeight: 500,
    color: BaseColors.textPrimary,
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: `${BaseColors.strokePrimary} !important`,
    top: '0px',
  },

  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: BaseColors.strokeContrast,
    borderWidth: '1px',
  },

  '.MuiOutlinedInput-notchedOutline legend': {
    display: 'none',
  },
}));

export default memo(BasePhoneInput);
