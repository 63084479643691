import debounce from 'lodash/debounce';
import React, { useState, useCallback, useMemo, memo, ReactNode } from 'react';
import styles from './SearchField.module.scss';
import SearchIcon from './SearchIcon';
import classNames from 'classnames';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

interface SearchFieldPropsI {
  value: string;
  handleSearchChange: (newValue: string) => void;
  children?:  React.ReactNode;
  classname?: string;
}

const SearchField = ({ value, handleSearchChange, children, classname }: SearchFieldPropsI) => {
  const [searchTerm, setSearchTerm] = useState(value);

  // Создаем дебаунсированную функцию и мемоизируем её
  const debouncedSearch = useMemo(
    () => debounce((searchText) => handleSearchChange(searchText), 300),
    [handleSearchChange]
  );

  // Обработчик изменения поля поиска
  const handleSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value); // Обновляем внутренний стейт немедленно для отзывчивости UI
      debouncedSearch(event.target.value); // Отправляем изменения с дебаунсом
    },
    [debouncedSearch]
  );

  const isWhite = useIsWhiteMoreContrast();

  return (
    <div className={classNames(styles.searchBar, { [styles.searchBarWhite]: isWhite, }, classname)}>
      <SearchIcon fill={isWhite ? '#000000' : '#FFFFFF'} />
      <input
        type="text"
        className={classNames(styles.searchInput, { [styles.searchInputWhite]: isWhite })}
        placeholder="Искать товары"
        value={searchTerm}
        onChange={handleSearchInputChange}
      />
      {children}
    </div>
  );
};

export default memo(SearchField);
