import {
  Stack,
  Typography,
  Modal,
  Checkbox,
  Box,
  InputLabel,
  Button,
  Link,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { useState, memo, useEffect } from 'react';

import BaseInput from 'components/ui/BaseInput/BaseInput';
import BasePhoneInput from 'components/ui/BasePhoneInput/BasePhoneInput';
import { matchIsValidTel } from 'mui-tel-input';
import { sendContactData } from 'services/api';
import { setUserContactInfoStatus } from 'redux/UserSlice/UserSlice';

const ContactModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  // Валидация на номер
  // хотя бы 1 поле - обязательное
  return (
    <Modal
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setIsModalOpen(false);
      }}
      open={isModalOpen}
      disableEscapeKeyDown={true}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '310px',
          maxHeight: '100vh',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 2,
          overflow: 'auto',
        }}
      >
        <Stack alignItems={'flex-start'}>
          <Typography variant='h4'>LinkWi прекращает работу</Typography>
          <Typography sx={{
            my: '15px'
          }}>20.10.24 сервера будут остановлены.</Typography>
          <Typography sx={{
            my: '15px'
          }}>
            Спасибо, что были с нами все это время. Заполните форму ниже, чтобы
            получить возврат средств за неиспользованную подписку после закрытия
            сервиса
          </Typography>
          <Link href='https://forms.gle/zbaeGnpgeb6RFt3eA'>
            https://forms.gle/zbaeGnpgeb6RFt3eA
          </Link>
          <Button
            fullWidth
            onClick={() => setIsModalOpen(false)}
            variant='contained'
            color='primary'
            sx={{ mt: '10px' }}
          >
            Закрыть
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default memo(ContactModal);
