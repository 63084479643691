import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Modal } from 'shared/ui/Modal/Modal';
import cls from './LinkBlockedModal.module.scss';

export const LinkBlockedModal = () => {
  return (
    <Modal>
      <Stack gap={2} alignItems={'center'}>
        <Typography variant='h3'> Страница неактивна</Typography>
        <Typography variant='body2' className={cls.info}>
          Если вы владелец - войдите в аккаунт для подробной информации
        </Typography>
        <Button
          className={cls.link}
          variant='contained'
          component={Link}
          to='/signin'
        >
          Войти
        </Button>
      </Stack>
    </Modal>
  );
};
