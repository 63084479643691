import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { showErrorNotification } from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getStockOrderProductListSearch } from '../selectors/stockOrderEditSelectors';

export const getOrderData = createAsyncThunk<
  any,
  string,
  ThunkConfig<string>
>('stock/getOrderData', async (orderId, thunkApi) => {
  const {
    extra: { api },
  } = thunkApi;
  try {
    const { data } = await api.get(`${ApiRoutes.Orders}/${orderId}`);

    return data;
  } catch (error) {
    const { message } = apiErrorHandler(error);
    showErrorNotification([message]);
    // Важно возвращать явно undefined, если функция должна завершиться после ошибки
    return undefined;
  }
});
