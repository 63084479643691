import {
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { getAllReviewsCount } from '../../model/selectors/reviews';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { usePagination } from 'hooks/usePagination';

export const ReviewsStatusToggler = () => {
  const allReviewsCount = useAppSelector(getAllReviewsCount);
  const { filter, setSearchParams } = usePagination();

  const handleChange = (event: any, newAlignment: string) => {
    setSearchParams({ filter: newAlignment });
  };

  return (
    <>
      <ToggleButtonGroup
        value={filter}
        exclusive
        onChange={handleChange}
        aria-label='filter'
        sx={{mb: 2}}
      >
        <ToggleButton value='all'>
          Все отзывы: {allReviewsCount && allReviewsCount.toString()}
        </ToggleButton>
        <ToggleButton value='new'>Новые</ToggleButton>
        <ToggleButton value='viewed'>Просмотренные</ToggleButton>
        <ToggleButton value='completed'>Обработанные</ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
