import BasicModal from 'components/common/BasicModal/BasicModal';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { AddressInput } from 'features/FeatchAddressInput';
import { FindDeliveryPoint } from 'features/sdek/NewSdekOrder';
import { IAutocomplitingAddress } from 'features/sdek/NewSdekOrder';
import { memo, useState } from 'react';
import { getCustomLinkTariffRestriction } from 'entities/CustomLink';
import { useAppSelector } from 'redux/hooks';
import cls from './AddressDelivery.module.scss';
import { Checkbox } from 'shared/ui/Checkbox/Checkbox';
import {
  DeliveryPointI,
  PointType,
} from 'features/sdek/NewSdekOrder/model/types/location';

interface AddressDeliveryProps {
  setAddress: (value: IAutocomplitingAddress) => void;
  address: IAutocomplitingAddress | null;
  setPoint: (point: PointType) => void;
  point: PointType | null;
  setIsUseSdek: (value: boolean) => void;
  isUseSdek: boolean;
}

export const AddressDelivery = memo(
  ({
    setAddress,
    address,
    setPoint,
    point,
    setIsUseSdek,
    isUseSdek,
  }: AddressDeliveryProps) => {
    const [sdekPointModal, setSdekOpenModal] = useState(false);
    const { isPremiumModulesAvailable } = useAppSelector(
      getCustomLinkTariffRestriction
    );

    const handleChangePoint = (point: DeliveryPointI) => {
      setPoint({
        lat: point.location.latitude,
        lon: point.location.longitude,
        deliveryPointCode: point.code,
        deliveryPointName: point.name,
        deliveryPointAddress: point.location.address,
        deliveryPointOpenHours: point.work_time,
        pickupType: point.type,
      });
    };

    const changeHandleAddress = (address: IAutocomplitingAddress) => {
      setAddress(address);
    };

    if (!isPremiumModulesAvailable || !isUseSdek) {
      return (
        <div className={cls.addressInputWrapper}>
          <AddressInput
            label='Адрес'
            id='location-from'
            value={address?.value ?? ''}
            setLocation={changeHandleAddress}
          />
          {isPremiumModulesAvailable && (
            <Checkbox
              checked={isUseSdek}
              onChange={setIsUseSdek}
              label='Доставить с помощью СДЕК в ближайший пункт выдачи'
            />
          )}
        </div>
      );
    }

    return (
      <>
        <div className={cls.addressInputWrapper}>
          <AddressInput
            label='Ваш город'
            id='location-from'
            value={address?.value ?? ''}
            setLocation={changeHandleAddress}
            onlyCities
          />
          <Checkbox
            checked={isUseSdek}
            onChange={setIsUseSdek}
            label='Доставить с помощью СДЕК в ближайший пункт выдачи'
          />
        </div>
        {address && (
          <>
            {address?.value && (
              <BaseInput
                fullWidth
                id='sdek-point'
                name='sdek-point'
                label='Выбранный клиентом пвз'
                InputLabelProps={{ shrink: true }}
                value={point?.deliveryPointAddress}
                onClick={() => setSdekOpenModal(true)}
                sx={{ mt: '1rem' }}
                required
              />
            )}
            <BasicModal
              open={sdekPointModal}
              onClose={() => setSdekOpenModal(false)}
            >
              <FindDeliveryPoint
                onCloseModal={() => setSdekOpenModal(false)}
                setPoint={handleChangePoint}
                deliveryPointCode={point?.deliveryPointCode ?? ''}
                latitude={Number(address.lat)}
                longitude={Number(address.lon)}
                fiasCode={address.fiasCode}
                deliveryPointAddress={point?.deliveryPointAddress}
              />
            </BasicModal>
          </>
        )}
      </>
    );
  }
);
