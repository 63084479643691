import { SdekAvailableStatus, UserAuthStatus, UserRole } from "consts";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TelergamDataType } from "types/user";
import { TariffInfoI } from "types/tariffs";

export type UserState = {
  userId: string;
  isByAdmin: boolean;
  isLoading: boolean;
  errorMessage: string;
  requestStatus: number;
  telegramData: TelergamDataType;
  userRole: UserRole;
  email: string;
  errors: any;
  userAuthStatus: UserAuthStatus;
  userContactInfoStatus: boolean;
  isSdekAvailable: SdekAvailableStatus;
  tariff?: TariffInfoI;
  userTgBotStatus: boolean;
};

const initialState: UserState = {
  userId: "",
  isByAdmin: false,
  isLoading: false,
  errorMessage: "",
  telegramData: { telegramVerificationCode: "", telegramId: "" },
  requestStatus: 0,
  userRole: UserRole.Unknowe,
  email: "",
  userAuthStatus: UserAuthStatus.Unknowed,
  userContactInfoStatus: true,
  isSdekAvailable: SdekAvailableStatus.Unknowed,
  userTgBotStatus: false,
  errors: {}
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserTariff: (state, action: PayloadAction<TariffInfoI>) => {
      state.tariff = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUserError: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetUserError(state) {
      state.errorMessage = "";
    },
    setUserTelegramData: (state, action) => {
      state.telegramData = action.payload;
    },
    setUserIsByAdmin(state, action) {
      state.isByAdmin = action.payload;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserEmail: (state, action) => {
      state.email = action.payload;
    },
    setUserAuthStatus: (state, action) => {
      state.userAuthStatus = action.payload;
    },
    setUserContactInfoStatus: (state, action) => {
      state.userContactInfoStatus = action.payload;
    },
    setUserIsSDEKAvailable: (state, action) => {
      state.isSdekAvailable = action.payload;
    },
    setOwnTgBotStatus: (state, action) => {
      state.userTgBotStatus = action.payload;
    },
    setTgBotError: (state, action) => {
      state.errors.token = action.payload;
    },
  },
});

export const {
  setUserId,
  setUserLoading,
  setUserError,
  resetUserError,
  setUserTelegramData,
  setUserIsByAdmin,
  setRequestStatus,
  setUserRole,
  setUserEmail,
  setUserAuthStatus,
  setUserContactInfoStatus,
  setUserIsSDEKAvailable,
  setUserTariff,
  setOwnTgBotStatus,
  setTgBotError
} = userSlice.actions;

export default userSlice;
