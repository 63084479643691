import IconButton from '@mui/material/IconButton';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Marker, MarkerProps } from 'react-map-gl';

type CustomMarkerProps = {
  pointData: any;
  children?: JSX.Element;
  handleMarkerClick: (pointData: MarkerProps) => void;
  deliveryPointCode?: string;
};

export const CustomMarker = ({
  pointData,
  children,
  handleMarkerClick,
  deliveryPointCode,
}: CustomMarkerProps) => {
  return (
    <Marker
      offset={[-48, -24]}
      latitude={Number(pointData.location.latitude)}
      longitude={Number(pointData.location.longitude)}
    >
      <IconButton onClick={() => handleMarkerClick(pointData)}
        color={pointData.code === deliveryPointCode ? "error" : "primary"}
      >
        {children}
        <FmdGoodIcon />
      </IconButton>
    </Marker>
  );
};
