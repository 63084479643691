import * as yup from 'yup';
import heic2any from 'heic2any';
import { isUri } from 'valid-url';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import {
  CartProductCardType,
  CategoryType,
  S3ImgBase64,
  CartProductCardTypeObj,
} from 'types/products';
import { PRODUCTS_SORTING_OPTIONS } from 'consts';
import { ProductCardType, ProductPriceType } from 'types/products';
import { LinkDataType } from 'types/links';
import { BUTTON_TEMPLATE } from 'consts/customLink';
import { parseISO, format } from 'date-fns';
import { useEffect } from 'react';

export const getExtensionFile = (fileName: string) => {
  return fileName.match(/\.([^.]+)$/)?.[1];
};

export const convertHeicImage = async (file: File) => {
  const blobImage = await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: 0.7,
  });

  return blobImage as Blob;
};

export const convertToBase64 = (file: Blob): Promise<ArrayBuffer> | unknown => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getSortingOptionByType = (type: string) =>
  PRODUCTS_SORTING_OPTIONS.find((option) => option.type === type);

export const getBase64Images = async (
  rawPhotos: any
): Promise<S3ImgBase64[]> => {
  const imgBase64List: S3ImgBase64[] = [];
  for (const img of rawPhotos) {
    let fileExtension = getExtensionFile(img.name);
    let imgCopy = img;

    if (fileExtension === 'heic') {
      //TODO: можно вынести магию в константы
      imgCopy = await convertHeicImage(img);
      fileExtension = 'jpg';
    }
    const base64Img = await convertToBase64(imgCopy);

    if (base64Img && fileExtension) {
      imgBase64List.push({
        id: uuidv4(),
        imgBase64: base64Img as string,
        imgType: fileExtension,
      });
    }
  }

  return imgBase64List;
};

export const getBase64Image = async (image: any): Promise<S3ImgBase64> => {
  let fileExtension = getExtensionFile(image.name);
  let imgCopy = image;

  const imgBase64 = {
    id: uuidv4(),
    imgBase64: '',
    imgType: '',
  };

  if (fileExtension === 'heic') {
    //TODO: можно вынести магию в константы
    imgCopy = await convertHeicImage(image);
    fileExtension = 'jpg';
  }
  const base64Img = await convertToBase64(imgCopy);
  if (base64Img && fileExtension) {
    imgBase64.imgBase64 = base64Img as string;
    imgBase64.imgType = fileExtension;
  }

  return imgBase64;
};

export const getCategoriesFromProducts = (
  products: ProductCardType[]
): string[] => {
  const categories = new Set<string>();
  products.forEach((product) => {
    if (Array.isArray(product.category)) {
      product.category.forEach((category) => categories.add(category.title));
    } else {
      categories.add(product.category.title);
    }
  });
  return Array.from(categories);
};

//TODO: убрать на бэк!!!!!!!
export const getFormatedCategoriesList = (
  categories: CategoryType | CategoryType[]
) => {
  if (!Array.isArray(categories)) {
    return [categories];
  }

  return categories;
};

export const validationSchemaUrl = yup.object({
  shortUrl: yup
    .string()
    .test('is-url-valid', 'Не корректный URL', (value) =>
      Boolean(isUri(`https://linkwi.ru/${value}`))
    ),
});

export const scrollTopPage = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const getPhoneNumberForUrl = (phone: string) => {
  return phone.replace(/\+/g, '%2B');
};

export const getCurrentPrice = (
  product: ProductCardType | CartProductCardType,
  count: number
) => {
  if (product.price.multiplePrice) {
    const prices = Object.values(product.price.multiplePrice);

    prices.sort(
      (multiplePrice1, multiplePrice2) =>
        multiplePrice2.endAmount - multiplePrice1.endAmount
    );

    for (let price of prices) {
      if (
        count >= Number(price.startAmount) &&
        count <= Number(price.endAmount)
      ) {
        return Number(price.price);
      }
    }

    if (prices.length > 0 && count > prices[0].endAmount) {
      return prices[0].price;
    }
  }

  return product.price.currentPrice;
};

export const getNextProductCountAndPrice = (
  product: ProductCardType,
  currentCount: number
): { nextPrice: number; nextCount: number } | null => {
  const multiplePrice = product.price.multiplePrice;

  if (!multiplePrice) {
    return null;
  }

  const prices = Object.values(multiplePrice).sort(
    (a, b) => a.endAmount - b.endAmount
  );

  let nextPriceInfo = null;

  for (let i = 0; i < prices.length; i++) {
    const price = prices[i];
    if (currentCount >= price.startAmount && currentCount < price.endAmount) {
      if (i + 1 < prices.length) {
        const nextPrice = prices[i + 1];
        nextPriceInfo = {
          nextPrice: Number(nextPrice.price),
          nextCount: Number(nextPrice.startAmount) - currentCount,
        };
        break;
      }
    } else if (currentCount < price.startAmount) {
      nextPriceInfo = {
        nextPrice: Number(price.price),
        nextCount: Number(price.startAmount) - currentCount,
      };
      break;
    }
  }

  return nextPriceInfo;
};

export const getProductsQuantity = (
  products: CartProductCardTypeObj,
  id: string
) =>
  Object.values(products)
    .filter((el: CartProductCardType) => el.productId === id)
    .reduce((a, b) => a + b.count, 0);

export const getMinPrice = (price: ProductPriceType) => {
  const arr = [
    ...Object.values(price.multiplePrice).map((x) => x.price),
    price.currentPrice,
  ].filter((n) => n > 0);

  const min = arr.sort((a, b) => a - b);

  return Math.round(Number(min[0]));
};

export function handleEnterKeyDown(event: any) {
  if (event.key === 'Enter') {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form[index + 2].focus();
    event.preventDefault();
  }
}

export const getDiscount = (
  cartProducts: CartProductCardTypeObj,
  finalPrice: number,
  id: string
) => {
  const copyCartProducts = cloneDeep(cartProducts);

  Object.values(copyCartProducts)
    .filter((el) => el.productId === id)
    .forEach((el) => (el.finalPrice = finalPrice));

  return copyCartProducts;
};

export const getLinksByProjectId = (
  links: LinkDataType[],
  projectId: string
) => {
  return links.filter((link) => link.projectId === projectId);
};

export const getDomainUrl = () => {
  return window.location.origin;
};

export const formatDateString = (dateString: string) => {
  return format(parseISO(dateString), 'dd.MM.yyyy');
};

export const getCategoriesString = (categories: any) => {
  if (!Array.isArray(categories)) {
    categories = [categories];
  }
  return categories.map((category: CategoryType) => category.title).join(',');
};

export const getDateForMobile = (date: Date, mobileFlag: boolean) => {
  return moment(date).format(`${mobileFlag ? 'DD.MM.YY' : 'DD.MM.YYYY HH:mm'}`);
};

export const getFormatDateForViewPort = (date: Date, isMobile: boolean) => {
  return moment(date).format(`${isMobile ? 'DD.MM.YY' : 'DD.MM.YYYY HH:mm'}`);
};

export const cutTextByLength = (text: string, length: number) => {
  if (text.length <= length) {
    return text;
  }

  return `${text.slice(0, length)}...`;
};

export const searchProductsByName = (
  products: ProductCardType[],
  searchValue: string
) => {
  return products.filter((product) =>
    product.name.toLowerCase().includes(searchValue.toLowerCase())
  );
};

export const getButtonsTemplate = () => {
  const buttonsBlockId = uuidv4();

  return {
    ...BUTTON_TEMPLATE,
    blockId: buttonsBlockId,
  };
};

export const getHtmlTextLength = (htmlText: string) => {
  const element = document.createElement('div');
  element.innerHTML = htmlText;
  return element.innerText.length;
};

export const getListByObj = <T>(list: T[], key: string) => {
  const result: any = {};

  if (list) {
    list.forEach((el: any) => {
      result[el[key]] = el;
    });
  }

  return result;
};

export const openPDF = (data: Blob): string => {
  var file = new Blob([data], { type: 'application/pdf' });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);

  return fileURL;
};

export const addRussianCountryCodeIfNeeded = (value: string) => {
  // Если строка уже начинается с +7, ничего не делаем
  if (value.startsWith('+7')) {
    return value;
  }
  
  // Если строка начинается с 8 или 7 (без +), заменяем на +7
  if (value.startsWith('8') || value.startsWith('7')) {
    return '+7' + value.substring(1);
  }
  
  // В противном случае, предполагаем, что номер локальный и добавляем +7
  return '+7' + value;
};