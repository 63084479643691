import { ReactNode } from 'react';
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
} from '@mui/material';
import 'moment/locale/ru';
import Parser from 'html-react-parser';
import { Rating } from 'react-simple-star-rating';
import { ReviewType } from 'entities/Reviews';
import { getFormatedDate } from 'utils/tariff';
import ImgList from 'shared/ui/ImgList/ImgList';

const ProductReviewCard = ({
  data,
  action = null,
}: {
  data: ReviewType;
  action?: ReactNode | null;
}) => {
  const { rating, createdAt, reviewText, replyReviewText, customerName, reviewImages } = data;  

  return (
    <Card sx={{ marginTop: 1 }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              width: 'min-content',
              borderRadius: 0,
              backgroundColor: 'transparent',
            }}
            aria-label='rating'
          >
            <Rating
              initialValue={rating}
              size={22}
              readonly
              tooltipStyle={{
                backgroundColor: 'white',
                color: 'inherit',
                fontSize: '14px',
                marginLeft: 0,
                paddingTop: 0,
              }}
            />
          </Avatar>
        }
        title={customerName || 'покупатель'}
        subheader={getFormatedDate(new Date(createdAt || ''))}
      />
      <CardContent sx={{ paddingBlock: 0 }}>
        {reviewText && <span>{reviewText}</span>}

        {reviewImages && <ImgList imgLinkList={reviewImages} />}

        {replyReviewText && (
          <>
            <Typography variant='body1' sx={{ pt: '8px' }}>
              <strong>Ответ продавца: </strong>
              {Parser(replyReviewText)}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductReviewCard;
