import { memo } from 'react';
import style from './OrderIdCell.module.scss';
import { Button, IconButton } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';

const OrderIdCell = ({ cell, row }: any) => {
  const navigate = useNavigate();
  const orderId = row.original.orderId;

  const handleEditButtonClick = () => {
    navigate(`/dashboard/orders/order/${orderId}`);
  };

  return (
    <div>
      <Button
        onClick={handleEditButtonClick}
        variant='text'
        endIcon={<LaunchIcon />}
      >
        #{orderId.slice(30, 36)}
      </Button>
    </div>
  );
};

export default memo(OrderIdCell);
