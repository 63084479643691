import { LinksBlock } from 'consts/links';

import { S3ImgLink } from './products';
import { Point, Area } from 'react-easy-crop/types';

export type LinkDataType = {
  name: string;
  type: LinkType;
  content: string;
  linkId: string;
  userId: string;
  projectId: string;
  password: string;
  isActive: boolean;
  redirectUrl: string;
  clickCounter: number;
  createdAt: string;
  updateAt?: string;
};

export enum LinkType {
  redirect = 'redirect',
  linkwi = 'linkwi',
}

export enum SocialSourceType {
  email = 'email',
  phone = 'phone',
  whatsApp = 'whatsApp',
  telegram = 'telegram',
  youtube = 'youtube',
  vk = 'vk',
  instagram = 'instagram',
}

export type SocialLinkTypeObj = { [name: string]: SocialLinkType };

export type SocialLinkType = {
  source: SocialSourceType;
  url: string;
  colorIcon?: string;
};

export type BrandType = {
  title: string;
  url: string;
  linkColor: string;
};

export type RelatedLink = {
  name: string;
  linkId: string;
  redirectUrl: string;
};

export type LinkObjType = { [id: string]: LinkDataType };

export type LinkButtonType = {
  bg: string;
  id: string;
  urlLink: string;
  textLink: string;
  textColor: string;
  animation: string | null;
  animationSpeed: string | null;
  s3IconImg: string | null;
};

export type bannerImgCroppedParameter = {
  point: Point;
  zoom: number;
  croppedArea: Area;
};

export type GeneralBlockType = {
  blockId: string;
  bgColor: string;
  colorText: string;
  s3LogoImg: S3ImgLink | null;
  logoImgUrl: string;
  s3BgImg: S3ImgLink | null;
  bgImgUrl: string;
  description: string;
  productList: string[];
};

export type BannerBlockType = {
  blockId: string;
  s3BannerImgInitial: S3ImgLink | null;
  bannerImgInitialUrl: string;
  s3BannerImgMobile: S3ImgLink | null;
  bannerImgMobileUrl: string;
  s3BannerImgDesktop: S3ImgLink | null;
  bannerImgDesktopUrl: string;
  bannerImgMobileCroppedParameter: bannerImgCroppedParameter | null;
  bannerImgDesktopCroppedParameter: bannerImgCroppedParameter | null;
};

export type ZeroBlockType = {
  code: string;
};

export type BrandBlockType = {
  blockId: string;
  url: string;
  title: string;
  linkColor: string;
};

export type SocialLinksBlockType = {
  blockId: string;
  email: string;
  phone: string;
  whatsApp: string;
  telegram: string;
  youtube: string;
  vk: string;
  instagram: string;
  socialLinksColor: string;
};

export type ServiceBlockType = {
  metaTitle?: string;
  metaDesc?: string;
  yandexMetrikaKey?: string;
};

export type ButtonsBlockType = {
  blockId: string;
  bg: string;
  urlLink: string;
  textLink: string;
  animation: string;
  iconImgUrl: string;
  textColor: string;
  borderColor: string;
  animationSpeed: string;
  borderThickness: number;
};

export type CreateLinkWiTypes = {
  projectId: string;
  redirectUrl?: string;
  content?: any; // TODO: поправить типы
  linkId: string;
  name: string;
  type: LinkType;
  password: string;
  sellerTgChannel?: number;
  privateChannelLink?: string;
  captchaToken?: string;
};

export type UpdateLinkTypes = {
  redirectUrl?: string;
  newLinkId?: string;
  content?: any;
  linkId: string;
  name: string;
  type: LinkType;
  password?: string;
  sellerTgChannel?: number;
  privateChannelLink?: string;
};

export interface UserTgChannelItemI {
  id: number;
  userId: string;
  botId: number;
  channelId: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}

export interface ILink {
  shortUrl: string;
  name: string;
  service: ServiceBlockType;
  socialLinksColor?: string;
  password: string;
  sellerTgChannel?: number | null;
  privateChannelLink?: string;
  linksButtonStyles: ButtonSettingType;
  socialLinks: SocialLinksBlockType;
  brand: BrandType;
  general: GeneralBlockType;
  banner: BannerBlockType;
  zeroBlock: ZeroBlockType;
}

export type ButtonSettingType = {
  [id: string]: { content: ButtonsBlockType; order: number; isNew?: boolean };
};

export const defaultProductCardPerPage = 16;
export const defaultProductCardListPerPage = 32;

export enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}
