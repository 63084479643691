import React, { memo, useEffect, useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { ProductViewTypeEnum } from 'entities/CustomLink';

import placeholder from './600x600.svg';

// Определение типа для элемента слайдера
type SlideItem = {
  src: string;
  alt?: string;
};

// Пропсы для компонента ImageSlider
type ImageSliderProps = {
  slides: SlideItem[];
  viewType: ProductViewTypeEnum;
};

const ImageSlider: React.FC<ImageSliderProps> = ({ slides, viewType }) => {
  const splideRef = useRef(null);

  useEffect(() => {
    // Обновляем слайдер при смене типа отображения
    if (splideRef.current) {
      // @ts-ignore
      splideRef.current.splide.refresh();
    }
  }, [viewType]);

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = placeholder;
  };

  return (
    <Splide
      ref={splideRef}
      options={{
        type: 'loop',
        lazyLoad: 'nearby',
        pagination: false,
        arrows: false,
      }}
    >
      {slides.map((slide, index) => (
        <SplideSlide key={index}>
          <img
            src={slide.src || placeholder}
            alt={slide.alt || `Slide ${index + 1}`}
            onError={handleImageError}
            data-splide-lazy={slide.src}
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default memo(ImageSlider);
