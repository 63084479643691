import { Stack, Typography, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Cart from "components/Cart/Cart";

const CartPage = () => {
  const matches = useMediaQuery("(max-width:650px)");
  return (
    <Container
      className="cart-main__container"
      disableGutters={matches}
      maxWidth={false}
    >
      <Stack className="cart-main__wrapper">
        <Typography className="cart-main__header" component="h1">
          Корзина
        </Typography>
        <section className="cart-main">
          <Cart />
        </section>
      </Stack>
    </Container>
  );
};

export default CartPage;
