import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function ImgList({
  imgLinkList,
}: {
  imgLinkList: string[];
}) {
  if (!imgLinkList || !imgLinkList.length) {
    return null;
  }
  return (
    <ImageList cols={3} rowHeight={120} variant='quilted'> 
      {imgLinkList.map((item: string, ind: number) => (
        <ImageListItem key={item}>
          <img
            src={item}
            style={{
                objectFit: 'contain'
            }}
            alt={`Картинка ${ind}`}
            loading='lazy'
            width={'120px'}
            height={'120px'}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
