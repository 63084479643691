import { AppBar, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import style from './MobileModalSlide.module.scss';
import CategorySelect from '../Filters/CategoryFilter/CategoryFilter';
import PriceFilter from '../Filters/PriceFilter/PriceFilter';
import GridIcon from '../Filters/GridIcon';
import ListIcon from '../Filters/ListIcon';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getProductViewType } from 'entities/CustomLink/model/selectors';
import { ProductViewTypeEnum } from 'entities/CustomLink/lib/types';
import { CustomLinkActions } from 'entities/CustomLink/model/slice/CustomLinkSlice';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';
import classNames from 'classnames';

interface MobileModalSlidePropsI {
  handleClose: () => void;
}

const MobileModalSlide = ({ handleClose }: MobileModalSlidePropsI) => {
  const dispatch = useAppDispatch();
  const isWhite = useIsWhiteMoreContrast();
  const productViewType = useAppSelector(getProductViewType);

  const getIconStyle = (viewType: ProductViewTypeEnum) => {
    const baseColor = isWhite ? '#121212' :'#FFFFFF';
    if (productViewType === viewType) {
      return { fill: baseColor, opacity: 1 }; // Если текущий тип отображения совпадает с типом иконки
    }
    return { fill: baseColor, opacity: 0.3 }; // Если текущий тип отображения не совпадает с типом иконки
  };

  const handleViewTypeChange = () => {
    if (productViewType === ProductViewTypeEnum.GRID) {
      dispatch(CustomLinkActions.setProductViewType(ProductViewTypeEnum.LIST));
    } else {
      dispatch(CustomLinkActions.setProductViewType(ProductViewTypeEnum.GRID));
    }
  };
  return (
    <AppBar
      className={classNames(style.wrapper, {
        [style.wrapperWhite]: isWhite,
      })}
    >
      <div className={style.header}>
        <IconButton className={style.headerButton} onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <span
          className={classNames(style.headerTitle, {
            [style.headerTitleWhite]: isWhite,
          })}
        >
          Фильтры
        </span>
      </div>
      <div
        onClick={handleViewTypeChange}
        className={style.visibilityTypeWrapper}
      >
        <div className={classNames(style.visibilityTypeItem, {
            [style.visibilityTypeItemWhite]: isWhite,
          })}>
          <GridIcon style={getIconStyle(ProductViewTypeEnum.GRID)} />
          <span>Карточки</span>
        </div>
        <div className={classNames(style.visibilityTypeItem, {
            [style.visibilityTypeItemWhite]: isWhite,
          })}>
          <ListIcon style={getIconStyle(ProductViewTypeEnum.LIST)} />
          <span>Cписок</span>
        </div>
      </div>
      <CategorySelect classname={style.categoryFilter} />
      <PriceFilter classname={style.priceFilter} />
    </AppBar>
  );
};

export default MobileModalSlide;
