import cookies from 'js-cookie';
import { useState, FC, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography, Box, Link } from '@mui/material';

import api from 'services/api';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { CustomLinkActions } from 'entities/CustomLink';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { useSearchParams } from 'react-router-dom';
import { getPrivateChannelLink } from 'entities/CustomLink';

interface ILoginLink {
  linkId: string;
}

export const LoginLink: FC<ILoginLink> = ({ linkId }) => {
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [search] = useSearchParams();
  const privateChannelLink = useAppSelector(getPrivateChannelLink);

  useEffect(() => {
    // Получение кода из URL или куки
    const urlCode = search.get('code');
    const cookieCode = cookies.get('linkCode') || '';

    // Определение активного кода с приоритетом для кода из URL
    const activeCode = urlCode || cookieCode;

    if (activeCode) {
      setPassword(activeCode);
      handleAutoSubmit(activeCode);
    }
  }, [search]);

  const handleAutoSubmit = async (code: string) => {
    setIsLoading(true);
    try {
      const data = await api.getPrivateLinkData(linkId, code);
      
      if (data.content) {
        dispatch(
          CustomLinkActions.setLinkData({
            content: data.content,
            name: data.name,
            sellerId: data.userId,
            productCount: data.productCount,
            categories: data.categories,
          })
        );
        dispatch(CustomLinkActions.setAccess(true));
        cookies.set('linkCode', code);
      }
    } catch (error) {
      const { message } = apiErrorHandler(error);
      setError(message);
      setTimeout(() => setError(''), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    await handleAutoSubmit(password);
  };

  return (
    <div style={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}>
      <Box
        sx={{
          maxWidth: '300px',
          transform: 'translateY(-10vh)',
        }}
      >
        <Typography variant='h4' sx={{ mb: '2rem' }}>
          Доступ ограничен
        </Typography>
        {privateChannelLink && (
          <>
            <Typography sx={{ mb: '10px' }}>
              Для получения доступка к каталогу подпишитесь на{' '}
              <Link href={privateChannelLink}>телеграм канал</Link> или введите
              пароль
            </Typography>
          </>
        )}
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} direction={'column'}>
            <BaseInput
              fullWidth
              id='password'
              name='password'
              label='Пароль'
              type='password'
              InputLabelProps={{ shrink: true }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={Boolean(error)}
              helperText={error}
            />

            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isLoading}
            >
              Войти
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </div>
  );
};
