import { createSlice } from '@reduxjs/toolkit';
import { ReviewType } from 'entities/Reviews';
import { postReview } from 'features/ReviewPost';
import { replyReview } from 'features/ReviewReply';

export type ReviewSliceType = {
  reviewsList: ReviewType[];
  currentReview: ReviewType | null;
  isLoading: boolean;
  productId: string;
  error: string;
};

const initialState: ReviewSliceType = {
  reviewsList: [],
  currentReview: null,
  isLoading: false,
  productId: '',
  error: '',
};

const ReviewSlice = createSlice({
  name: 'ReviewSlice',
  initialState,
  reducers: {
    setReviewProductId: (state, action) => {
      state.productId = action.payload;
    },

    setCurrentReview: (state, action) => {
      state.currentReview = action.payload;
    },

    setReviewError: (state, action) => {
      state.error = action.payload;
    },

    setReviewLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },

    setReviewsList: (state, action) => {
      state.reviewsList = action.payload;
    },

    resetReviewError: (state) => {
      state.error = '';
    },

    updateReply: (state, action) => {
      if (state.reviewsList) {
        const reviewIndex = state.reviewsList.findIndex(
          (review) => review.id === state.currentReview?.id
        );
        state.reviewsList[reviewIndex].replyReview = {
          ...state.reviewsList[reviewIndex].replyReview,
          replyReviewText: action.payload,
        };
      }
    },

    updateViewedStatus: (state, action) => {
      const reviewIndex = state.reviewsList.findIndex(
        (review) =>
          review.orderId === state.currentReview?.orderId &&
          review.productId === state.currentReview.productId
      );
      state.reviewsList[reviewIndex].isReviewViewed = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postReview.pending, (state) => {
        state.error = '';
        state.isLoading = true;
      })
      .addCase(postReview.fulfilled, (state) => {
        state.isLoading = false;
        state.productId = '';
      })
      .addCase(postReview.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default ReviewSlice;

export const {
  setReviewProductId,
  setReviewError,
  setReviewsList,
  setReviewLoadingStatus,
  resetReviewError,
  setCurrentReview,
  updateReply,
  updateViewedStatus
} = ReviewSlice.actions;
