import { UserTgChannelItemI } from 'types/links';
import { ProductCardType } from './../../types/products';
import { createSlice } from '@reduxjs/toolkit';

export type NewLinkSliceType = {
  iddList: string[];
  products: ProductCardType[] | null;
  selectedProducts: ProductCardType[] | null;
  productsCount: number;
  sellerTgChannelList: UserTgChannelItemI[] | null;
};

const initialState: NewLinkSliceType = {
  iddList: [],
  products: null,
  selectedProducts: null,
  productsCount: 0,
  sellerTgChannelList: null,
};

const NewLinkSlice = createSlice({
  name: 'NewLinkSliceType',
  initialState,
  reducers: {
    setProductsIdList: (state, action) => {
      state.iddList = action.payload;
    },

    setSellerTgChannelList: (state, action) => {
      state.sellerTgChannelList = action.payload;
    },

    setNewLinkProducts: (state, action) => {
      state.products = action.payload.products;
      state.productsCount = action.payload.productsCount;
    },

    setSelectedProducts: (state, action) => {
      if (!state.selectedProducts?.length) {
        state.selectedProducts = [action.payload.products];
        return;
      }

      state.selectedProducts = [...state.selectedProducts, ...action.payload.products];
    },
  },
});

export default NewLinkSlice;

export const { setProductsIdList, setNewLinkProducts, setSelectedProducts, setSellerTgChannelList } =
  NewLinkSlice.actions;
