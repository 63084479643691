import ModalMui from '@mui/material/Modal';
import { ReactNode, memo } from 'react';
import cls from './Modal.module.scss';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  children: ReactNode;
  showCloseButton?: boolean;
}

export const Modal = memo(
  ({ open = true, onClose, children, showCloseButton }: ModalProps) => {
    return (
      <ModalMui open={open} onClose={onClose} className={cls.modal}>
        <div className={cls.content}>
          {showCloseButton && onClose && (
            <IconButton
              onClick={onClose}
              className={cls.closeButton} // Добавьте соответствующие стили для позиционирования кнопки закрытия
            >
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </div>
      </ModalMui>
    );
  }
);
