import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { WithAdminAccess } from 'components/hoc/WithAdminAccess';
import { differenceInDays } from 'date-fns';
import { useAppSelector } from 'redux/hooks';
import { getUserTariff } from 'redux/UserSlice/selector';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => {
          if (item.isAdminAccess) {
            const NavItemLinkWithAdminAccess = WithAdminAccess(NavItem, false);
            return <NavItemLinkWithAdminAccess key={item.title} item={item} />;
          }

          return <NavItem key={item.title} item={item} />;
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, isBlank } = item;

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        target={isBlank && '_blank'}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        <ListItemText disableTypography primary={title} />

        {info && info}
        {title === 'Тарифы' && <TariffLeftDaysCount />}
      </StyledNavItem>
    </>
  );
}

function TariffLeftDaysCount() {
  const tariffData = useAppSelector(getUserTariff);
  
  if (!tariffData) {
    return;
  }

  const daysLeft = differenceInDays(
    new Date(tariffData?.endDate || new Date()),
    Date.now()
  );

  return <span>{daysLeft} д.</span>;
}
