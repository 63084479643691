import { ProductCardType } from "types/products";

export const ApiRoutes = {
  Projects: "projects",
  Product: "products",
  ProductData: "product",
  AllProducts: "products/all",
  ProductSequence: "products/sequence",
  GetAllProjectLinks: "link/all",
  GetAllLinkwiLinks: "link/links",
  Links: "link",
  User: "user",
  UserContact: "user/contact",
  UserBot: "bot",
  Orders: "order",
  Cart: "cart", // нигде не используется
  Analytics: "analytics",
  AnalyticsOrders: "analytics/orders",
  Reviews: "customer/review",
  Review: "review",
  ReviewViewedStatus: "review/viewed",
  Verification: "auth/user/verification",
  SendCode: "auth/user/code",
  RestorePassword: "auth/user/restore",
  Users: "user/all",
  Login: "auth/user/signin",
  Registration: "auth/user/signup",
  Logout: "auth/user/logout",

  RegistrationCustomer: "/auth/customer/signup",
  VerificationCustomer: "auth/customer/verification",
  LoginCustomer: "auth/customer/signin",
  SendCodeCustomer: "auth/customer/code",
  RestorePasswordCustomer: "auth/customer/restore",
  Customer: "/customer",
  CustomerOrders: "/customer/order",

  SellerAllProducts: "products/seller",
  SellerAllProductsIds: "products/seller/ids",
  Addresses: "addresses",


  // Тарифы

  Tariff: "/tariff",

  //SDEK
  SdekTariffs: "cdek/tariff",
  GetOfficeList: "/cdek/offices",
  SdekOrder: "/cdek/order",
  SdekOrderDetail: "/cdek/order/detail",
  SdekOnboarding: "cdek/form",
  SdekPrintFormCreate: "cdek/print/create",
  SdekPrintForm: "cdek/print",

  // Clients
  Clients: 'clients',
  ClientsOrders: 'clients/detail',

  // Онбординг
  Onboarding: 'onboarding',

  // Учет
  Stock: 'stock',
  OrdersDashboard: 'stock/stats',
  StockOrderDateEdit: 'stock/order',
  StockProduct: 'stock/product',
  ClientSuggestions: 'stock/clients',
  ClientCreate: 'stock/clients'
};

export const PRODUCTS_SORTING_OPTIONS = [
  {
    type: "price-high-low",
    text: "Цена: по убыванию",
    function: (a: ProductCardType, b: ProductCardType) =>
      a.price?.currentPrice - b.price?.currentPrice,
  },
  {
    type: "price-low-high",
    text: "Цена: по возрастанию",
    function: (a: ProductCardType, b: ProductCardType) =>
      b.price.currentPrice - a.price.currentPrice,
  },
];

export enum UserRole {
  Unknowe = "UNKNOWE",
  User = "USER",
  Admin = "ADMIN",
  Customer = "CUSTOMER",
}

export enum UserAuthStatus {
  Unknowed = "Unknowed",
  Authorized = "Authorized",
  Unauthorized = "Unauthorized",
}

export enum SdekAvailableStatus {
  Unknowed = "Unknowed",
  IsAvailable = "IsAvailable",
  NotAvailable = "NotAvailable",
}

export const CUSTOM_LINK_ALIAS = "ваша_ссылка";

export const REGEX_VERIFY_CODE = "[0-9]{1}";

export const LinkNamesBlackList = [
  "faq",
  "signin",
  "signup",
  "reset-password",
  "new-password",
  "dashboard",
  "verification",
  "404",
  "offer",
  "agreement",
  "person",
  "cookie",
  "contacts",
];

export const DEFAULT_LENGTH_VERIFICATION_CODE = 6;

export const CUSTOMER_ROLE = "CUSTOMER";

export const LengthVerificationCode = {
  Phone: 4,
  Email: 6,
};

export enum CommunicateWithUserType {
  Phone = "phone",
  Email = "email",
}

export const TIME_DEBOUNCE_SEND_CODE = 300000;

//TODO: Дополнить
export const RequestStatusCode = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  Forbidden: 403,
  Internal: 500,
};

export const UserType = {
  Seller: "seller",
  Customer: "customer",
};

export enum ProductDisplay {
  'CARD' = 'card',
  'LIST' = 'list',
}

export const DEFAULT_PAGE = '1';

export const START_PAGE = 0;

export const AppRoutes = {
  DashboardMain: "/dashboard/main"
}

export const KeyboardKey = {
  Escape: "Escape",
  Esc: "Esc",
}