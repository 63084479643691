import { Navigate, Outlet, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';

import {
  getCustomLinkContent,
  getCustomLinkLoadingStatus,
  getAccessStatus,
} from 'entities/CustomLink';
import { LoginLink } from 'pages/CustomLink/LoginLink';
import { defaultProductCardPerPage } from 'types/links';
import { getCustomLinkData } from 'entities/CustomLink';

function CustomLinkLayout() {
  const dispatch = useAppDispatch();
  const { linkId } = useParams();
  const isLoading = useAppSelector(getCustomLinkLoadingStatus);
  const content = useAppSelector(getCustomLinkContent);
  const access = useAppSelector(getAccessStatus);

  useEffect(() => {
    if (linkId) {
      dispatch(
        getCustomLinkData({
          linkId,
          page: 1,
          rowsPerPage: defaultProductCardPerPage,
        })
      );
    }
  }, [linkId]);

  if (!access && linkId) {
    return <LoginLink linkId={linkId} />;
  }

  if (isLoading || !content) {
    return null;
  }

  if (!isLoading && !content) {
    return <Navigate to='/404' />;
  }

  if (content?.generalBlock.content.bgColor) {
    localStorage.setItem('currentPageBgColor', content?.generalBlock.content.bgColor);
  }

  return (
    <Box
      className='custom-link'
      sx={{
        background: content?.generalBlock.content.bgColor,
      }}
    >
      <Outlet />
    </Box>
  );
}

export default CustomLinkLayout;
