import { StateSchema } from "app/providers/StoreProvider";
import { SdekAvailableStatus, UserAuthStatus, UserRole } from "consts";
import { TariffInfoI } from "types/tariffs";
import { TelergamDataType } from "types/user";


export const getUserId = (state: StateSchema): string => state.user.userId;

export const getUserLoadingStatus = (state: StateSchema): boolean =>
  state.user.isLoading;

export const getUserErrorMessage = (state: StateSchema): string =>
  state.user.errorMessage;

export const getUserRequestStatus = (state: StateSchema): number =>
  state.user.requestStatus;

export const getUserTelegramData = (state: StateSchema): TelergamDataType =>
  state.user.telegramData;

export const getUserRole = (state: StateSchema): UserRole => state.user.userRole;

export const getUserEmail = (state: StateSchema): string => state.user.email;

export const getUserAuthStatus = (state: StateSchema): UserAuthStatus =>
  state.user.userAuthStatus;

export const getUserContactInfoStatus = (state: StateSchema): boolean =>
  state.user.userContactInfoStatus;

export const getUserIsByAdmin = (state: StateSchema): boolean => state.user.isByAdmin;

export const getUserIsSdekvailable = (state: StateSchema): SdekAvailableStatus => state.user.isSdekAvailable;

export const getUserTariff = (state: StateSchema): TariffInfoI | undefined => state.user.tariff;

export const getUserTgBotStatus = (state: StateSchema): boolean =>
  state.user.userTgBotStatus;

export const getUserErrors = (state: StateSchema) =>
  state.user.errors;
