import { Skeleton } from '@mui/material';
import {
  getIsOrdersDashboardLoading,
  getOrdersDashboardStats,
} from 'features/OrdersDashboard/model/selectors/orderDashboardSelectors';
import { useAppSelector } from 'redux/hooks';

import style from './OrderProductsWidget.module.scss';
import { formatAmount } from 'utils/formatAmount';

const OrderProductsWidget = () => {
  const isLoading = useAppSelector(getIsOrdersDashboardLoading);
  const { ordersCount, soldProductsCount, averageCheck, averageOrderFill } =
    useAppSelector(getOrdersDashboardStats);

  if (isLoading) {
    return (
      <div className={style.wrapper}>
        <Skeleton width={'100%'} height={'100%'} />
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <div className={style.itemWrapper}>
        <p className={style.header}>Кол-во заказов</p>
        <p className={style.value}>{ordersCount} шт</p>
      </div>

      <div className={style.itemWrapper}>
        <p className={style.header}>Кол-во проданных товаров</p>
        <p className={style.value}>{soldProductsCount} шт</p>
      </div>

      <div className={style.itemWrapper}>
        <p className={style.header}>Средний чек</p>
        <p className={style.value}>{formatAmount(averageCheck)}</p>
      </div>

      <div className={style.itemWrapper}>
        <p className={style.header}>Средняя наполненность заказа</p>
        <p className={style.value}>{averageOrderFill} шт</p>
      </div>
    </div>
  );
};

export default OrderProductsWidget;
