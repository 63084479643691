import { format, parse } from 'date-fns';
import { MRT_ColumnDef, type MRT_Cell } from 'material-react-table';
import { Rating } from 'react-simple-star-rating';
import { OrderType } from 'types/order';
import { getFormatedDate } from 'utils/tariff';

const reviewsTableColumns: (data: any) => MRT_ColumnDef<OrderType>[] = (
  data: any
) => {
  return [
    {
      accessorKey: 'createdAt',
      header: 'Дата',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const formatedDate = getFormatedDate(date);
        return <>{formatedDate}</>;
      },
    },
    {
      accessorKey: 'isReviewViewed',
      header: 'статус',
      Cell: ({ cell, row }) => {
        if (row.original?.replyReview?.replyReviewText) return <span>обработан</span>;

        return <span>{cell.getValue<boolean>() ? 'просмотрен' : 'новый'}</span>;
      },
    },
    {
      accessorKey: 'productId',
      header: 'товар',
    },
    {
      accessorKey: 'rating',
      header: 'Оценка',
      Cell: ({ cell }) => (
        <>
          <Rating initialValue={cell.getValue<number>()} size={20} readonly />
        </>
      ),
    },
  ];
};

export { reviewsTableColumns };
