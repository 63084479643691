import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  resetReviewError,
  setReviewError,
  setReviewLoadingStatus,
  updateViewedStatus,
} from './ReviewSlice';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { setReviewsList } from './ReviewSlice';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { ApiRoutes } from 'consts';

export const updateReviewViewed = createAsyncThunk<
  void,
  { orderId: string; productId: string },
  ThunkConfig<string>
>(
  'review/updateReviewViewed',
  async ({ orderId, productId }, { dispatch, extra: { api } }) => {
    dispatch(setReviewLoadingStatus(true));
    dispatch(resetReviewError());
    try {
      await api.put(ApiRoutes.ReviewViewedStatus, {
        orderId,
        productId,
      });
      dispatch(setReviewLoadingStatus(false));
      dispatch(updateViewedStatus(true));
    } catch (error) {
      dispatch(setReviewLoadingStatus(false));
      const { message, status } = apiErrorHandler(error);
      dispatch(setReviewError(`${status} ${message}`));
      setTimeout(() => {
        dispatch(resetReviewError());
      }, 3000);
    }
  }
);
