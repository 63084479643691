import { IconButton, Link } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import style from './OrderHeader.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getCurrentOrderData } from '../../model/selectors/stockOrderEditSelectors';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { OrderSliceActions } from '../../model/slice/stockOrderEditSlice';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StatusSelect from '../OrderStatus/OrderStatus';
import ExportOrderData from '../ExportOrderData/ExportOrderData';
import { updateOrderDate } from 'features/Orders/model/services/updateOrderDate';

const OrderHeader = () => {
  const dispatch = useAppDispatch();
  const { orderId, createdAt } = useAppSelector(getCurrentOrderData);

  const handleOrderDateChange = (newValue: Date | null) => {
    if (!newValue || !orderId) {
      return;
    }

    dispatch(
      updateOrderDate({
        orderId,
        newDate: newValue,
      })
    );
  };

  return (
    <div className={style.headerWrapper}>
      <div className={style.title}>
        <IconButton LinkComponent={Link} href='/dashboard/orders'>
          <ArrowBackIcon />
        </IconButton>

        <p className={style.header}>
          {orderId ? `Заказ #${orderId.slice(30, 36)}` : 'Новый заказ'}{' '}
        </p>
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <DateTimePicker
          sx={{
            width: '200px',
          }}
          slotProps={{ textField: { variant: 'standard' } }}
          label='Дата заказа'
          value={createdAt}
          onChange={handleOrderDateChange}
        />
      </LocalizationProvider>
      <StatusSelect />
      <ExportOrderData />
    </div>
  );
};

export default OrderHeader;
