import { memo } from 'react';
import { sumBy } from 'lodash';
import GradeIcon from '@mui/icons-material/Grade';

import { ReviewType } from 'entities/Reviews';
import style from './ProductReviewsSmallWidget.module.scss';
import { getReviewWord } from 'entities/Reviews/lib/utils';
import classNames from 'classnames';
import ReviewsIcon from './ReviewsIcon';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

const ProductReviewsSmallWidget = ({ reviews }: { reviews: ReviewType[] }) => {
  const reviewsCount = reviews?.length || 0;
  const averageRating = sumBy(reviews, 'rating') / reviewsCount;

  const isWhite = useIsWhiteMoreContrast();

  if (!reviewsCount) {
    return (
      <div className={style.productCardReviewsWrapper}>
        <div
          className={classNames(style.productCardReviewsEmptyCount, {
            [style.productCardReviewsEmptyCountWhite]: isWhite,
          })}
        >
          <ReviewsIcon fill={isWhite ? '#000000' : '#ffffff'} />
          <span>Нет отзывов</span>
        </div>
      </div>
    );
  }

  return (
    <div className={style.productCardReviewsWrapper}>
      <div
        className={classNames(style.productCardReviewsRating, {
          [style.productCardReviewsRatingWhite]: isWhite,
        })}
      >
        <GradeIcon
          sx={{
            color: '#FEB601',
          }}
        />
        <span>{averageRating}</span>
      </div>
      <div
        className={classNames(style.productCardReviewsCount, {
          [style.productCardReviewsCountWhite]: isWhite,
        })}
      >
        <ReviewsIcon fill={isWhite ? '#000000' : '#ffffff'} />
        <span>{getReviewWord(reviewsCount)}</span>
      </div>
    </div>
  );
};

export default memo(ProductReviewsSmallWidget);
