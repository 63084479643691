import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiRoutes } from 'consts';
import {
  deleteLink,
  setLinks,
  setIsLoadingLinks,
  setLinkError,
  resetLinkError,
  updateLink,
} from './LinkSlice';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const fetchLinkAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>('links/fetchLink', async (_args, { dispatch, extra: { api } }) => {
  dispatch(setIsLoadingLinks(true));
  dispatch(resetLinkError());
  try {
    const { data } = await api.get(
      `${ApiRoutes.GetAllProjectLinks}?projectId=${_args}`
    );

    if (data) {
      dispatch(setLinks(data));
      dispatch(setIsLoadingLinks(false));
    }
  } catch (error) {
    const { message, status } = apiErrorHandler(error);
    dispatch(setLinkError(`${status} ${message}`));
    dispatch(setIsLoadingLinks(false));
    setTimeout(() => {
      dispatch(resetLinkError());
    }, 3000);
  }
});

export const DeleteLinkAction = createAsyncThunk<
  void,
  undefined,
  ThunkConfig<string>
>('links/DeleteLink', async (_args, { dispatch, getState, extra: { api } }) => {
  dispatch(setIsLoadingLinks(true));
  dispatch(resetLinkError());
  try {
    const state = getState();

    if (!state.LinkSlice.currentLink) {
      return;
    }

    const linkId = state.LinkSlice.currentLink.linkId;
    const response = await api.delete(ApiRoutes.Links, {
      data: { linkId },
    });

    if (response.status === 200) {
      dispatch(deleteLink(state.LinkSlice.currentLink.linkId));
    }

    dispatch(setIsLoadingLinks(false));
  } catch (error) {
    dispatch(setIsLoadingLinks(false));
    const { message, status } = apiErrorHandler(error);
    dispatch(setLinkError(`${status} ${message}`));
    dispatch(setIsLoadingLinks(false));
    setTimeout(() => {
      dispatch(resetLinkError());
    }, 3000);
  }
});

export const updateLinkAction = createAsyncThunk<
  void,
  { name: string },
  ThunkConfig<string>
>(
  'links/updateLink',
  async ({ name }, { dispatch, getState, extra: { api } }) => {
    dispatch(setIsLoadingLinks(true));
    dispatch(resetLinkError());
    try {
      const state = getState();

      if (!state.LinkSlice.currentLink) {
        return;
      }

      const linkId = state.LinkSlice.currentLink.linkId;

      const { data } = await api.put(ApiRoutes.Links, { name, linkId });

      if (data) {
        dispatch(updateLink({ name, linkId }));
        dispatch(setIsLoadingLinks(false));
      }
    } catch (error) {
      dispatch(setIsLoadingLinks(false));
      const { message, status } = apiErrorHandler(error);
      dispatch(setLinkError(`${status} ${message}`));

      setTimeout(() => {
        dispatch(resetLinkError());
      }, 3000);
    }
  }
);
