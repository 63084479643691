import { format } from 'date-fns';

import ClientCell from './ClientCell/ClientCell';
import { formatAmount } from 'utils/formatAmount';
import OrderIdCell from './OrderIdCell/OrderIdCell';

const StockTableColumns = () => {
  return [
    {
      accessorKey: 'createdAt',
      header: 'Дата',
      enableEditing: false,
      size: 60,
      Cell: ({ cell }: any) => {
        const dateValue = cell.getValue(); // Предполагаем, что dateValue всегда есть
        const formattedDate = format(new Date(dateValue), 'dd.MM.yyyy');
        const formatTime = format(new Date(dateValue), 'HH:mm');
        return (
          <>
            <p>{formattedDate}</p>
            <p>{formatTime}</p>
          </>
        );
      },
    },
    {
      accessorKey: 'orderId',
      header: 'Заказ',
      size: 60,
      enableEditing: false,
      Cell: ({ cell, row }: any) => <OrderIdCell cell={cell} row={row} />,
    },
    {
      accessorKey: 'name',
      header: 'Клиент',
      enableEditing: false,
      size: 300,
      Cell: ({ cell, row }: any) => <ClientCell cell={cell} row={row} />,
    },
    // {
    //   accessorKey: 'finalPrice',
    //   header: 'Цена 1 единицы',
    //   enableEditing: true,
    //   muiEditTextFieldProps,
    //   size: 150,
    // },
    {
      accessorKey: 'totalOrderAmount',
      header: 'Сумма',
      enableEditing: false,
      size: 120,
      Cell: ({ cell, row }: any) => {
        const finalPrice = row.original?.totalOrderAmount;

        return <>{formatAmount(Math.ceil(finalPrice))}</>;
      },
    },
    {
      accessorKey: 'costPriceTotal',
      header: 'с/с',
      enableEditing: false,
      size: 80,
      Cell: ({ cell, row }: any) => {
        const costPriceTotal = row.original?.costPriceTotal;

        return <>{formatAmount(Math.ceil(costPriceTotal))}</>;
      },
    },
    {
      accessorKey: 'grossRevenue',
      header: 'Прибыль',
      enableEditing: false,
      size: 170,
      Cell: ({ cell, row }: any) => {
        const costPrice = row.original?.costPriceTotal;
        const finalPrice = row.original?.totalOrderAmount;

        return <>{formatAmount(Math.ceil(finalPrice - costPrice))}</>;
      },
    },
    {
      accessorKey: 'status',
      header: 'Статус',
      enableEditing: false,
      size: 100,
    },
  ];
};

export default StockTableColumns;
