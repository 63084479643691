export interface DimensionsType {
  weightMin?: number;
  weightMax?: number;
  height?: number;
  width?: number;
  depth?: number;
}

export interface IAutocomplitingAddress {
  value: string;
  fiasCode: string;
  lat: string;
  lon: string;
}

export enum PickupLocationTypeEnum {
  'PVZ' = 'PVZ',
  'POSTAMAT' = 'POSTAMAT',
}

export const locationTemplate = {
  value: '',
  fiasCode: '',
  postalCode: null,
  lat: '',
  lon: '',
};

export interface DeliveryPointI {
  code: string;
  name: string;
  uuid: string;
  address_comment: string;
  nearest_station: string;
  nearest_metro_station: string;
  work_time: string;
  phones: {
    number: string;
  }[];

  email: string;
  note: string;
  type: 'PVZ' | 'POSTAMAT';
  owner_code: string;
  take_only: false;
  is_handout: true;
  is_reception: true;
  is_dressing_room: true;
  is_ltl: false;
  have_cashless: true;
  have_cash: true;
  allowed_cod: true;
  office_image_list: {
    url: string;
  }[];
  work_time_list: {
    day: number;
    time: string;
  }[];
  weight_min: number;
  weight_max: number;
  location: {
    address: string;
    country_code: string;
    region_code: number;
    region: string;
    city_code: number;
    city: string;
    fias_guid: string;
    postal_code: string;
    longitude: number;
    latitude: number;
    address_full: string;
  };
  fulfillment: boolean;
}

export interface PointType {
  lat: number;
  lon: number;
  deliveryPointCode: string;
  deliveryPointName: string;
  deliveryPointAddress: string;
  deliveryPointOpenHours: string;
  pickupType: string;
}
