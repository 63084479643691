import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_RowData,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { memo } from 'react';

interface TableProps {
  columns: MRT_ColumnDef<MRT_RowData, any>[];
  data: MRT_RowData[];
}
export const Table = memo((props: any) => {
  const { columns, data, ...other } = props;

  const table = useMaterialReactTable({
    columns,
    data,
    localization: MRT_Localization_RU,
    initialState: { pagination: { pageSize: 20, pageIndex: 0 }, sorting: [{ id: 'createdAt', desc: true }] },
    ...other,
  });
  return <MaterialReactTable table={table} />;
});
