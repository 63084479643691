import {
  MRT_ShowHideColumnsButton,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import columns from './OrdersTableColumns';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getIsStockDataLoading,
  getStockDataList,
} from '../../model/selectors/stockSelectors';
import { getStockData } from '../../model/services/getStockData';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DetailProductsPanel from './DetailProductsPanel/DetailProductsPanel';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';

const OrdersTable = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getStockData());
  }, [dispatch]);

  const navigate = useNavigate();
  const stockData = useAppSelector(getStockDataList);
  const isLoading = useAppSelector(getIsStockDataLoading);

  const table = useMaterialReactTable({
    columns: columns(),
    data: stockData || [],
    state: {
      isLoading,
    },
    editDisplayMode: 'table',
    positionExpandColumn: 'last',
    localization: MRT_Localization_RU,
    muiTablePaperProps: () => ({
      sx: () => ({
        backgroundColor: 'rgb(255, 255, 255)',
      }),
    }),
    muiDetailPanelProps: () => ({
      sx: () => ({
        backgroundColor: 'rgb(244, 246, 248)',
      }),
    }),

    renderDetailPanel: ({ row }) => <DetailProductsPanel row={row} />,
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Button onClick={() => navigate('/dashboard/orders/order')}>
          Добавить заказ
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
      </>
    ),
  });

  return <MaterialReactTable table={table}/>;
};

export default OrdersTable;
