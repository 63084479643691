import {
  getCurrentOrderData,
  getIsAddProductModalVisible,
  getIsDeleteProductModalVisible,
} from '../../model/selectors/stockOrderEditSelectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import sum from 'lodash/sum';
import style from './OrderProducts.module.scss';
import { Button } from '@mui/material';
import AddProductModal from '../AddProductModal/AddProductModal';
import { OrderSliceActions } from '../../model/slice/stockOrderEditSlice';
import { formatAmount } from 'utils/formatAmount';
import OrderProductsTable from '../OrderProductsTable/OrderProductsTable';
import DeleteProductModal from '../DeleteProductModal/DeleteProductModal';

const OrderProducts = () => {
  const dispatch = useAppDispatch();
  const { orderProducts, orderId } = useAppSelector(getCurrentOrderData);
  const isModalOpen = useAppSelector(getIsAddProductModalVisible);
  const isDeleteProductModalVisible = useAppSelector(
    getIsDeleteProductModalVisible
  );
  
  const totalAmount = sum(
    orderProducts
      ?.flatMap((el) => el.orderProduct)
      .map((el) => el.finalPrice * el.count)
  );

  const handleModalClose = () => {
    dispatch(OrderSliceActions.setIsAddProductModalVisible(false));
  };

  const handleDeleteModalClose = () => {
    dispatch(OrderSliceActions.setProductDataToDelete({
      productId: '',
      orderId: ''
    }));
    dispatch(OrderSliceActions.setIsDeleteProductModalVisible(false));
  };

  return (
    <>
      <div className={style.wrapper}>
        <p className={style.header}>Товары</p>
        <div>
          {Boolean(orderProducts?.length) && orderId ? (
            <OrderProductsTable
              // @ts-ignore
              orderProducts={orderProducts?.map((el) => ({
                ...el.orderProduct,
                s3ImgList: el.s3ImgList,
                name: el.name,
                productId: el.productId,
                // @ts-ignore
                costPrice: el.costPrice,
              }))}
              orderId={orderId}
            />
          ) : (
            <p>Товаров нет</p>
          )}
        </div>
        <div className={style.footer}>
          <Button
            onClick={() =>
              dispatch(OrderSliceActions.setIsAddProductModalVisible(true))
            }
            variant='outlined'
          >
            Добавить товар
          </Button>
          <div className={style.totalAmountWrapper}>
            <p>
              Итого
              <span>{formatAmount(totalAmount)}</span>
            </p>
          </div>
        </div>
      </div>
      <AddProductModal
        isOpen={isModalOpen}
        handleModalClose={handleModalClose}
      />
      <DeleteProductModal
        isOpen={isDeleteProductModalVisible}
        handleModalClose={handleDeleteModalClose}
      />
    </>
  );
};

export default OrderProducts;
