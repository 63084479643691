import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { getCategories } from '../../../model/selectors';
import {
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import styles from './CategoryFilter.module.scss';
import classNames from 'classnames';
import useSyncSearchWithUrl from 'entities/CustomLink/hooks/useSyncSearchWithURL';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

interface CategortSelectPropsI {
  classname?: string;
}

const CategorySelect = ({ classname }: CategortSelectPropsI) => {
  const { categoryFilter, updateCategory } = useSyncSearchWithUrl();
  const isWhite = useIsWhiteMoreContrast(); // Определяем контрастность
  const categories = useAppSelector(getCategories); // Получаем список категорий
  const [selectedCategories, setSelectedCategories] = useState<string[]>(categoryFilter);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
    
    // @ts-ignore
    updateCategory(value);
  };

  const renderValue = (selected: any) => {
    if (selected.length === 0) {
      return 'Все категории';
    }
    return selected.join(', ');
  };

  return (
    <Select
      multiple
      value={selectedCategories}
      onChange={handleChange}
      displayEmpty
      className={classNames(styles.select, {
        [styles.selectWhite]: isWhite,
      }, classname)}
      input={<OutlinedInput />}
      renderValue={(sel) => renderValue(sel)}
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: isWhite
            ? 'rgba(0,0,0, 0.3) !important'
            : 'rgba(255,255,255, 0.3) !important',
        },
        '.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: isWhite
            ? 'rgba(0,0,0, 0.5) !important'
            : 'rgba(255,255,255, 0.5) !important',
        },
        width: 250, // Установи ширину, соответствующую твоим нуждам
        m: 1,
      }}
    >
      {categories.map((category) => (
        <MenuItem key={category} value={category}>
          <ListItemText primary={category} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default CategorySelect;
