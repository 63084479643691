import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import classNames from 'classnames';
import { memo, useCallback} from 'react';

import CartIcon from './CartIcon';
import { ProductCardType } from 'types/products';
import { formatAmount } from 'utils/formatAmount';
import style from './AddProductsToCartButton.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CartActions } from 'entities/Cart';
import { useNavigate } from 'react-router-dom';
import { ProductCounter } from 'shared/ui/ProductCounter';
import { getNextProductCountAndPrice } from 'utils';
import { getProductViewType } from 'entities/CustomLink';
import { ProductViewTypeEnum } from 'entities/CustomLink/lib/types';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

interface AddProductsToCartButtonPropsI {
  product: ProductCardType;
  cartProductId: string;
  targetProductCartCount?: number;
  selectedProductOptions?: any[];
}

const AddProductsToCartButton = ({
  product,
  cartProductId,
  selectedProductOptions = [],
  targetProductCartCount,
}: AddProductsToCartButtonPropsI) => {
  const { price } = product;
  const { previousPrice, currentPrice } = price;

  const productViewType = useAppSelector(getProductViewType);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isWhite = useIsWhiteMoreContrast();

  const debouncedDispatch = debounce(
    (product: ProductCardType, selectedProductOptions: any, cartProductId: any) => {
      dispatch(
        CartActions.addProduct({
          ...product,
          count: product.multiplicityCount,
          options: selectedProductOptions,
          cartProductId,
          finalPrice: product.price.currentPrice,
        })
      );
    },
    50
  );

  const handleAddProductToCartClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!isEmpty(product.options)) {
      navigate(`${product.productId}`);
      return;
    }
    debouncedDispatch(product, selectedProductOptions, cartProductId);
  };

  const nextPriceAndCount = useCallback(
    () => getNextProductCountAndPrice(product, 0),
    [product]
  )();

  if (!product.isAvailable) {
    return (
      <button
        className={classNames(style.baseButton, style.buttonDisabled, {
          [style.buttonDisabledWhite]: isWhite,
        })}
      >
        <span className={style.buttonDisabledText}>Нет в наличии</span>
      </button>
    );
  }

  if (targetProductCartCount) {
    return (
      <ProductCounter
        product={product}
        cartProductId={cartProductId}
        startCount={targetProductCartCount}
      />
    );
  }

  return (
    <div className={style.wrapper}>
      <button
        className={classNames(style.baseButton, {
          [style.baseButtonList]: productViewType === ProductViewTypeEnum.LIST,
        })}
        onClick={handleAddProductToCartClick}
      >
        {previousPrice && (
          <span className={style.oldPrice}>{previousPrice}</span>
        )}
        <span className={style.currentPrice}>{formatAmount(currentPrice)}</span>
        <CartIcon />
      </button>

      {nextPriceAndCount && (
        <>
          <div
            className={classNames(style.moreTextWrapper, {
              [style.moreTextWrapperWhite]: isWhite,
            })}
          >
            <span className={style.moreText}>
              ещё {nextPriceAndCount.nextCount} до{' '}
              {formatAmount(nextPriceAndCount.nextPrice)}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(AddProductsToCartButton);
