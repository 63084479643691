import { SearchField } from 'shared/ui/SearchField';
import FiltersIcon from './FiltersIcon';
import { Ref, forwardRef, memo, useState, ReactElement, } from 'react';


import style from './MobileSearchFieldWithTools.module.scss';
import classNames from 'classnames';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Slide } from '@mui/material';
import MobileModalSlide from './MobileModalSlide';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

interface MobileSearchFieldWithToolsPropsI {
  searchValue: string;
  handleSearchChange: (newValue: string) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobileSearchFieldWithTools = ({
  searchValue,
  handleSearchChange,
}: MobileSearchFieldWithToolsPropsI) => {
  const isWhite = useIsWhiteMoreContrast();
  const [isToolModalOpen, setIsToolsModalOpen] = useState(false);

  const handleClose = () => setIsToolsModalOpen(false)

  return (
    <>
      <SearchField
        classname={style.mobileSearch}
        value={searchValue}
        handleSearchChange={handleSearchChange}
      >
        <div
          className={classNames(style.mobileSearchToolIcon, {
            [style.mobileSearchToolIconWhite]: isWhite,
          })}
          onClick={() => setIsToolsModalOpen((prev) => !prev)}
        >
          <FiltersIcon fill={isWhite ? '#000000' : '#FFFFFF'} />
        </div>
      </SearchField>

      <Dialog
        fullScreen
        open={isToolModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <MobileModalSlide handleClose={handleClose}/>
      </Dialog>
    </>
  );
};

export default memo(MobileSearchFieldWithTools);
