import { UserAuthStatus, UserRole } from "consts";
import { createSlice } from "@reduxjs/toolkit";
import { TelergamDataType } from "types/user";
import { PaymentPeriod, TariffInfoI } from "types/tariffs";

export interface PaymentSchema {
  isLoading: boolean;
  paymentPeriod: PaymentPeriod;
  selectedTariffId: number | null;
  tariffList: TariffInfoI[];
  paymentId: string;
};

const initialState: PaymentSchema = {
  isLoading: false,
  paymentPeriod: PaymentPeriod.Yearly,
  selectedTariffId: null,
  tariffList: [],
  paymentId: '',
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSelectedTariff: (state, action) => {
      state.selectedTariffId = action.payload;
    },
    setIsPaymentLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPaymentPeriod: (state, action) => {
      state.paymentPeriod = action.payload;
    },

    setTariffList: (state, action) => {
      state.tariffList = action.payload;
    },

    setPaymentId: (state, action) => {      
      state.paymentId = action.payload;
    },
  },
});

export const {
  setSelectedTariff,
  setIsPaymentLoading,
  setPaymentPeriod,
  setTariffList,
  setPaymentId
} = paymentSlice.actions;

export default paymentSlice;
