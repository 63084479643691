import cls from './VerifiedPopoverTitle.module.scss'
import DoneIcon from '@mui/icons-material/Done';

export const VerifiedPopoverTitle = () => {
  return (
    <>
      <span className={cls.Title}>Проверенный магазин</span>&nbsp;
      <DoneIcon color='success' />
    </>
  );
};
