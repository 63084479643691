import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  useMediaQuery,
} from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import CartProductListMobileShow from '../CartProductListMobileShow/CartProductListMobileShow';
import CartProductsListShow from '../CartProductsListShow/CartProductsListShow';
import CartShowUserData from '../CartShowUserData/CartShowUserData';
import CartTableHeadShow from '../CartTableHeadShow/CartTableHeadShow';
import { getCartClientData, getCartProducts } from 'entities/Cart';

type CartShowAllDataProps = {
  setActiveStep: (cb: (activeStep: number) => number) => void;
};

const CartShowAllData = ({ setActiveStep }: CartShowAllDataProps) => {
  const products = useAppSelector(getCartProducts);
  const clientData = useAppSelector(getCartClientData);
  const matches = useMediaQuery('(max-width:900px)');

  if (!products || !clientData) {
    setActiveStep((prevActiveStep) => 0);
    return null;
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
        <b className='cart-main__name cart-main__name--show-all-mobile'>
          Информация о заказе
        </b>
      </Box>

      <CartShowUserData />
      {matches ? (
        <CartProductListMobileShow products={products} />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <CartTableHeadShow />
            <TableBody>
              <CartProductsListShow products={products} />
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CartShowAllData;
