import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTS_SORTING_OPTIONS } from "consts";
import {
  CategoryType,
  ProductCardType,
} from "types/products";


export type ProductState = {
  productList: ProductCardType[] | null;
  categories: CategoryType[],
  productCount: number;
  currentProductId: string;
  currentProduct: ProductCardType | null;
  sortingMethod: string;
  isLoading: boolean;
  errorMessage: string;
  newProductModal: boolean;
};

const initialState: ProductState = {
  productList: null,
  categories: [],
  productCount: 0,
  currentProductId: '',
  currentProduct: null,
  sortingMethod: PRODUCTS_SORTING_OPTIONS[0].type,
  isLoading: false,
  errorMessage: '',
  newProductModal: false,
};

const productsPageSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    addProduct(state, action) {
      state.productList?.push(action.payload);
    },

    setProductList(state, action) {
      state.productList = action.payload;
    },

    changeProductAvailable(state, action) {
      if (state.productList) {
        const productIndex = state.productList.findIndex(
          (product) => product.productId === action.payload.productId
        );
        state.productList[productIndex].isAvailable = !state.productList[productIndex].isAvailable;
      }
    },

    setProductCount(state, action) {
      state.productCount = action.payload;
    },

    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
    },

    setCurrentProductId: (state, action) => {
      state.currentProductId = action.payload;
    },

    updateImagesCurrentProduct: (state, action) => {
      if (state.currentProduct) {
        state.currentProduct.s3ImgList = action.payload;
      }
    },

    setCategories: (state, action) => {
      state.categories = action.payload;
    },

    setSortingMethod: (state, action) => {
      state.sortingMethod = action.payload;
    },

    setIsLoadingProduct: (state, action) => {
      state.isLoading = action.payload;
    },

    setProductError: (state, action) => {
      state.errorMessage = action.payload;
    },

    resetProductError: (state) => {
      state.errorMessage = '';
    },

    updateProduct: (state, action) => {
      if (state.productList) {
        const productIndex = state.productList.findIndex(
          (product) => product.productId === action.payload.productId
        );
        state.productList[productIndex] = action.payload;
      }
    },

    deleteProduct: (state, action) => {
      const productIndex = state.productList?.findIndex(
        (product) => product.productId === action.payload
      );
      if (productIndex && productIndex > -1) {
        state.productList?.splice(productIndex, 1);
      }
      state.currentProduct = null;
    },

    setNewProductModalActive: (state, action) => {
      state.newProductModal = action.payload;
    },
  },
});

export const {
  setCurrentProduct,
  addProduct,
  setProductList,
  updateImagesCurrentProduct,
  setSortingMethod,
  setIsLoadingProduct,
  resetProductError,
  setProductError,
  updateProduct,
  deleteProduct,
  setCurrentProductId,
  setProductCount,
  setCategories,
  changeProductAvailable,
  setNewProductModalActive,
} = productsPageSlice.actions;

export default productsPageSlice;
