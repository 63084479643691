import axios, { AxiosError } from 'axios';
import { redicrectWithUpdatePage } from 'utils/routes';
import accessTokenController from './accessTokenController';
import { AxiosResponse } from 'axios';
import { ApiRoutes } from 'consts';

declare module 'axios' {
  interface HeadersDefaults {
    isRetry?: boolean;
  }
}

export interface ApiAxiosError extends AxiosError {
  errorData: {
    errorCode: number;
    message: string;
    status: number;
  };
}

const getCustomError = (error: ApiAxiosError): ApiAxiosError => {
  const customError = error;

  customError.errorData = {
    message: 'Отсуствует подключение к интернету или сервер не отвечает ',
    status: StatusCode.Internal,
    errorCode: 0,
  };

  if (customError.response) {
    const resError = customError.response as AxiosResponse;

    customError.errorData = {
      message: resError.data.message,
      status: resError.status,
      errorCode: resError.data.errorCode,
    };
  }

  return customError;
};

export const BASE_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

export enum StatusCode {
  Success = 201,
  Ok = 200,
  AccessDenie = 403,
  UnauthorizedError = 401,
  BadRequest = 400,
  NotFound = 404,
  Internal = 500,
  Forbidden = 403,
}

export const createApi = () => {
  const api = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    timeout: 45000,
  });

  api.interceptors.request.use(
    function (request) {
      const accessToken = accessTokenController.getToken();
      request.headers['Authorization'] = `Bearer ${accessToken}`;

      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    async (error: ApiAxiosError) => {
      const originalRequest = error.config;

      if (
        error?.response?.status === StatusCode.UnauthorizedError &&
        originalRequest && !originalRequest.headers.isRetry
      ) {
        api.defaults.headers.isRetry = true;

        if (originalRequest.url === ApiRoutes.Login) {
          return;
        }

        try {
          const { accessToken } = await refresh();
          accessTokenController.save(accessToken);
          
          return await api(originalRequest);
        } catch (error) {
          const apiError = error as AxiosError;
          // очень мало вероятный кейс, тк рефреш токен будет жить несколько дней
          redicrectWithUpdatePage();
        }
      }

      return await Promise.reject(getCustomError(error));
    }
  );

  return api;
};

const apiInstance = createApi();

const refresh = async (): Promise<{ accessToken: string }> => {
  const result = await axios.get(`${BASE_URL}/auth/user/refresh`, {
    withCredentials: true,
  });

  return result.data;
};

export { apiInstance, refresh };
