import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { Table } from 'shared/ui/Table/Table';
import { type MRT_Row } from 'material-react-table';
import { reviewsTableColumns } from './reviewsTableColumns';
import { ReviewType, getReviewLoadingStatus, getReviewsList, setCurrentReview } from 'entities/Reviews';
import { updateReviewViewed } from '../../model/services/updateReviewViewed';

export const ReviewsTable = () => {
  const reviewsList = useAppSelector(getReviewsList);
  const isLoading = useAppSelector(getReviewLoadingStatus);
  const dispatch = useAppDispatch();

  const onRowClick = (review: ReviewType) => {
    dispatch(setCurrentReview(review));
    if (review.isReviewViewed) {
      return;
    }
    dispatch(updateReviewViewed(review));
  };

  return (
    <Table
      data={reviewsList}
      columns={reviewsTableColumns(reviewsList)}
      state={{
        isLoading
      }}
      muiTableBodyRowProps={({ row }: { row: MRT_Row<ReviewType> }) => ({
        onClick: () => {
          onRowClick(row.original);
        },
        sx: {
          cursor: 'pointer',
        },
      })}
    />
  );
};
