import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { PaymentTypeList } from '../../model/types/payment';
import { TotalPrice } from '../TotalPrice/TotalPrice';
import { useState } from 'react';

export const OrderPayment = () => {
  const [priceCod, setPriceCod] = useState<number | null>(null);
  const [paymentType, setPaymentType] = useState<PaymentTypeList>(
    PaymentTypeList.DeliveryAndOrder
  );

  const handleChangePaymentType = (value: PaymentTypeList) => {
    setPaymentType(value);
  };

  const handleChangePriceCOD = (value: number | null) => {
    setPriceCod(value);
  };

  return (
    <FormControl component='fieldset' variant='standard' sx={{ mt: '2rem' }}>
      <FormLabel id='payment-change-group'>Тип оплаты:</FormLabel>
      <RadioGroup
        row
        aria-labelledby='payment-change-group'
        name='payment-change-group'
        value={paymentType}
        onChange={(evt) => setPaymentType(evt.target.value as PaymentTypeList)}
        sx={{ marginBottom: '10px' }}
      >
        <FormControlLabel
          value={PaymentTypeList.DeliveryAndOrder}
          control={<Radio />}
          label='Наложный платеж - покупатель оплачивает доставку/товар при получении'
        />
        {/* TODO: Временно */}
        <FormControlLabel
          value={PaymentTypeList.Free}
          control={<Radio />}
          label='Бесплатная доставка для покупателя - покупатель ничего не оплачивает'
        />
      </RadioGroup>

      {paymentType === PaymentTypeList.DeliveryAndOrder && <TotalPrice />}
    </FormControl>
  );
};
