import React from 'react';
import { styled } from '@mui/material/styles';
import cls from './BaseImageUploader.module.scss';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface BaseImageUploaderProps {
  images: File[];
  setImages: (images: File[]) => void;
}

const BaseImageUploader: React.FC<BaseImageUploaderProps> = ({
  images,
  setImages,
}) => {
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      // Обновите состояние images, добавив новые файлы
      setImages([...images, ...filesArray].slice(0, 5));
    }
  };

  const renderPhotos = (files: File[]) => {
    return files.map((file, index) => {
      const previewUrl = URL.createObjectURL(file);
      return (
        <img
          src={previewUrl}
          key={index}
          alt=''
          style={{ width: '100px', height: '100px' }}
          onLoad={() => URL.revokeObjectURL(previewUrl)}
        />
      );
    });
  };

  return (
    <div>
      <Button
        component='label'
        variant='contained'
        startIcon={<CloudUploadIcon />}
      >
        Добавить фото
        <VisuallyHiddenInput
          type='file'
          multiple
          onChange={handleImageChange}
          accept='image/*'
          disabled={images.length >= 5}
        />
      </Button>
      <div className={cls.imagePreviewList}>{renderPhotos(images)}</div>
    </div>
  );
};

export default BaseImageUploader;
