import { Box, TableCell, TableRow, Link } from "@mui/material";
import ProductOptionsString from "components/Products/ProductOptions/ProductOptionsString";
import { useAppDispatch } from "redux/hooks";
import { CartProductCardType } from "types/products";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete-product-cart.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import { CartActions } from "entities/Cart";
import { ProductCounterDeprecated } from "shared/ui/ProductCounter";

type CartProductListProps = {
  products: CartProductCardType[];
};

const CartProductList = ({ products }: CartProductListProps) => {
  const dispatch = useAppDispatch();

  const handleDeleteProductFromCart = (productId: string) => {
    dispatch(CartActions.deleteProductFromCart(productId));
  };

  return (
    <>
      {products.map((product) => (
        <TableRow
          key={product.cartProductId}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row" sx={{ pl: "24px" }}>
            <Box className="cart-main__product-name">
              {product.s3ImgList && (
                <img
                  className="cart-main__product-img"
                  src={product.s3ImgList[0].imgLink}
                  alt={product.name}
                />
              )}
              <Link
                href={product.productId}
                underline="none"
                sx={{ color: "black" }}
                target="_blank"
              >
                <p className="cart-main__product-name-text">{product.name}</p>
              </Link>
            </Box>
          </TableCell>
          <TableCell
            align="right"
            className="cart-main__product-field cart-main__product-field--price"
          >
            <p>{`${product.finalPrice} ${getSymbolFromCurrency(product.currency ?? "rub")} ×`}</p>
          </TableCell>
          <TableCell align="right" className="cart-main__product-field">
            <ProductCounterDeprecated minValue={1} startCount={product.count} cartProductId={product.cartProductId} multiplicityCount={product.multiplicityCount}/>
          </TableCell>
          <TableCell align="right" className="cart-main__product-field">
            <ProductOptionsString optionList={product.options} />
          </TableCell>
          <TableCell
            align="right"
            className="cart-main__product-field cart-main__product-field--summary-price"
          >
            {(product.finalPrice * product.count).toFixed(0)}&nbsp;{getSymbolFromCurrency(product.currency ?? "rub")}
          </TableCell>
          <TableCell
            align="right"
            sx={{ width: "50px", boxSizing: "border-box", padding: "0" }}
            className="cart-main__product-field"
          >
            <button
              className="cart-main__delete-icon"
              onClick={() => handleDeleteProductFromCart(product.cartProductId)}
            >
              <div>
                <DeleteIcon />
              </div>
            </button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default CartProductList;
