import { Skeleton } from '@mui/material';
import {
  getIsOrdersDashboardLoading,
  getOrdersDashboardStats,
} from 'features/OrdersDashboard/model/selectors/orderDashboardSelectors';
import { useAppSelector } from 'redux/hooks';

import style from './RevenueWidget.module.scss';
import { formatAmount } from 'utils/formatAmount';

const RevenueWidget = () => {
  const isLoading = useAppSelector(getIsOrdersDashboardLoading);
  const { totalCost, totalSales, profit } = useAppSelector(
    getOrdersDashboardStats
  );

  if (isLoading) {
    return (
      <div className={style.wrapper}>
        <Skeleton width={'100%'} height={'100%'} />
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <div className={style.itemWrapper}>
        <p className={style.header}>Продажи</p>
        <p className={style.value}>{formatAmount(totalSales)}</p>
      </div>

      <div className={style.itemWrapper}>
        <p className={style.header}>Себестоимость</p>
        <p className={style.value}>{formatAmount(totalCost)}</p>
      </div>

      <div className={style.itemWrapper}>
        <p className={style.header}>Валовая прибыль</p>
        <p className={style.value}>{formatAmount(profit)}</p>
      </div>
    </div>
  );
};

export default RevenueWidget;
