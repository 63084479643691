import { IAutocomplitingAddress, PointType } from 'features/sdek/NewSdekOrder';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartProductCardType, CartProductCardTypeObj } from 'types/products';
import { CartClientDataType } from 'types/user';
import { getProductsQuantity, getCurrentPrice } from 'utils';
import { getDiscount } from 'utils';

export type CartSliceType = {
  cartProducts: CartProductCardTypeObj;
  clientData: CartClientDataType | null;
  sellerId: string;
  address: IAutocomplitingAddress | null;
  point: PointType | null;
};

const defaultSellerId = localStorage.getItem('sellerId') || ''

const initialState: CartSliceType = {
  cartProducts: JSON.parse(localStorage.getItem(defaultSellerId) || 'null') || {},
  clientData: null,
  sellerId: defaultSellerId,
  address: null,
  point: null,
};

const CartSlice = createSlice({
  name: 'CartSlice',
  initialState,
  reducers: {
    setCart: (state, action: PayloadAction<CartProductCardTypeObj>) => {
      state.cartProducts = action.payload;

      localStorage.setItem(state.sellerId, JSON.stringify(state.cartProducts));
    },

    setAddress: (state, action: PayloadAction<IAutocomplitingAddress>) => {
      state.address = action.payload;
    },

    setPoint: (state, action: PayloadAction<PointType>) => {
      state.point = action.payload;
    },
    addProduct: (state, action: PayloadAction<CartProductCardType>) => {
      const { cartProductId } = action.payload;
      state.cartProducts[cartProductId] = action.payload;
      const currentProduct = state.cartProducts[cartProductId];

      const summaryCount = getProductsQuantity(
        state.cartProducts,
        currentProduct.productId
      );

      const finalPrice = getCurrentPrice(currentProduct, summaryCount);

      state.cartProducts = getDiscount(
        state.cartProducts,
        finalPrice,
        currentProduct.productId
      );

      localStorage.setItem(state.sellerId, JSON.stringify(state.cartProducts));
    },
    incProductInCart: (
      state,
      action: PayloadAction<{
        cartProductId: string;
        multiplicityCount: number;
      }>
    ) => {
      const { cartProductId, multiplicityCount } = action.payload;
      const currentProduct = state.cartProducts[cartProductId];
      currentProduct.count += multiplicityCount;

      const summaryCount = getProductsQuantity(
        state.cartProducts,
        currentProduct.productId
      );

      const finalPrice = getCurrentPrice(currentProduct, summaryCount);

      state.cartProducts = getDiscount(
        state.cartProducts,
        finalPrice,
        currentProduct.productId
      );

      localStorage.setItem(state.sellerId, JSON.stringify(state.cartProducts));
    },
    decProductInCart: (
      state,
      action: PayloadAction<{
        cartProductId: string;
        multiplicityCount: number;
      }>
    ) => {
      const { cartProductId, multiplicityCount } = action.payload;
      const cartProduct = state.cartProducts[cartProductId];
      if (cartProduct.count > 1) {
        const currentProduct = cartProduct;
        currentProduct.count -= multiplicityCount;

        const summaryCount = getProductsQuantity(
          state.cartProducts,
          currentProduct.productId
        );

        const finalPrice = getCurrentPrice(currentProduct, summaryCount);

        state.cartProducts = getDiscount(
          state.cartProducts,
          finalPrice,
          currentProduct.productId
        );

        localStorage.setItem(
          state.sellerId,
          JSON.stringify(state.cartProducts)
        );
      }
    },
    setProductCountInCart: (
      state,
      action: PayloadAction<{ productId: string; count: number }>
    ) => {
      const { productId, count } = action.payload;
      const currentProduct = state.cartProducts[productId];
      state.cartProducts[productId].count = count;
      const summaryCount = getProductsQuantity(
        state.cartProducts,
        currentProduct.productId
      );

      const finalPrice = getCurrentPrice(currentProduct, summaryCount);

      state.cartProducts = getDiscount(
        state.cartProducts,
        finalPrice,
        currentProduct.productId
      );

      localStorage.setItem(state.sellerId, JSON.stringify(state.cartProducts));
    },

    deleteProductFromCart: (state, action: PayloadAction<string>) => {
      const cartProductId = action.payload;
      const currentProduct = state.cartProducts[cartProductId];
      const productId = state.cartProducts[cartProductId].productId;

      delete state.cartProducts[cartProductId];

      const summaryCount =
        getProductsQuantity(state.cartProducts, productId) -
        currentProduct.count;

      const finalPrice = getCurrentPrice(currentProduct, summaryCount);

      state.cartProducts = getDiscount(
        state.cartProducts,
        finalPrice,
        currentProduct.productId
      );

      localStorage.setItem(state.sellerId, JSON.stringify(state.cartProducts));
    },
    setCartClientData: (
      state,
      action: PayloadAction<CartClientDataType | null>
    ) => {
      state.clientData = action.payload;
    },
    setSellerId: (state, action: PayloadAction<string>) => {
      state.sellerId = action.payload;
      localStorage.setItem('sellerId', state.sellerId);
    },
  },
});

export default CartSlice;
export const {
  addProduct,
  incProductInCart,
  decProductInCart,
  deleteProductFromCart,
  setCartClientData,
  setCart,
  setSellerId,
  setProductCountInCart,
  setAddress,
  setPoint,
} = CartSlice.actions;

export const CartActions = CartSlice.actions;
