import { TariffOrderType, TariffType } from './../types/tariff';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { validationPackages } from '../../lib/validation/validationPackages';
import { validationLocations } from '../../lib/validation/validationLocations';
import { getDimensionsPackages } from '../selectors/newOrderSdekSelectors';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { showErrorNotification } from 'utils/notification';
import { CreateOrderSdekActions } from '../slice/newSdekOrderSlice';
import { ThunkConfig } from 'app/providers/StoreProvider';

interface FetchTariffSdekProps {
  orderTariffData: TariffOrderType;
}

export const fetchTariffSdek = createAsyncThunk<
  TariffType[],
  FetchTariffSdekProps,
  ThunkConfig<string>
>('sdek/fetchTariffSdek', async ({ orderTariffData }, thunkApi) => {
  const {extra: { api }, getState, dispatch } = thunkApi;
  const { packages, pickupType, fromLocationFiasCode, toLocationFiasCode } =
    orderTariffData;

  const dimensions = getDimensionsPackages(getState());

  const { isValid: isValidPackages, errors: errorsPackages} =
    await validationPackages(packages, dimensions);

  const { isValid: isValidLocations, errors: errorsLocation} =
    await validationLocations({
      pickupType,
      fromLocationFiasCode,
      toLocationFiasCode,
    });

  if (!isValidPackages || !isValidLocations) {
    return null;
  }

  try {
    const { data } = await api.post(ApiRoutes.SdekTariffs, orderTariffData);
    dispatch(CreateOrderSdekActions.setTariff(null));

    return data;
  } catch (error) {
    const { message } = apiErrorHandler(error);
    showErrorNotification([message]);
  }
});
