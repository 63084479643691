import { DimensionsType } from '../../model/types/packages';
import Typography from '@mui/material/Typography';
import cls from './ShowDimensionsDeliveryPoint.module.scss';
import { memo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { getDimensionsPackages } from '../../model/selectors/newOrderSdekSelectors';

export const ShowDimensionsDeliveryPoint = memo(() => {
  const dimensions = useAppSelector(getDimensionsPackages);

  if (!dimensions) {
    return null;
  }
  const { weightMin, weightMax, height, width, depth } = dimensions;

  return (
    <div className={cls.wrapper}>
      <Typography variant='body2' component='p' className={cls.title}>
        Габариты хранилища:
      </Typography>
      <ul className={cls.list}>
        {Boolean(weightMin) && (
          <li>
            <span>Мин. вес:</span> {weightMin} кг;
          </li>
        )}

        <li>
          <span>Макс. вес:</span>
          {weightMax} кг;
        </li>

        {Boolean(height) && (
          <li>
            <span>Высота:</span> {height} см;
          </li>
        )}
        {Boolean(width) && (
          <li>
            <span>Ширина:</span> {width} см;
          </li>
        )}
        {Boolean(depth) && (
          <li>
            <span>Длина:</span> {depth} см;
          </li>
        )}
      </ul>
    </div>
  );
});
