import { GridComparatorFn } from "@mui/x-data-grid/models/gridSortModel";

import { TariffType } from "../../model/types/tariff";
import { getMarginPriceFromString } from "utils/tariff";

const deliverySumComparator: GridComparatorFn<string> = (value1, value2) => {
    return getMarginPriceFromString(value1) - getMarginPriceFromString(value2);
  };
  

export const tariffColumns = [
    {
      field: 'tariff_name',
      headerName: 'Тип услуги',
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: 'delivery_sum',
      headerName: 'Стоимость',
      minWidth: 80,
      flex: 0.3,
      sortComparator: deliverySumComparator,
    },
    {
      field: 'time',
      headerName: 'Срок',
      flex: 0.2,
      valueGetter: ({ row }: { row: TariffType }) => {
        return `${row.period_min} - ${row.period_max} дней`;
      },
    },
  ];
  