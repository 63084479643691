import { Stack, Pagination } from '@mui/material';
import { getProductCount } from 'entities/CustomLink';
import { DEFAULT_PAGE_ITEM_COUNT } from '../../lib/types';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useIsWhiteMoreContrast } from 'shared/hooks/useIsWhiteMoreContrast';

export default function PaginationRanges() {
  const [search, setSearch] = useSearchParams();
  const isWhite = useIsWhiteMoreContrast();

  const rowsPerPage = Number(search.get('rowsPerPage') || DEFAULT_PAGE_ITEM_COUNT);
  const productsCount = useAppSelector(getProductCount);

  const productsTotalCount = productsCount || DEFAULT_PAGE_ITEM_COUNT;

  const countPaginationItems = Math.ceil(productsTotalCount / rowsPerPage) || 0;

  const handlePaginationClick = (
    _event: React.ChangeEvent<unknown>,
    pageNumValue: number
  ) => {
    search.set('page', String(pageNumValue));
    setSearch(search);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (countPaginationItems <= 1 || !productsCount) {
    return null;
  }  

  return (
    <Stack spacing={2} m='2rem 0'>
      <Pagination
        hideNextButton={true}
        hidePrevButton={true}
        count={countPaginationItems}
        page={Number(search.get('page')) | 1}
        boundaryCount={2}
        siblingCount={1}
        sx={{
          backgroundColor: isWhite
            ? 'rgba(#000000, 0.2)'
            : 'rgba(#ffffff, 0.2)',
        }}
        variant='outlined'
        shape='rounded'
        onChange={handlePaginationClick}
      />
    </Stack>
  );
}
