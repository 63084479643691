import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { ApiRoutes } from 'consts';
import { ReviewSlice, getCurrentReview, setCurrentReview, updateReply } from 'entities/Reviews';
import { resetReviewError, setReviewError } from 'entities/Reviews';
import { setCurrentOrder } from 'redux/OrdersSlice/OrdersPageSlice';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { showErrorNotification, showSuccessNotification } from 'utils/notification';

export const replyReview = createAsyncThunk<
  void,
  string,
  ThunkConfig<string>
>(
  'review/postReview',
  async (reviewText, { dispatch, getState, extra: { api } }) => {
    const state = getState();
    const currentReview = getCurrentReview(state);

    if (!currentReview) {
      return;
    }

    const { orderId, productId } = currentReview;

    try {
      const { data } = await api.post(ApiRoutes.Review, {
        replyReviewText: reviewText,
        orderId,
        productId,
      });
      
      if (data) {
        dispatch(setCurrentOrder(null));
        dispatch(setCurrentReview(null));
        dispatch(updateReply(reviewText))
        showSuccessNotification(['Ответ успешно отправлен']);
      }
    } catch (error) {
      const { message, status } = apiErrorHandler(error);
      dispatch(setReviewError(`${status} ${message}`));
      showErrorNotification([message]);
      setTimeout(() => {
        dispatch(resetReviewError());
      }, 3000);
    }
  }
);
