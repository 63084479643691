import { FormLabel } from '@mui/material';
import BaseInput from 'components/ui/BaseInput/BaseInput';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import React from 'react';
import { CreateOrderSdekActions } from '../../model/slice/newSdekOrderSlice';
import { getPriceCOD, getRecipientError } from '../../model/selectors/newOrderSdekSelectors';

export const TotalPrice = () => {
  const dispatch = useAppDispatch();
  const priceCOD = useAppSelector(getPriceCOD);
  const error = useAppSelector(getRecipientError)

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(CreateOrderSdekActions.setPriceCOD(Number(evt.target.value)));
  };
  return (
    <>
      <FormLabel sx={{ mb: '1rem' }}>Стоимость отправления:</FormLabel>
      <BaseInput
        label='Сумма'
        inputProps={{
          inputMode: 'numeric',
          // pattern: '[0-9]',
          onWheel: (evt) => evt.currentTarget.blur(),
        }}
        onChange={onChange}
        value={priceCOD}
        error={Boolean(error.priceCOD)}
        helperText={error.priceCOD}
      />
    </>
  );
};
