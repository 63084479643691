import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { BaseColors } from '../colors';
import { memo } from 'react';

const BaseInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '.MuiInputBase-root': {
    borderRadius: '12px',
    color: BaseColors.textPrimary,
    border: `1px solid ${BaseColors.strokePrimary}`,

    '&:hover': {
      border: `1px solid ${BaseColors.strokeContrast}`,
    },
  },

  '.MuiInputLabel-root': {
    position: 'static',
    transform: 'none',
    fontWeight: 500,
    color: BaseColors.textPrimary,
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: `${BaseColors.strokePrimary} !important`,
    top: '0px',
  },

  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: BaseColors.strokeContrast,
    borderWidth: '1px',
  },

  '.Mui-error.MuiInputBase-root': {
    borderColor: 'red',
  },

  '.MuiOutlinedInput-notchedOutline legend': {
    display: 'none',
  },
}));

export default memo(BaseInput);
