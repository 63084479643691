import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = ["Корзина", "Адрес", "Подтверждение"];

type CartStepperProps = {
  activeStep: number;
};

function CartStepper({ activeStep }: CartStepperProps) {
  return (
    <Box
      sx={{
        padding: "5px",
        width: "112px",
        alignSelf: "flex-end",
        borderRadius: "20px",
        background: "rgba(34, 59, 221, 0.1)",
        position: "absolute",
        top: "16px",
        right: "16px"
      }}
    >
      <Stepper activeStep={activeStep} connector={null} sx={{display: "flex", justifyContent: "space-between"}}>
        {steps.map((label) => {
          return (
            <Step key={label} sx={{padding: "0"}}>
              <StepLabel sx={{
                '& .MuiStepLabel-iconContainer': {
                  padding: "0"
                },
                '& .MuiStepLabel-horizontal': {
                  opacity: "0",
                  backgroundColor: "white"
                }
              }}></StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default CartStepper;
