import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { getValidationError } from 'utils/validation';

const recipientSchema = Yup.object().shape({
  priceCOD: Yup.number()
    .required('Это поле обязательное!')
    .test('test-min-cod-price', 'Цена должна быть больше стоимости тарифа', (value, context) => {
      if (
        context.options.context &&
        Number(value) < context?.options?.context.minPrice
      ) {
        return false;
      }

      return true;
    }),
  recipientName: Yup.string()
    .typeError('Введите корректное значение')
    .required('Это поле обязательное!'),
  recipientPhone: Yup.string()
    .typeError('Введите корректное значение')
    .required('Это поле обязательное!'),
});

export const validationRecipient = async (recipientData: {
  priceCOD?: number;
  recipientName: string;
  recipientPhone: string;
  minPrice: number;
}) => {
  const errors = await getValidationError(recipientSchema, recipientData, {
    minPrice: recipientData.minPrice,
  });

  if (!isEmpty(errors)) {
    return { errors: errors, isValid: false };
  }

  return { errors: undefined, isValid: true };
};
