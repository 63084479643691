import { useEffect } from 'react';
import {
  getProductViewType,
} from '../model/selectors';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getCustomLinkProducts } from '../model/services/getCustomLinkProducts';
import Cookies from 'js-cookie';
import useSyncSearchWithUrl from './useSyncSearchWithURL';

const useCustomLinkData = () => {
  const dispatch = useAppDispatch();
  const { linkId } = useParams();

  const productViewType = useAppSelector(getProductViewType);

  const {
    page,
    rowsPerPage,
    searchValue,
    categoryFilter,
    sortTypeValue,
    sortBy,
  } = useSyncSearchWithUrl();

  useEffect(() => {
    if (linkId) {
      Cookies.set('cookiesLastLinkId', linkId);
    }

    dispatch(
      getCustomLinkProducts({
        linkId: String(linkId),
        page,
        rowsPerPage,
        searchValue,
        categoryFilter,
        sortBy,
        sortType: sortTypeValue,
      })
    );
  }, [dispatch, page, rowsPerPage, searchValue, sortTypeValue, categoryFilter]);

  return {
    productViewType,
  };
};

export { useCustomLinkData };
