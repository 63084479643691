import {
  getCustomLinkContent,
  getCustomLinkName,
  getCustomLinkTariffRestriction,
  getVerifiedStatus,
  getIsActiveLink,
  CustomLinkProductList,
} from 'entities/CustomLink';
import { YMInitializer } from 'react-yandex-metrika';
import DefaultBrand from 'components/DefaultBrand/DefaultBrand';
import { Typography, Stack, Button, Box } from '@mui/material';
import SocialLinks from 'components/SocialLinks/SocialLinks';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import { memo, useEffect } from 'react';
import Icon from '@mui/material/Icon';
import { Helmet } from 'react-helmet-async';
import { ServiceBlockType } from 'types/links';
import useAsyncReducer, {
  ReducersList,
} from 'shared/lib/hooks/useAsyncReducer';
import { VerifiedPopover } from 'entities/CustomLink';
import { ZeroBlock } from './ZeroBlock/ZeroBlock';
import { LinkBlockedModal } from './LinkBlockedModal/LinkBlockedModal';
import { CartSlice } from 'entities/Cart';

const reducers: ReducersList = {
  CartSlice: CartSlice.reducer,
};

const LinkPreview = () => {
  useAsyncReducer({ reducers, removeAfterUnmount: false });
  const content = useAppSelector(getCustomLinkContent);
  const { isWhiteLabelAvailable } = useAppSelector(
    getCustomLinkTariffRestriction
  );
  const isUserVerified = useAppSelector(getVerifiedStatus)
  const matches = useMediaQuery('(min-width:600px)');
  const isActiveLink = useAppSelector(getIsActiveLink);    

  if (!content) {
    return null;
  }

  const socialLinks = content.socialLinksBlock.content;
  const title = useAppSelector(getCustomLinkName);
  const { bgImgUrl, colorText, logoImgUrl, description } =
    content.generalBlock.content;    

  const { bannerImgMobileUrl, bannerImgDesktopUrl } =
    content.bannerBlock.content;

  const getServiceBlockInfo = (): ServiceBlockType => {
    return content?.serviceBlock?.content;
  };

  const svgIcon = (link: string) => (
    <Icon>
      <img src={link} />
    </Icon>
  );

  return (
    <>
      <Helmet>
        <title>{getServiceBlockInfo()?.metaTitle || title}</title>
        <meta
          name='description'
          content={getServiceBlockInfo()?.metaDesc || ''}
        />
      </Helmet>
      <YMInitializer
        accounts={[Number(getServiceBlockInfo()?.yandexMetrikaKey)]}
      />
      <Stack
        sx={{
          minHeight: '100vh',
          backgroundImage: `url(${bgImgUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          maxWidth: '100vw',
        }}
      >
        {!matches && bannerImgMobileUrl && (
          <Box src={bannerImgMobileUrl} component={'img'} margin={0}></Box>
        )}
        <Stack
          direction={'column'}
          alignItems={'center'}
          spacing={3}
          sx={{
            padding: {
              xs: '8px',
              md: '16px',
            },
          }}
        >
          {matches && bannerImgDesktopUrl && (
            <Box
              src={bannerImgDesktopUrl}
              component={'img'}
              margin={0}
              sx={{
                width: '100%',
                borderRadius: '20px',
                maxWidth: '1260px',
                margin: '0 auto 0',
              }}
            ></Box>
          )}
          {logoImgUrl && (
            <Box
              className={
                bannerImgMobileUrl ? '' : 'custom-link__logo-without-banner'
              }
              component={'img'}
              src={logoImgUrl}
              sx={{ width: '200px' }}
            ></Box>
          )}

          <Typography
            variant='h3'
            component='h1'
            sx={{ color: colorText, textAlign: 'center', maxWidth: '500px' }}
            mt={bannerImgMobileUrl ? '5%' : '10%'}
          >
            {title}
          </Typography>

          {isUserVerified && <VerifiedPopover />}

          {description && (
            <Stack
              sx={{ color: colorText, textAlign: 'center', maxWidth: '500px' }}
              className='preview-box'
            >
              {Parser(description)}
            </Stack>
          )}

          {Object.values(content.buttonsBlock).map(({ content }) => (
            <Button
              target='_blank'
              key={content.blockId}
              variant='contained'
              fullWidth
              startIcon={content.iconImgUrl ? svgIcon(content.iconImgUrl) : ''}
              className={`animate__animated ${content.animation} animate__infinite ${content.animationSpeed}`}
              sx={{
                maxWidth: '98vw',
                fontSize: '1rem',
                p: 1.5,
                color: content.textColor,
                background: content.bg,
                width: 'unset',
                pl: '36px',
                pr: '36px',
                alignSelf: { xs: 'stretch', md: 'center' },
                textTransform: 'uppercase',
                border: content.borderThickness,
                borderColor: content.borderColor,
                ':hover': {
                  border: content.borderThickness,
                  borderColor: content.borderColor,
                  background: content.bg,
                  opacity: '0.9',
                  boxShadow: '0px 0px 10px #000',
                },
              }}
              href={content.urlLink}
            >
              {content.textLink}
            </Button>
          ))}

          <SocialLinks socialLinks={socialLinks} />

          {content?.zeroBlock?.content?.code && (
            <ZeroBlock content={content?.zeroBlock?.content?.code} />
          )}

          <CustomLinkProductList />

          {!isWhiteLabelAvailable && <DefaultBrand colorText={colorText} />}
        </Stack>
      </Stack>
      {!isActiveLink && <LinkBlockedModal />}
    </>
  );
};

export default memo(LinkPreview);
