import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

import { LogisticsLayout } from 'app/layouts/LogisticsLayout/LogisticsLayout';
const LogisticsPage = lazy(
  () => import('pages/Logistics/LogisticsPage/LogisticsPage')
);

const LogisticsNewOrderPage = lazy(
  () => import('pages/Logistics/LogisticsNewOrderPage/LogisticsNewOrderPage')
);

const LogisticsOnboardingPage = lazy(
  () =>
    import('pages/Logistics/LogisticsOnboardingPage/LogisticsOnboardingPage')
);

const LogisticsRoutes = () => {
  return (
    <Routes>
      <Route path='/onboarding' element={<LogisticsOnboardingPage />} />
      <Route path='/' element={<LogisticsLayout />}>
        <Route path='/orders' element={<LogisticsPage />} />
        <Route path='/new-order' element={<LogisticsNewOrderPage />} />
      </Route>
    </Routes>
  );
};

export default LogisticsRoutes;
