import { StateSchema } from 'app/providers/StoreProvider';
import { OnboardingCompleteStatusI } from 'entities/Onboarding/lib/types';

export const getIsOnboardingLoading = (state: StateSchema): Boolean =>
  state?.Onboarding?.isLoading;

export const getOnboardingData = (
  state: StateSchema
): OnboardingCompleteStatusI | null =>
  state?.Onboarding?.onboardingCompleteStatus;
