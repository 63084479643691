import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { ApiRoutes } from 'consts';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AddNewClientResultI } from '../../lib/types';
import {
  getCurrentOrderData,
  getNewCustomerData,
} from '../selectors/stockOrderEditSelectors';

export const addNewClient = createAsyncThunk<
  AddNewClientResultI,
  void,
  ThunkConfig<string>
>(
  'stock/addNewClient',
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    try {
      const { orderId } = getCurrentOrderData(getState());
      const newClientData = getNewCustomerData(getState());

      const phoneValidator = (value?: string) => {
        // Если значение не предоставлено, возвращаем false, так как поле обязательно
        if (value === undefined || value === '') {
          return false;
        }
        return isValidPhoneNumber(value, 'RU');
      };

      const customerInfoSchema = yup.object().shape({
        phone: yup
          .string()
          .required('Телефон обязателен')
          .test('is-valid-phone', 'Номер телефона невалиден', phoneValidator),
        name: yup.string().nullable().min(2, 'Имя слишком короткое'),
        surName: yup.string().nullable(),
        fatherName: yup.string().nullable(),
        tgNick: yup.string().nullable(),
        address: yup.string().nullable(),
      });

      const validateCustomerInfo = async () => {
        try {
          await customerInfoSchema.validate(newClientData, {
            abortEarly: false,
          });
          return true;
          // Валидация прошла успешно, выполняем нужные действия
        } catch (error) {
          console.log(error, 'validtai');

          if (error instanceof yup.ValidationError) {
            const errorMessages = error.inner.map((err) => err.message);
            showErrorNotification(errorMessages);
          } else {
            // Неизвестная ошибка
            console.error(error);
            showErrorNotification(['Произошла неизвестная ошибка']);
          }

          return false;
        }
      };

      const result = await validateCustomerInfo();

      if (!result) {
        console.log('обсер');

        return;
      }

      // Валидация и показ ошибок
      // Обработка открытия новой страницы/релоад

      const { data } = await api.post(ApiRoutes.ClientCreate, {
        ...newClientData,
        orderId,
      });

      if (!data) {
        showErrorNotification(['Не удалось добавить продукт к заказу']);
        return rejectWithValue('Не удалось добавить продукт к заказу');
      }

      showSuccessNotification(['клиент успешно добавлен к заказу']);

      if (!orderId) {
        window.open(`/dashboard/orders/order/${data}`, '_self');
      } else {
        window.location.reload();
      }

      return data;
    } catch (error) {
      console.log(error, 'update error');

      const { message } = apiErrorHandler(error);
      showErrorNotification([message]);
      return rejectWithValue('Не удалось добавить продукт к заказу');
    }
  }
);
