import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { getCurrentReview, getReviewLoadingStatus, setReviewError } from 'entities/Reviews';
import { replyReview } from 'features/ReviewReply/model/services/replyReview';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export const ReviewReplyForm = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getReviewLoadingStatus);
  const review = useAppSelector(getCurrentReview);

  if (review?.replyReview?.id) {
    return null;
  }

  const [reply, setReply] = useState('');

  const handleSubmit = () => {
    if (!reply) {
      setReviewError('Напишите ответ')
      return;
    }
    dispatch(replyReview(reply))
  };

  return (
    <form>
      <TextField
        id='reply-text'
        value={reply}
        onChange={(evt) => setReply(evt.currentTarget.value)}
        label='Ваш Ответ'
        multiline
        rows={4}
        sx={{width: '100%', marginBlock: '16px'}}
      />
      <LoadingButton
        type='button'
        fullWidth
        variant='contained'
        onClick={handleSubmit}
        loading={isLoading}
        disabled={!Boolean(reply)}
      >
        Сохранить
      </LoadingButton>
    </form>
  );
};
