import { createSlice } from "@reduxjs/toolkit";
import { ProjectObjType, ProjectType } from "types/projects";

export type ProjectSliceType = {
  projects: ProjectObjType;
  isLoading: boolean;
  errorMessage: string;
  currentProject: ProjectType | null;
};

const initialState: ProjectSliceType = {
  projects: {},
  isLoading: false,
  errorMessage: "",
  currentProject: null,
};

const ProjectSlice = createSlice({
  name: "ProjectSlice",
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    addProject: (state, action) => {
      state.projects[action.payload.projectId] = action.payload;
    },
    deleteProject: (state, action) => {
      delete state.projects[action.payload];
      state.currentProject = null;
    },
    setIsLoadingProjects: (state, action) => {
      state.isLoading = action.payload;
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    updateProject: (state, action) => {
      state.projects[action.payload.projectId].name = action.payload.name;
      state.currentProject = null;
    },
    setProjectError: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetProjectError: (state) => {
      state.errorMessage = "";
    },
  },
});

export default ProjectSlice;

export const {
  setProjects,
  addProject,
  setIsLoadingProjects,
  deleteProject,
  setCurrentProject,
  setProjectError,
  resetProjectError,
  updateProject,
} = ProjectSlice.actions;
