import { Alert } from "@mui/material";

type ShoweRequestErrorAlertProps = {
  error: string;
};

const ShoweRequestErrorAlert = ({ error }: ShoweRequestErrorAlertProps) => {
  if (error) {
    return (
      <Alert className="alert-request" variant="filled" severity="error">
        {error}!
      </Alert>
    );
  }

  return null;
};

export default ShoweRequestErrorAlert;
