import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from 'consts';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { showErrorNotification } from 'utils/notification';
import { DeliveryPointI } from '../types/location';
import { ThunkConfig } from 'app/providers/StoreProvider';

interface fetchOfficeListSdekProps {
  toLocationFiasCode: string;
}

export const fetchOfficeListSdek = createAsyncThunk<
  DeliveryPointI[],
  fetchOfficeListSdekProps,
  ThunkConfig<string>
>('sdek/fetchOfficeListSdek', async ({ toLocationFiasCode }, thunkApi) => {
  const {
    extra: { api },
  } = thunkApi;

  if (!toLocationFiasCode) {
    showErrorNotification(['Не был выбран город доставки']);
    return null;
  }

  try {
    const { data } = await api.get(ApiRoutes.GetOfficeList, {
      params: { toLocationFiasCode },
    });

    return data;
  } catch (error) {
    const { message } = apiErrorHandler(error);
    showErrorNotification([message]);
  }
});
